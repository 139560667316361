import { ECommonColumns } from '../../models/enums/columns.enum'

const clientColumns = [
  ECommonColumns.CLIENT_NAME,
  ECommonColumns.INDUSTRY,
  ECommonColumns.BUSINESS_UNITS,
  ECommonColumns.LAST_UPDATED,
]

export { clientColumns }
