import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'

import { AddMoreFilesIcon, CompletedGreenIcon, GeneralInformationIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import { IWizardResponseType } from '../../../models/interfaces/wizard-response-type'
import { getMessagingEngineThunk, updateMessagingEngine } from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import GeneralInformation from '../WizardDiscovery/GeneralInformation/GeneralInformation'
import UploadFilesDropDown from '../WizardDiscovery/UploadFilesDropDown/UploadFilesDropDown'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import styles from './WizardMessagingEngine.module.scss'

interface IWizardMessagingEngineProps {
  messagingId?: string
  nextStep: () => void
  onProgressUpdate: (percent: number) => void
}
/*TODO: implement save function like discovery and update progress */
const WizardMessagingEngine: FC<IWizardMessagingEngineProps> = ({ messagingId, nextStep, onProgressUpdate }) => {
  const dispatch = useAppDispatch()
  const messagingEngine = useAppSelector((state) => state.wizard.data?.messagingEngine)
  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])

  useEffect(() => {
    if (messagingId && !messagingEngine) {
      dispatch(getMessagingEngineThunk({ messagingId }))
    }
  }, [dispatch, messagingId, messagingEngine])

  // const saveDiscoveryData = async () => {
  //   if (messagingId && messagingEngine) {
  //     // await dispatch()
  //     // createOrUpdateDiscovery({
  //     // messagingEngine: messagingEngine,
  //     // }),
  //     nextStep()
  //   }
  // }

  const handleNext = (currentKey: string, value: IWizardResponseType[]) => {
    if (!messagingEngine) return
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const newMoreInformation = [...messagingEngine.messagingEngineData.moreInformation, ...value]
    dispatch(
      updateMessagingEngine({
        messagingEngineData: {
          ...messagingEngine.messagingEngineData,
          moreInformation: newMoreInformation,
        },
      }),
    )

    setCompletedKeys((prev) => {
      const updated = [...new Set([...prev, currentKey])]
      return updated
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const items: AccordionItem[] = [
    {
      key: '1',
      label: <>{strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_INFORMATION}</>,
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={messagingEngine?.messagingEngineData.moreInformation}
          onNext={(data) => handleNext('1', data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
        />
      ),
    },
    {
      key: '2',
      label: strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_LINKS_AND_FILES,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={messagingEngine?.messagingEngineData.moreInformation}
          onNext={(data) => handleNext('2', data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={messagingId}
          referenceType={EFileReferenceType.MESSAGING_ENGINE}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
        />
      ),
    },
  ]

  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.MESSAGING_ENGINE.TITLE}
      subTitle={strings.WIZARD.MESSAGING_ENGINE.SUB_TITLE}
      availableGenerate={true}
      nextStep={nextStep}
      step={3}
      onlyGenerateButton
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardMessagingEngine
