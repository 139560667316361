import { Dropdown, MenuProps } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {
  AdjustToneAIIcon,
  ExpandAIIcon,
  MagicPenIcon,
  RewriteAIIcon,
  RowsIcon,
  ShortenAIIcon,
  TableIcon,
} from '../../../assets/icons'
import { EStatusEnum } from '../../../models/enums/status.enum'
import { ItextEditingAI } from '../../../models/interfaces/text-editing-ai.interface'
import { addParagraphAIService, textEditingAIService } from '../../../services/wizard-ai.service'
import { getParagraphAfter, getParagraphBefore } from '../../../utils/text.util'
import { GenericButton } from '../../GenericButton/GenericButton'
import { StatusBox } from '../../StatusBox/StatusBox'
import WizardAIAssistant from '../WizardAIAssistant/WizardAIAssistant'
import styles from './WizardTextEditor.module.scss'
interface WizardTextEditorProps {
  step: number
  text?: string
  onChangeText?: (text: string) => void
}

const CustomToolbar = () => {
  return (
    <div id="my-toolbar" className={styles.customToolbar}>
      <select className="ql-header" defaultValue="">
        <option value="1" />
        <option value="2" />
        <option value="3" />
        <option value="" />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <select className="ql-align" />
      <button className="ql-link" />
    </div>
  )
}

const WizardTextEditor: FC<WizardTextEditorProps> = ({ step, text, onChangeText }) => {
  const quillRef = useRef<ReactQuill>(null)
  const menuConfig = [
    { key: 'shorten', label: 'Shorten', Icon: ShortenAIIcon },
    { key: 'expand', label: 'Expand', Icon: ExpandAIIcon },
    { key: 'rewrite', label: 'Rewrite', Icon: RewriteAIIcon },
    { key: 'adjustTone', label: 'Adjust Tone', Icon: AdjustToneAIIcon },
  ]

  const [value, setValue] = useState<string>(text ? text : '')
  const [stepName, setStepName] = useState<EStatusEnum>(EStatusEnum.DEFAULT)
  const [internalOrExternal, setInternalOrExternal] = useState<EStatusEnum>(EStatusEnum.INTERNAL)
  const [showOptions, setShowOptions] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 })
  const [aiMode, setAiMode] = useState(false)
  const [showToneDropdown, setShowToneDropdown] = useState(false)
  const [toneInstructions, setToneInstructions] = useState('')
  const [aiAnswer, setAiAnswer] = useState<string>('')
  const [aiAnswerLoading, setAiAnswerLoading] = useState<boolean>(false)

  useEffect(() => {
    if (text !== undefined) {
      setValue(text)
    }
  }, [text])

  useEffect(() => {
    switch (step) {
      case 0:
        setStepName(EStatusEnum.DISCOVERY)
        setInternalOrExternal(EStatusEnum.INTERNAL)
        break
      case 1:
        setStepName(EStatusEnum.COMPETITIVE_ANALYSIS)
        setInternalOrExternal(EStatusEnum.INTERNAL)
        break
      case 2:
        setStepName(EStatusEnum.MESSAGING_ENGINE)
        setInternalOrExternal(EStatusEnum.EXTERNAL)
        break
      default:
        setStepName(EStatusEnum.DEFAULT)
        break
    }
  }, [step])

  const optionsMenuItems: MenuProps['items'] = menuConfig.map(({ key, label, Icon }) => ({
    key,
    label: (
      <div className={styles.dropdownItem}>
        <Icon className={styles.dropdownIcon} />
        {label}
      </div>
    ),
  }))

  const toneMenuItems: MenuProps['items'] = [
    {
      key: 'toneInput',
      label: (
        <div onClick={(e) => e.stopPropagation()} className={styles.adjustToneContainer}>
          <input
            style={{ width: '100%', marginBottom: '8px', pointerEvents: 'auto' }}
            value={toneInstructions}
            onChange={(e) => setToneInstructions(e.target.value)}
            placeholder="Adjust tone of voice to..."
            onClick={(e) => e.stopPropagation()}
          />
          <GenericButton
            Icon={MagicPenIcon}
            onClick={() => {
              handleApplyTone()
            }}
          />
        </div>
      ),
    },
  ]

  const modules = {
    toolbar: {
      container: '#my-toolbar',
    },
  }

  const handleDropdownItemClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'adjustTone') {
      setShowToneDropdown(true)
    } else {
      applyAI(key)
    }
  }

  const handleApplyTone = () => {
    applyAI(toneInstructions)
    setToneInstructions('')
  }

  const applyAI = async (instructions: string) => {
    setShowOptions(false)
    setShowToneDropdown(false)

    const quill = quillRef.current?.getEditor()
    if (!quill) return

    quill.focus()
    const range = quill.getSelection()
    if (!range || range.length === 0) return

    const fullText = quill.getText(0)
    const selectedText = fullText.slice(range.index, range.index + range.length)
    const beforeParagraph = getParagraphBefore(fullText, range.index)
    const afterParagraph = getParagraphAfter(fullText, range.index + range.length)

    const textInfo: ItextEditingAI = {
      text: selectedText,
      instructions,
      before: beforeParagraph,
      after: afterParagraph,
    }

    const response = await textEditingAIService(textInfo)
    const updatedText = response.result || ''
    quill.deleteText(range.index, range.length)
    quill.insertText(range.index, updatedText)
  }

  const handleChangeSelection = (range: any) => {
    const quill = quillRef.current?.getEditor()
    if (range && range.length > 0 && quill) {
      const bounds = quill.getBounds(range.index + range.length, 0)
      setDropdownPosition({
        x: bounds.left + 30,
        y: bounds.top + bounds.height + 85,
      })
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
  }

  const handleAssistantOk = async (question: string) => {
    const quill = quillRef.current?.getEditor()
    if (!quill) return

    setAiAnswerLoading(true)

    let range = quill.getSelection()
    if (!range) {
      range = { index: quill.getLength(), length: 0 }
    }

    const fullText = quill.getText(0)

    const beforeParagraph = getParagraphBefore(fullText, range.index)
    const afterParagraph = getParagraphAfter(fullText, range.index + range.length)

    const textInfo: ItextEditingAI = {
      instructions: question,
      before: beforeParagraph,
      after: afterParagraph,
    }
    const response = await addParagraphAIService(textInfo)
    setAiAnswer(response.result || '')
    setAiAnswerLoading(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.toolbarContainer}>
          <CustomToolbar />
          <div className={styles.buttonsContainer}>
            <GenericButton
              style={aiMode ? { backgroundColor: '#f2f1f8' } : {}}
              secondary
              Icon={MagicPenIcon}
              onClick={() => setAiMode(true)}
            />
            <div className={styles.rowsTableContainer}>
              <GenericButton Icon={RowsIcon} onClick={() => {}} />
              <GenericButton secondary Icon={TableIcon} onClick={() => {}} />
            </div>
          </div>
        </div>
        <div className={styles.badgesContainer}>
          <StatusBox style={{ fontSize: '12px' }} status={stepName}></StatusBox>
          <StatusBox style={{ fontSize: '12px' }} status={internalOrExternal}></StatusBox>
        </div>
        <ReactQuill
          ref={quillRef}
          modules={modules}
          className={styles.textEditor}
          theme="snow"
          value={value}
          onChangeSelection={handleChangeSelection}
          onChange={(content) => {
            setValue(content)
            if (onChangeText) onChangeText(content)
          }}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: dropdownPosition.x,
          top: dropdownPosition.y,
          zIndex: 1000,
        }}
      >
        <Dropdown
          menu={{ items: optionsMenuItems, onClick: handleDropdownItemClick }}
          trigger={['click']}
          open={showOptions}
          onOpenChange={(open) => setShowOptions(open)}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: dropdownPosition.x,
          top: dropdownPosition.y,
          zIndex: 1000,
        }}
      >
        <Dropdown
          menu={{ items: toneMenuItems }}
          trigger={['click']}
          open={showToneDropdown}
          onOpenChange={(open) => setShowToneDropdown(open)}
          overlayStyle={{ pointerEvents: 'none' }}
        />
      </div>

      {aiMode && (
        <WizardAIAssistant
          isLoading={aiAnswerLoading}
          aiAnswer={aiAnswer}
          onOk={handleAssistantOk}
          onClose={() => setAiMode(false)}
        />
      )}
    </div>
  )
}
export default WizardTextEditor
