import { LoadingOutlined } from '@ant-design/icons'
import { FC } from 'react'

import { Spin } from 'antd'
import { strings } from '../../models/consts/strings'
import { CustomModal } from '../CustomModal/CustomModal'
import { GenericButton } from '../GenericButton/GenericButton'
import styles from './ConfirmModal.module.scss'

interface IConfirmModalProps {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
  isDangerButton?: boolean
  primaryTitle: string
  secondaryTitle: string
  confirmButtonTItle: string
  isLoading?: boolean
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  primaryTitle,
  onCancel,
  isOpen,
  onOk,
  isDangerButton,
  secondaryTitle,
  confirmButtonTItle,
  isLoading = false,
}) => {
  const customIcon = <LoadingOutlined style={{ color: '#8e8df4' }} spin />

  return (
    <CustomModal isOpen={isOpen} onCancel={onCancel}>
      <Spin spinning={isLoading} indicator={customIcon}>
        <div className={styles.topSection}>
          <h1 className={styles.primaryTitle}>{primaryTitle}</h1>
          <h2 className={styles.secondaryTitle}> {secondaryTitle}</h2>
        </div>
        <div className={styles.buttons}>
          <GenericButton isDangerButton={isDangerButton} title={confirmButtonTItle} onClick={onOk} />
          <GenericButton secondary title={strings.GENERAL_LABELS.CANCEL} onClick={onCancel} />
        </div>
      </Spin>
    </CustomModal>
  )
}

export default ConfirmModal
