import classNames from 'classnames'
import { FC } from 'react'

import { LeftArrow, RightArrow } from '../../assets/icons'
import styles from './Pagination.module.scss'
import { EComponentType } from '../../models/enums/componentType.enum'

export interface IPaginationProps {
  pagesCount: number
  currentPage: number
  onPageChange: (currentPage: number) => void
  componentType?: EComponentType
}

const Pagination: FC<IPaginationProps> = ({
  currentPage,
  onPageChange,
  pagesCount,
  componentType = EComponentType.DEFAULT,
}) => {
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= pagesCount) {
      onPageChange(newPage)
    }
  }

  const containerClass = classNames(styles.paginationContainer, {
    [styles.commandCenter]: componentType === EComponentType.COMMAND_CENTER,
  })
  return (
    <div className={containerClass}>
      <LeftArrow
        className={classNames(styles.arrow, {
          [styles.disabled]: currentPage === 1,
        })}
        onClick={() => handlePageChange(currentPage - 1)}
      />
      <p className={styles.paginationContent}>
        {currentPage} of {pagesCount}
      </p>
      <RightArrow
        className={classNames(styles.arrow, {
          [styles.disabled]: currentPage === pagesCount,
        })}
        onClick={() => handlePageChange(currentPage + 1)}
      />
    </div>
  )
}

export { Pagination }
