import { Config } from '../constants'

const msalConfig = {
  auth: {
    clientId: Config.entraClientId || '',
    authority: `${Config.entraAuthorityUrl}/${Config.entraTenantId}`,
    redirectUri: Config.baseUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

const loginRequest = {
  scopes: [`${Config.entraClientId}/.default`],
}

export { msalConfig, loginRequest }
