const DOCUMENT_FILE_TYPES = [
  '.pdf', // Portable Document Format
  '.doc', // Microsoft Word Document
  '.docx', // Microsoft Word Open XML Document
  '.xls', // Microsoft Excel Spreadsheet
  '.xlsx', // Microsoft Excel Open XML Spreadsheet
  '.ppt', // Microsoft PowerPoint Presentation
  '.pptx', // Microsoft PowerPoint Open XML Presentation
  '.rtf', // Rich Text Format
  '.txt', // Plain Text
  '.odt', // OpenDocument Text Document (LibreOffice Writer)
  '.ods', // OpenDocument Spreadsheet (LibreOffice Calc)
  '.odp', // OpenDocument Presentation (LibreOffice Impress)
  '.html', // HyperText Markup Language
]

const FONT_FILE_TYPES = [
  '.ttf', // TrueType Font
  '.otf', // OpenType Font
  '.woff', // Web Open Font Format
  '.woff2', // Web Open Font Format 2
  '.eot', // Embedded OpenType Font
  '.svg', // Scalable Vector Graphics Font
]

const IMG_TYPE = 'image/*'
export { FONT_FILE_TYPES, DOCUMENT_FILE_TYPES, IMG_TYPE }
