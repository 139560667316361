import { UploadFile } from 'antd/es/upload/interface'
import { IFile } from '../../models/interfaces/file.interface'

export function convertIFileToUploadFile(files: IFile[]): UploadFile[] {
  return files.map((f) => ({
    uid: f.id || '',
    name: f.fileName,
    status: 'done' as UploadFile['status'],
    url: f.fileUrl,
    type: f.fileType,
  }))
}
