import { Input, message } from 'antd'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { InfoIcon } from '../../assets/icons'
import { DOCUMENT_FILE_TYPES, IMG_TYPE } from '../../models/consts/document-type'
import { strings } from '../../models/consts/strings'
import { EFileSubReferenceType } from '../../models/enums/file-reference-type.enum'
import { EGuidelineType } from '../../models/enums/guideline-type.enum'
import { IFile } from '../../models/interfaces/file.interface'
import { SaveFileToDBService } from '../../services/files-upload-service'
import { createGuidelineService, deleteFileService, getGuidelineFilesService } from '../../services/guideline-service'
import {
  createOrUpdateGuidelineThunk,
  fetchGuidelineThunk,
  resetGuideline,
  updateGuideline,
} from '../../store/slices/guideline.slice'
import { RootState, useAppDispatch } from '../../store/store'
import { validateURL } from '../../utils/valid-url.util'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import { GenericButton } from '../GenericButton/GenericButton'
import { Loader } from '../Loader/Loader'
import { ColorPalette } from './ColorPalette/ColorPalette'
import FilesUploadGuideline from './FilesUploadGuideline/FilesUploadGuideline'
import FontUpload from './FontUpload/FontUpload'
import styles from './Guideline.module.scss'
import { WritingStyles } from './WritingStyles/WritingStyles'

const { TextArea } = Input

interface IGuidelineProps {
  referenceId: string | undefined
  referenceType: EGuidelineType
}

const Guideline: FC<IGuidelineProps> = ({ referenceId, referenceType }) => {
  const dispatch = useAppDispatch()
  const { guideline, loading } = useSelector((state: RootState) => state.guidelineSlice)

  const [confirmUploadResolve, setConfirmUploadResolve] = useState<((confirmed: boolean) => void) | null>(null)
  const [isParentGuideline, setIsParentGuideline] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [files, setFiles] = useState<IFile[]>([])
  const [removedFilesIds, setRemovedFilesIds] = useState<string[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [resourcesLoading, setResourcesLoading] = useState<boolean>(true)
  const [tempWebsite, setTempWebsite] = useState<string | null>(null)

  const [errors, setErrors] = useState<{ [key: string]: string | null }>({
    website: null,
    parentWebsite: null,
    tempWebsite: null,
  })

  useEffect(() => {
    if (!referenceId) {
      setResourcesLoading(false)
      return
    }

    ;(async () => {
      try {
        setResourcesLoading(true)
        const parentGuideline = await dispatch(fetchGuidelineThunk({ referenceType, referenceId })).unwrap()
        if (referenceType === EGuidelineType.BUSINESSUNIT && parentGuideline?.referenceType === EGuidelineType.CLIENT) {
          setIsParentGuideline(true)
        }
      } catch (error) {
        console.error('Error fetching guideline:', error)
      }
    })()
    return () => {
      dispatch(resetGuideline())
    }
  }, [dispatch, referenceId, referenceType])

  useEffect(() => {
    if (!guideline?.id) return
    ;(async () => {
      try {
        setResourcesLoading(true)
        const fetchedFiles = await getGuidelineFilesService(guideline.id as string)
        setFiles(fetchedFiles)
      } catch (err) {
        message.error('Error fetching files')
        console.error(err)
      } finally {
        setResourcesLoading(false)
      }
    })()
    return () => {
      setFiles([])
    }
  }, [guideline?.id])

  const handleSave = () => {
    if (!guideline || !referenceId) return console.error('No guideline data available to save.')
    if (isParentGuideline) {
      handleConfirmUpload()
      return
    }
    const { id, createdAt, updatedAt, ...filteredData } = guideline

    const payload = {
      ...filteredData,
      referenceType: referenceType,
      referenceId: referenceId,
      ...(referenceType === EGuidelineType.CLIENT && { parentWebsite: guideline.website }),
    }
    dispatch(createOrUpdateGuidelineThunk(payload))
      .unwrap()
      .then(() => {
        message.success({
          content: strings.SUCCESS_MSG.CLIENT_GUIDELINE,
        })
        files.filter((file) => !file.id).map((file) => SaveFileToDBService(file))

        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

        removedFilesIds.filter((fileId) => uuidRegex.test(fileId)).map((validFileId) => deleteFileService(validFileId))

        setRemovedFilesIds([])
      })
      .catch((error) => {
        console.error('Error saving guideline:', error)
        message.error('Failed to save guideline changes.')
      })
  }

  const handleInputChange = (key: string, value: string) => {
    if (isParentGuideline && key === strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.KEY) {
      setTempWebsite(value)
      if (value.trim() === '') {
        setErrors((prev) => ({ ...prev, tempWebsite: 'Website cannot be empty.' }))
      } else if (!validateURL(value)) {
        setErrors((prev) => ({ ...prev, tempWebsite: 'Please enter a valid URL starting with "www."' }))
      } else {
        setErrors((prev) => ({ ...prev, tempWebsite: null }))
      }
      return
    }
    if (
      key === strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.KEY ||
      key === strings.GUIDELINE.GENERAL_DETAILS.PARENT_WEBSITE.KEY
    ) {
      if (value.trim() === '') {
        setErrors((prev) => ({
          ...prev,
          [key]: null,
        }))
        dispatch(updateGuideline({ [key]: null }))
        return
      } else if (!validateURL(value)) {
        setErrors((prev) => ({
          ...prev,
          [key]: 'Please enter a valid URL starting with "www."',
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          [key]: null,
        }))
      }
    }
    dispatch(updateGuideline({ [key]: value }))
  }

  const handleConfirmUpload = (): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirmUploadResolve(() => resolve)
      setOpenModal(true)
    })
  }

  const handleModalOk = async () => {
    if (!guideline) {
      console.error('No guideline data available to save.')
      return
    }

    setConfirmLoading(true)

    const { id, createdAt, updatedAt, ...filteredData } = guideline

    const payload = {
      ...filteredData,
      referenceType: EGuidelineType.BUSINESSUNIT,
      referenceId,
      website: tempWebsite?.trim() === '' ? null : tempWebsite,
    }

    try {
      const newGuideline = await createGuidelineService(payload)
      dispatch(updateGuideline({ website: tempWebsite }))

      if (newGuideline && newGuideline.id) {
        setIsParentGuideline(false)

        const validFiles = files.filter((file) => !removedFilesIds.includes(file.id ?? ''))

        for (const file of validFiles) {
          const { id, createdAt, updatedAt, ...restFileFields } = file
          const newFileData = {
            ...restFileFields,
            referenceId: newGuideline.id,
          }
          await SaveFileToDBService(newFileData)
        }
        setRemovedFilesIds([])
        confirmUploadResolve?.(true)
        setOpenModal(false)
        setConfirmUploadResolve(null)

        message.success({
          content: 'Files updated successfully!',
        })
      } else {
        console.error('No id returned from createGuidelineService')
        message.error({
          content: 'No guideline ID returned.',
        })
      }
    } catch (error) {
      console.error('Error creating or updating guideline:', error)
      message.error({
        content: 'Error creating or updating guideline.',
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleModalCancel = () => {
    confirmUploadResolve?.(false)
    setOpenModal(false)
    setConfirmUploadResolve(null)
  }

  const handleFileRemove = (fileId: string) => {
    setFiles((prev) => prev.filter((file) => file.id && file.id !== fileId))
    setRemovedFilesIds((prev) => [...prev, fileId])
  }

  const hasErrors = Object.values(errors).some((error) => error !== null)

  const inputData = [
    {
      id: 1,
      key: strings.GUIDELINE.GENERAL_DETAILS.GENERAL_INFORMATION.KEY,
      label: strings.GUIDELINE.GENERAL_DETAILS.GENERAL_INFORMATION.LABEL,
      placeholder:
        referenceType === EGuidelineType.CLIENT
          ? strings.GUIDELINE.GENERAL_DETAILS.GENERAL_INFORMATION.PLACEHOLDER1
          : strings.GUIDELINE.GENERAL_DETAILS.GENERAL_INFORMATION.PLACEHOLDER2,
      value: guideline?.generalInformation || '',
    },
    {
      id: 2,
      key: strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.KEY,
      label: `${
        referenceType === EGuidelineType.BUSINESSUNIT ? 'Business unit ' : ''
      }${strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.LABEL}`,
      placeholder: strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.PLACEHOLDER,
      value: isParentGuideline ? tempWebsite : guideline?.website,
      error: isParentGuideline ? errors.tempWebsite : errors.website,
    },
    ...(referenceType === EGuidelineType.BUSINESSUNIT
      ? [
          {
            id: 3,
            key: strings.GUIDELINE.GENERAL_DETAILS.PARENT_WEBSITE.KEY,
            label: strings.GUIDELINE.GENERAL_DETAILS.PARENT_WEBSITE.LABEL,
            placeholder: guideline?.parentWebsite || strings.GUIDELINE.GENERAL_DETAILS.PARENT_WEBSITE.PLACEHOLDER,
            value: guideline?.parentWebsite || '',
            error: errors.parentWebsite,
            disabled: referenceType === EGuidelineType.BUSINESSUNIT,
          },
        ]
      : []),
  ]

  if (resourcesLoading || loading) {
    return (
      <div className={styles.ViewContainer}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.ViewContainer}>
      <div className={classNames(styles.saveButton, { [styles.isParentGuideline]: isParentGuideline })}>
        {isParentGuideline && (
          <div className={styles.parentGuidelineContainer}>
            <InfoIcon />
            <p className={styles.parentGuidelineFlag}>{strings.GUIDELINE.TITLES.PARENT_GUIDELINE_MSG}</p>
          </div>
        )}
        <GenericButton isDisabled={hasErrors} title={strings.GENERAL_LABELS.SAVE_CHANGES} onClick={handleSave} />
      </div>
      <div className={styles.clientGuidelinesWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.generalDetailsContainer}>
            <h3 className={styles.title}>{strings.GUIDELINE.TITLES.GENERAL_DETAILS}</h3>
            <div className={styles.inputsContainer}>
              {inputData.map((data) => (
                <div key={data.id} className={styles.inputWrapper}>
                  <label className={styles.inputLabel}>{data.label}</label>
                  <Input
                    className={styles.generalDetailsInput}
                    placeholder={data.placeholder}
                    value={data.value ?? ''}
                    onChange={(e) => handleInputChange(data.key, e.target.value)}
                    disabled={data.disabled}
                  />
                  {data.error && <span className={styles.error}>{data.error}</span>}
                </div>
              ))}
            </div>
          </div>

          <div className={styles.filesContainer}>
            <h3 className={styles.title}>{strings.GUIDELINE.TITLES.FILES}</h3>
            <FilesUploadGuideline
              referenceId={guideline?.id}
              files={files}
              firstText={strings.GUIDELINE.FILES.UPLOAD_FILE1}
              secondText={strings.GUIDELINE.FILES.UPLOAD_FILE2}
              isButton
              buttonText={strings.GUIDELINE.FILES.BUTTON}
              accept={DOCUMENT_FILE_TYPES.join(',')}
              listType="picture"
              subReferenceType={EFileSubReferenceType.FILE}
              onFileUpload={(updatedFiles) => setFiles(updatedFiles)}
              onFileRemove={handleFileRemove}
            />
          </div>
        </div>

        <div className={styles.rightContainer}>
          <div className={styles.toneOfVoiceContainer}>
            <h3 className={styles.title}>{strings.GUIDELINE.TITLES.TONE_OF_VOICE}</h3>
            <TextArea
              placeholder={strings.GUIDELINE.GENERAL_DETAILS.TONE_OF_VOICE.PLACEHOLDER}
              autoSize={{ minRows: 3, maxRows: 15 }}
              onChange={(e) => handleInputChange(strings.GUIDELINE.GENERAL_DETAILS.TONE_OF_VOICE.KEY, e.target.value)}
              value={guideline?.toneOfVoice || ''}
            />
          </div>
          <div className={styles.writingStylesContainer}>
            <WritingStyles
              values={{
                grammar: guideline?.grammar || '',
                terminology: guideline?.terminology || '',
                grammaticalStyles: guideline?.grammaticalStyles || '',
                acronyms: guideline?.acronyms || '',
              }}
            />
          </div>
          <div className={styles.logosContainer}>
            <h3 className={styles.title}>{strings.GUIDELINE.TITLES.LOGOS}</h3>
            <FilesUploadGuideline
              referenceId={guideline?.id}
              files={files}
              firstText={strings.GUIDELINE.LOGOS.UPLOAD_FILE1}
              secondText={strings.GUIDELINE.LOGOS.UPLOAD_FILE2}
              isButton
              buttonText={strings.GUIDELINE.FILES.BUTTON}
              accept={IMG_TYPE}
              listType={'picture-card'}
              subReferenceType={EFileSubReferenceType.LOGO}
              onFileUpload={(updatedFiles) => setFiles(updatedFiles)}
              onFileRemove={handleFileRemove}
            />
          </div>
          <div className={styles.fontContainer}>
            <h3 className={styles.title}>{strings.GUIDELINE.TITLES.FONT}</h3>
            <FontUpload
              files={files}
              referenceId={guideline?.id}
              firstText={strings.GUIDELINE.FONT.TEXT1}
              secondText={strings.GUIDELINE.FONT.TEXT2}
              onFileUpload={(updatedFiles) => setFiles(updatedFiles)}
            />
          </div>
          <div className={styles.colorPaletteContainer}>
            <ColorPalette />
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={openModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        primaryTitle={strings.GUIDELINE.TITLES.UPDATE_GUIDELINE_TITLE_1}
        secondaryTitle={strings.GUIDELINE.TITLES.UPDATE_GUIDELINE_TITLE_2}
        confirmButtonTItle={strings.GENERAL_LABELS.UPDATE}
        isLoading={confirmLoading}
      />
    </div>
  )
}

export { Guideline }
