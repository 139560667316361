import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { IProject } from '../../models/interfaces/project.interface'
import {
  deleteProjectService,
  fetchBusinessUnitByIdService,
  fetchBusinessUnitProjectsService,
} from '../../services/business-unit-service'
import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'

interface BusinessUnitProjectsState {
  businessUnit: IBusinessUnit | null
  businessUnitProjects: IProject[]
  loading: boolean
  error: string | null
  page: number
  limit: number
  itemsCount: number
  pagesCount: number
}

const initialState: BusinessUnitProjectsState = {
  businessUnit: null,
  businessUnitProjects: [],
  loading: true,
  error: null,
  page: PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: PAGINATION_CONFIG.DEFAULT_LIMIT,
  itemsCount: 0,
  pagesCount: 1,
}

export const fetchBusinessUnitByIdThunk = createAsyncThunk<IBusinessUnit, { businessUnitId: string }>(
  'businessUnits/fetchBusinessUnit',
  async ({ businessUnitId }) => {
    const response = await fetchBusinessUnitByIdService(businessUnitId)
    return response
  },
)

export const fetchBusinessUnitProjectsThunk = createAsyncThunk<
  { businessUnitProjects: IProject[]; meta: { itemsCount: number; pagesCount: number } },
  {
    businessUnitId: string
    page?: number
    limit?: number
    sort?: { field: string; order: 'ASC' | 'DESC' }
    filter?: { field: string; value: string }[]
    search?: string
  }
>(
  'projects/fetchBusinessUnitProjects',
  async ({
    businessUnitId,
    page = PAGINATION_CONFIG.DEFAULT_PAGE,
    limit = PAGINATION_CONFIG.DEFAULT_LIMIT,
    sort,
    filter,
    search,
  }) => {
    const businessUnitProjects = await fetchBusinessUnitProjectsService(
      businessUnitId,
      page,
      limit,
      sort,
      filter,
      search,
    )
    return businessUnitProjects
  },
)

export const deleteProjectThunk = createAsyncThunk<void, { projectId: string }>(
  'projects/deleteProject',
  async ({ projectId }) => {
    await deleteProjectService(projectId)
  },
)

const businessUnitProjectsSlice = createSlice({
  name: 'businessUnitProjects',
  initialState,
  reducers: {
    clearProjects: (state) => {
      state.businessUnitProjects = []
    },
    clearBusinessUnit: (state) => {
      state.businessUnit = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessUnitProjectsThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchBusinessUnitProjectsThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.businessUnitProjects = action.payload.businessUnitProjects
        state.itemsCount = action.payload.meta.itemsCount
        state.pagesCount = action.payload.meta.pagesCount
      })
      .addCase(fetchBusinessUnitProjectsThunk.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch businessUnitProjects'
      })
      .addCase(deleteProjectThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteProjectThunk.fulfilled, (state, action) => {
        state.loading = false
        const deletedProjectId = action.meta.arg.projectId
        state.businessUnitProjects = state.businessUnitProjects.filter((project) => project.id !== deletedProjectId)
      })
      .addCase(deleteProjectThunk.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to delete project'
      })
      .addCase(fetchBusinessUnitByIdThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchBusinessUnitByIdThunk.fulfilled, (state, action) => {
        state.businessUnit = action.payload
        state.loading = false
      })
      .addCase(fetchBusinessUnitByIdThunk.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch business unit'
      })
  },
})

export const { clearProjects, clearBusinessUnit } = businessUnitProjectsSlice.actions

export default businessUnitProjectsSlice.reducer
