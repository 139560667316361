export const strings = {
  EMPTY_CASES: {
    OFFLINE: 'It seems you’re offline. \n Please check your internet connection and try again.',
    EMPTY_PROJECTS: 'No current projects? \n Let’s change that - start your messaging project now!',
    EMPTY_CLIENTS: 'No clients? \n No problem - create a new client now!',
    EMPTY_BUSINESS_UNITS: 'No business units? \n Make your first step before a new project and add a business unit!',
    EMPTY_BUSINESS_UNITS_PROJECTS: 'No projects? \n Make the first step and create project for this business unit!',
    ERROR_MSG: 'Failed to fetch projects. Please try again.',
    GUIDELINE_ERROR: 'Guideline not found',
    EMPTY_SEARCH: 'No Results, please try typing something else',
  },
  SUCCESS_MSG: {
    CLIENT_MODAL: 'Client created successfully!',
    CLIENT_GUIDELINE: 'Guideline saved successfully!',
    BUSINESS_UNIT: 'Business unit created successfully!',
    PROJECT_MODAL: 'Project created successfully!',
    FILE_UPLOAD: 'File uploaded successfully!',
    TONE_OF_VOICE: 'Tone of voice updated successfully!',
  },
  GENERAL_LABELS: {
    ACTION_BUTTON: 'Open details for ',
    SAVING: 'Saving...',
    FORM_COMPLETE_BUTTON: 'Create',
    ERROR_BACK_BUTTON: 'BACK',
    UPLOAD: 'Upload',
    UPDATE: 'Update',
    SAVE_CHANGES: 'Save changes',
    CANCEL: 'Cancel',
    NEXT: 'Next',
    GENERATE: 'Generate',
    COMPLETED: 'Complete',
    ADD_LINK: 'Add link',
    REMOVE_LINK: 'Remove link',
    ATTACH_FILE: 'Attach file',
    ADD_TEXT_EXPLANATION: 'Add text explanation',
    ADD_MORE: 'Add more',
    KEY_WORD: 'Key words',
    REMOVE: 'Remove',
    DELETED_NOW: 'Delete now',
    ADD: 'Add',
    EDIT: 'Edit',
    DELETE: 'Delete',
    DELETE_NOW: 'Delete now',
  },
  COMMAND_CENTER: {
    TITLES: {
      MAIN: 'Command Center',
      DATA_LIST: 'My current projects',
      NEWS_SCROLLER: 'My client news',
      NEW_PROJECT: 'New project',
    },
    PROJECT_MODAL: {
      TITLE: 'New messaging project',
      LABELS: {
        PROJECT_NAME: 'Project name',
        CLIENT_NAME: 'Client name',
        BUSINESS_UNIT: 'Business unit',
        PROJECTS_MEMBERS: 'Project members',
      },
    },
    ERRORS: {
      ERROR_MSG: 'Failed to create \n messaging project',
      ERROR_MSG2: 'Please try again',
      PROJECT_ERROR: 'Error creating project',
    },
  },

  CLIENTS_PAGE: {
    TITLES: {
      MAIN: 'Clients',
      NEW_CLIENT: 'New client',
    },
    ERRORS: {
      ERROR_MSG: 'Failed to create \n client',
      ERROR_MSG2: 'Please try again',
      ERROR_BACK_BUTTON: 'BACK',
      CLIENT_ERROR: 'Error creating client',
    },
    CLIENT_MODAL: {
      TITLE: 'New client',
      LABELS: {
        CLIENT_NAME: 'Client name',
        INDUSTRY: 'Industry',
      },
    },
  },
  MOVEO_LOGO: {
    TITLE: 'Powered By MOVEO',
  },
  SPECIFIC_CLIENT_PAGE: {
    TITLES: {
      CLIENT_GUIDELINE_TAB: 'Client guidelines',
      BUSINESS_UNITS_TAB: 'Business units',
      NEW_BUSINESS_UNIT: 'New Business unit',
    },
    BUSINESSUNIT_MODAL: {
      TITLE: 'New business unit',
      LABELS: {
        UNIT_NAME: 'Unit name',
        TEAM_MEMBERS: 'Team members',
      },
      ERRORS: {
        ERROR_MSG: 'Failed to create \n business unit',
        ERROR_MSG2: 'Please try again',
        BUSINESS_UNIT_ERROR: 'Error creating business unit',
      },
    },
  },
  WIZARD: {
    HEADER: {
      BACK_BUTTON: 'Back',
      TABS: {
        DETAILS: 'Details',
        BRAND: 'Brand',
        TONE_OF_VOICE: 'Tone of Voice',
        MEMBERS: 'Members',
      },
      TONE_OF_VOICE_MODAL: {
        MODAL_TITLE1: 'Are you sure you want to adjust \n tone of voice?',
        MODAL_TITLE2: 'The entire project will be updated with the new tone',
        MODAL_SAVE: 'Adjust tone',
        MODAL_CANCEL: 'Cancel',
        TOOL_TIP: 'You can adjust the tone for this specific project',
      },
      DROPDOWN: {
        EXPORT: 'Export',
        DELETE_PROJECT: 'Delete project',
      },
      MEMBERS_TAB: {
        TITLE: 'Project Members',
        SEARCH_PLACEHOLDER: 'Search members by name',
      },
      ERROR_MSGS: {
        TONE_OF_VOICE: 'Failed to update tone of voice',
      },
    },
    DISCOVERY: {
      TITLES: {
        TITLE: 'Discovery',
        SUBTITLE: 'Gather key information about your product',
      },
      ERRORS: {
        LINK_ERROR: 'Please complete the current link field before adding a new one.',
      },
      GENERAL_INFORMATION: {
        LABEL: ' General information',
        TITLE: 'Provide an overview of the product or service in question',
        PLACE_HOLDER: 'Explanation',
      },
      CLIENT_REFERENCE: {
        LABEL: 'Client reference',
        TITLE: 'Attach client-provided product/service content',
      },
      TARGET_AUDIENCE: {
        LABEL: 'Target audience',
        TITLE: 'Who are the primary and secondary audiences?',
        PLACE_HOLDER: 'Primary target audience',
        PLACE_HOLDER2: 'Secondary target audience',
        BUTTON: 'Add secondary target audience',
        BUTTON2: 'Remove secondary target audience',
      },
      DISCOVERY_SUMMARY: {
        LABEL: 'Discovery summary',
        TITLE: 'Attach the discovery summery or discovery notes document',
      },
      CUSTOMER_CASE_STUDIES: {
        LABEL: 'Customer case studies',
        TITLE: 'Include any relevant customer case studies',
        PLACE_HOLDER: 'Customer case studies link',
      },
      PROOF: {
        LABEL: 'Proof',
        TITLE:
          'Do you have any third-party reports or proof points, TCO studies, or relevant facts or statistics to include?',
        PLACE_HOLDER: 'Proof link',
        TEXT_EXPLANATION: 'You can also add text explanation',
        TEXT_EXPLANATION_PLACE_HOLDER: 'Proof points validation',
        REMOVE_TEXT_EXPLANATION: 'Remove text explanation',
      },
      ADD_MORE_INFORMATION: {
        LABEL: 'Add more information',
        TITLE: 'Provide any additional insights or information',
        PLACE_HOLDER: 'Information',
      },
      ATTACH_LINKS_AND_FILES: {
        LABEL: 'Attach links and files',
        TITLE: 'Attach any other files/links from client that includes relevant information',
        PLACE_HOLDER: 'Explanation about the attached file/link',
      },
    },
    COMPETITIVE_ANALYSIS: {
      TITLE: 'Competitors Information',
      SUB_TITLE: 'Identify top competitors to analyze',
      ITEM_TITLE: 'New competitor',
      ADD_ANOTHER_ITEM: 'Add another competitor',
      ADD_ITEM: 'Add competitor',
      ADD_KEY_WORD: 'Add keyword',
      COMPETITIVE_COMPONENT_TITLE: 'Products/Service identifying details',
      ERROR: 'You must change company name before adding another competitor',
      DEFAULT_NAME: 'newCompetitor',
      INPUTS: {
        COMPANY_NAME: {
          KEY: 'companyName',
          LABEL: 'Company name',
          PLACEHOLDER: 'Company name',
        },
        CATEGORY: {
          KEY: 'category',
          LABEL: 'Category',
          PLACEHOLDER: 'Category',
        },
        PRODUCT__NAME: {
          KEY: 'productName',
          LABEL: 'Product/Service name',
          PLACEHOLDER: 'Product/Service name',
        },
        URL: {
          KEY: 'url',
          LABEL: 'Product/Service website URL',
          PLACEHOLDER: 'Product/Service website URL',
        },
        KEY_WORD: {
          KEY: 'keywords',
          PLACEHOLDER: 'Key word',
        },
      },
      DELETE_PANEL1: 'Delete panel',
      DELETE_PANEL2: 'Are you sure you want to delete this panel?',
    },
    ADDITIONAL_FILES: {
      ATTACH_LINKS_AND_FILES: 'Attach links and files',
      ADD_MORE_INFO: 'Add more information',
    },
    MESSAGING_ENGINE: {
      TITLE: 'Messaging Engine',
      SUB_TITLE: 'Create differentiated, audience-focused messaging',
      ADD_MORE_INFORMATION: 'Add more information',
      ADD_MORE_INFORMATION_TITLE: 'Provide any additional insights or information',
      ADD_MORE_LINKS_AND_FILES: 'Attach more links and files',
    },
  },
  GUIDELINE: {
    TITLES: {
      GENERAL_DETAILS: 'General details',
      FILES: 'Files',
      TONE_OF_VOICE: 'Tone of voice',
      WRITING_STYLES: 'Writing styles',
      LOGOS: 'Logos',
      FONT: 'Font',
      COLOR_PALETTE: 'Color palette',
      UPDATE_GUIDELINE_TITLE_1: 'You are about to create a new guideline for this business unit',
      UPDATE_GUIDELINE_TITLE_2: 'By clicking Update you will create A new guideline for this unit',
      PARENT_GUIDELINE_MSG:
        'Business units inherit the guidelines of the parent company. If this business unit has unique guidelines, you can enter those details below.',
    },
    ERRORS: {
      GUIDELINE_ERROR: 'Error saving guideline',
    },
    WRITING_STYLES: {
      PUNCTUATION: {
        TITLE: 'Grammar rules and punctuation',
        PLACE_HOLDER: 'Enter content for grammar rules and punctuation',
      },
      SYNTAX: {
        TITLE: 'Word usage and terminology',
        PLACE_HOLDER: 'Enter content for Word usage and terminology',
      },
      GRAMMATICAL_STYLES: {
        TITLE: 'Grammatical Styles',
        PLACE_HOLDER: 'Enter content for Grammatical styles',
      },
      FORMATTING_STYLES: {
        TITLE: 'Acronyms and abbreviations',
        PLACE_HOLDER: 'Enter content for acronyms and abbreviations',
      },
    },
    GENERAL_DETAILS: {
      GENERAL_INFORMATION: {
        LABEL: 'General information',
        PLACEHOLDER1: 'Provide information about your client',
        PLACEHOLDER2: 'Add information about this business unit',
        KEY: 'generalInformation',
      },
      WEBSITE: {
        LABEL: 'website',
        PLACEHOLDER: 'Add URL',
        KEY: 'website',
      },
      PARENT_WEBSITE: {
        LABEL: 'Parent website',
        PLACEHOLDER: 'Add URL',
        KEY: 'parentWebsite',
      },
      TONE_OF_VOICE: {
        LABEL: 'Tone of voice',
        PLACEHOLDER: 'Describe tone of voice (e.g., bold, friendly, professional)',
        KEY: 'toneOfVoice',
      },
    },
    FILES: {
      UPLOAD_FILE1: 'Upload existing style guides or reference materials',
      UPLOAD_FILE2: 'PDF, DOCX, PPT...',
      BUTTON: 'Browse Files',
    },
    LOGOS: {
      UPLOAD_FILE1: 'Click or drag and drop files to this area to upload',
      UPLOAD_FILE2: 'PNG, JPEG, SVG...',
      BUTTON: 'Browse Files',
    },
    FONT: {
      TEXT1: 'Upload font file',
      TEXT2: 'Fonts files only: TTF, OTF, WOFF...',
    },
  },
  BUSINESS_UNIT_PAGE: {
    TABS: {
      TAB1: 'Messaging projects',
      TAB2: 'Business unit guidelines',
    },
    DELETE_MODAL: {
      TITLE1: 'Delete messaging project',
      TITLE2: 'Are you sure you want to delete this project?',
    },
  },
  AI_ASSISTANT: {
    TITLE: 'AI Assistant',
    COPY_ANSWER: 'copy answer',
  },
}
