import { FC } from 'react'
import styles from './WizardTab.module.scss'
import classNames from 'classnames'

interface IWizardTabProps {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  title: string
  isBold?: boolean
  onClick?: () => void
}
const WizardTab: FC<IWizardTabProps> = ({ Icon, title, isBold, onClick }) => {
  return (
    <div className={styles.tab} onClick={onClick}>
      {Icon && <Icon />}
      <span className={classNames(styles.title, { [styles.bold]: isBold })}>{title}</span>
    </div>
  )
}

export { WizardTab }
