import { Collapse, message } from 'antd'
import { FC, useEffect, useState } from 'react'

import {
  AddMoreFilesIcon,
  AddMoreInfoIcon,
  ClientReferenceIcon,
  CompletedGreenIcon,
  CustomerCaseStudiesIcon,
  DiscoverySummaryIcon,
  GeneralInformationIcon,
  ProofIcon,
  TargetAudienceIcon,
} from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EDiscoveryDataField } from '../../../models/enums/discovery-data-fields.enum'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import { IFile } from '../../../models/interfaces/file.interface'
import {
  EWizardResponseType,
  IWizardResponseType,
} from '../../../models/interfaces/responses/wizard-response-type.interface'
import { getFilesService } from '../../../services/files-upload-service'
import {
  createOrUpdateDiscovery,
  getDiscoveryThunk,
  resetDiscovery,
  updateDiscovery,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import GeneralInformation from './GeneralInformation/GeneralInformation'
import TargetAudience from './TargetAudience/TargetAudience'
import UploadFilesDropDown from './UploadFilesDropDown/UploadFilesDropDown'
import styles from './WizardDiscovery.module.scss'

interface IWizardDiscoveryProps {
  messagingId?: string
  nextStep: () => void
  onProgressUpdate: (percent: number) => void
  AILoading: (isLoading: boolean) => void
}

const WizardDiscovery: FC<IWizardDiscoveryProps> = ({ messagingId, nextStep, onProgressUpdate, AILoading }) => {
  const dispatch = useAppDispatch()
  const discovery = useAppSelector((state) => state.wizard.data?.discovery)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])
  const [isAddMoreInfo, setIsAddMoreInfo] = useState<boolean>(false)
  const [isAddMoreLinksAndFiles, setIsAddMoreLinksAndFiles] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (!messagingId) return

      try {
        const resultAction = await dispatch(getDiscoveryThunk({ messagingId }))
        if (!getDiscoveryThunk.fulfilled.match(resultAction)) {
          console.error('Failed to fetch discovery data')
          return
        }

        const disc = resultAction.payload
        if (!disc?.id) return

        const files: IFile[] = await getFilesService(EFileReferenceType.DISCOVERY, disc.id)

        const clientReferencesFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.CLIENT_REFERENCE,
        )
        const discoverySummaryFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.DISCOVERY_SUMMARY,
        )
        const customerCaseStudiesFiles = files.filter(
          (file) => file.subReferenceType === EFileSubReferenceType.CUSTOMER_CASE_STUDIES,
        )
        const additionalFiles = files.filter((file) => file.subReferenceType === EFileSubReferenceType.ADDITIONAL_FILES)

        const updatedClientReferences: IWizardResponseType[] = clientReferencesFiles.map((file) => ({
          type: EWizardResponseType.FILE,
          value: file.fileUrl,
        }))

        const updatedDiscoverySummary: IWizardResponseType[] = discoverySummaryFiles.map((file) => ({
          type: EWizardResponseType.FILE,
          value: file.fileUrl,
        }))

        const updatedCustomerCaseStudies: IWizardResponseType[] = customerCaseStudiesFiles.map((file) => ({
          type: EWizardResponseType.FILE,
          value: file.fileUrl,
        }))

        const updatedAdditionalFiles: IWizardResponseType[] = additionalFiles.map((file) => ({
          type: EWizardResponseType.FILE,
          value: file.fileUrl,
        }))

        dispatch(
          updateDiscovery({
            discoveryData: {
              ...disc.discoveryData,
              clientReferences: updatedClientReferences,
              discoverySummary: updatedDiscoverySummary,
              customerCaseStudies: updatedCustomerCaseStudies,
              moreInformation: updatedAdditionalFiles,
            },
          }),
        )
      } catch (error) {
        console.error('Error fetching file URLs:', error)
        message.error('Error fetching file details.')
      }
    })()

    return () => {
      dispatch(resetDiscovery())
    }
  }, [dispatch, messagingId])

  useEffect(() => {
    if (!discovery?.discoveryData) return

    const { discoveryData } = discovery
    const stepsMapping = [
      { key: '1', field: discoveryData.generalInformation },
      { key: '2', field: discoveryData.clientReferences },
      { key: '3', field: discoveryData.targetAudience },
      { key: '4', field: discoveryData.discoverySummary },
      { key: '5', field: discoveryData.customerCaseStudies },
      { key: '6', field: discoveryData.proof },
      { key: '7', field: discoveryData.moreInformation },
    ]
    const completed = stepsMapping.filter(({ field }) => Array.isArray(field) && field.length > 0).map(({ key }) => key)
    setCompletedKeys(completed)
  }, [discovery])

  const saveDiscoveryData = async () => {
    if (messagingId && discovery) {
      const { metadata, discoveryText, createdAt, updatedAt, ...filteredDiscovery } = discovery
      AILoading(true)

      await dispatch(
        createOrUpdateDiscovery({
          discoveryData: filteredDiscovery,
        }),
      )
      pollForUpdatedDiscovery()
    }
  }

  const pollForUpdatedDiscovery = () => {
    const intervalId = setInterval(async () => {
      try {
        if (messagingId) {
          const resultAction = await dispatch(getDiscoveryThunk({ messagingId }))

          if (getDiscoveryThunk.rejected.match(resultAction)) {
            throw new Error(resultAction.error.message || 'Error fetching discovery')
          }

          const updatedDiscovery = resultAction.payload
          if (!updatedDiscovery) return

          if ('discoveryText' in updatedDiscovery && updatedDiscovery.discoveryText) {
            AILoading(false)
            clearInterval(intervalId)
          }
        }
      } catch (error) {
        console.error('Polling error:', error)
        AILoading(false)
        clearInterval(intervalId)
      }
    }, 5000)
  }

  const handleNext = (currentKey: string, field: EDiscoveryDataField, value: IWizardResponseType[]) => {
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const updatedField: IWizardResponseType[] = value

    dispatch(
      updateDiscovery({
        discoveryData: {
          ...discovery?.discoveryData,
          [field]: updatedField,
        },
      }),
    )

    setCompletedKeys((prev) => {
      if (prev.includes(currentKey)) {
        return prev
      }
      return [...prev, currentKey]
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }
  const items: AccordionItem[] = [
    {
      key: '1',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.generalInformation}
          onNext={(data) => handleNext('1', EDiscoveryDataField.GENERAL_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
        />
      ),
    },
    {
      key: '2',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <ClientReferenceIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.clientReferences}
          onNext={(data) => handleNext('2', EDiscoveryDataField.CLIENT_REFERENCES, data)}
          title={strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.TITLE}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CLIENT_REFERENCE}
        />
      ),
    },
    {
      key: '3',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <TargetAudienceIcon style={{ marginRight: 8 }} />,
      children: (
        <TargetAudience
          value={discovery?.discoveryData.targetAudience}
          onNext={(data) => handleNext('3', EDiscoveryDataField.TARGET_AUDIENCE, data)}
        />
      ),
    },
    {
      key: '4',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <DiscoverySummaryIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.discoverySummary}
          onNext={(data) => handleNext('4', EDiscoveryDataField.DISCOVERY_SUMMARY, data)}
          title={strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.TITLE}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.DISCOVERY_SUMMARY}
        />
      ),
    },
    {
      key: '5',
      label: 'Customer case studies',
      icon: <CustomerCaseStudiesIcon style={{ marginRight: 8 }} />,

      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.customerCaseStudies}
          onNext={(data) => handleNext('5', EDiscoveryDataField.CUSTOMER_CASE_STUDIES, data)}
          title={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CUSTOMER_CASE_STUDIES}
        />
      ),
    },
    {
      key: '6',
      label: 'Proof',
      icon: <ProofIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.proof}
          onNext={(data) => handleNext('6', EDiscoveryDataField.PROOF, data)}
          title={strings.WIZARD.DISCOVERY.PROOF.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.PROOF.PLACE_HOLDER}
          isTextExplanation
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.PROOF}
        />
      ),
    },
  ]

  if (isAddMoreInfo || discovery?.discoveryData.moreInformation?.length) {
    items.push({
      key: '7',
      label: strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.LABEL,
      icon: <AddMoreInfoIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.moreInformation}
          onNext={(data) => handleNext('7', EDiscoveryDataField.MORE_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.PLACE_HOLDER}
        />
      ),
    })
  }

  if (isAddMoreLinksAndFiles || !!discovery?.discoveryData.moreInformation?.length) {
    items.push({
      key: '8',
      label: strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.LABEL,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.moreInformation}
          onNext={(data) => handleNext('8', EDiscoveryDataField.MORE_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
        />
      ),
    })
  }

  const requiredKeys = ['1', '2', '3', '4']
  const availableGenerate: boolean = requiredKeys.every((key) => completedKeys.includes(key))

  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.DISCOVERY.TITLES.TITLE}
      subTitle={strings.WIZARD.DISCOVERY.TITLES.SUBTITLE}
      availableGenerate={availableGenerate}
      nextStep={saveDiscoveryData}
      step={1}
      isAddMoreInfo
      addMoreInfo={() => setIsAddMoreInfo(true)}
      addMoreLinksAndFiles={() => setIsAddMoreLinksAndFiles(true)}
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardDiscovery
