import { FC } from 'react'

import { strings } from '../../../../../../models/consts/strings'
import { CustomModal } from '../../../../../CustomModal/CustomModal'
import { GenericButton } from '../../../../../GenericButton/GenericButton'
import styles from './ToneOfVoiceModalSave.module.scss'

interface IToneOfVoiceModalSaveProps {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}

const ToneOfVoiceModalSave: FC<IToneOfVoiceModalSaveProps> = ({ onCancel, isOpen, onOk }) => {
  return (
    <CustomModal isOpen={isOpen} onCancel={onCancel}>
      <div className={styles.topSection}>
        <h1 className={styles.primaryTitle}>{strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.MODAL_TITLE1}</h1>
        <h2 className={styles.secondaryTitle}> {strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.MODAL_TITLE2}</h2>
      </div>
      <div className={styles.buttons}>
        <GenericButton title={strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.MODAL_SAVE} onClick={onOk} />
        <GenericButton secondary title={strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.MODAL_CANCEL} onClick={onCancel} />
      </div>
    </CustomModal>
  )
}

export { ToneOfVoiceModalSave }
