import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import { INews } from '../models/interfaces/news.interface'
import { apiRequest } from '../utils/api-utils/api'

const fetchUserData = async () => {
  return await apiRequest('GET', apiEndpoints.USERS.GET_USER_DATA)
}

const fetchUserProjects = async (
  userId: string,
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
) => {
  const payload = {
    userId,
    page,
    limit,
    sort,
    filter,
  }
  const response = await apiRequest('POST', apiEndpoints.PROJECTS.GET_PROJECTS, payload)
  const projects = response.data.projects
  return {
    projects,
    meta: response.data.meta,
  }
}

const fetchCompaniesNews = async () => {
  const response = await apiRequest('GET', apiEndpoints.NEWS.GET_NEWS)
  const news = response.data.map((news: INews) => {
    const [name] = news.relatedKeywords || []
    return {
      name,
      title: news.title,
      description: news.description,
      content: news.content,
      publishedAt: news.publishedAt,
      source: news.source,
      url: news.url,
    }
  })
  return news
}

const createProjectApi = async (name: string, businessUnitId: string, userIds: string[]) => {
  const response = await apiRequest('POST', apiEndpoints.PROJECTS.CREATE_PROJECT, {
    name,
    businessUnitId,
    userIds,
  })
  return response
}

const fetchClientsService = async (
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
) => {
  const payload = {
    page,
    limit,
    sort,
    filter,
  }
  return await apiRequest('POST', apiEndpoints.CLIENTS.GET_CLIENTS, payload)
}

const fetchBusinessUnitsService = async (
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  filter?: { field: string; value: string }[],
  sort?: { field: string; order: 'ASC' | 'DESC' },
  search?: string,
) => {
  const payload = {
    page,
    limit,
    filter,
    sort,
    search,
  }
  const response = await apiRequest('POST', apiEndpoints.BUSINESS_UNITS.GET_BUSINESS_UNITS, payload)
  const businessUnits = response.data.businessUnits
  return {
    businessUnits,
    meta: response.data.meta,
  }
}

const fetchAllUsers = async () => {
  return await apiRequest('GET', apiEndpoints.USERS.GET_ALL_USERS)
}

export {
  createProjectApi,
  fetchAllUsers,
  fetchBusinessUnitsService,
  fetchClientsService,
  fetchCompaniesNews,
  fetchUserData,
  fetchUserProjects,
}
