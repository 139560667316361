export enum EWizardResponseType {
  TEXT = 'text',
  URL = 'url',
  FILE = 'file',
}

export interface IWizardResponseType {
  type: EWizardResponseType
  value: string
}
