export enum EStatusEnum {
  DRAFTS = 'DRAFTS',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DISCOVERY = 'Discovery',
  COMPETITIVE_ANALYSIS = 'Competitive Analysis',
  MESSAGING_ENGINE = 'Messaging Engine',
  INTERNAL = 'Internal',
  EXTERNAL = 'External',
  CLIENT = 'Client',
  DEFAULT = '',
}
