import { Tabs } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { PlusIcon } from '../../assets/icons'
import BusinessUnitModal from '../../components/BusinessUnitModal/BusinessUnitModal'
import { GenericButton } from '../../components/GenericButton/GenericButton'
import { Guideline } from '../../components/Guideline/Guideline'
import { ListView } from '../../components/ListView/ListView'
import { ListViewContainer } from '../../components/ListViewContainer/ListViewContainer'
import { Pagination } from '../../components/Pagination/Pagination'
import { SearchInput } from '../../components/SearchInput/SearchInput'
import { useDebounce } from '../../hooks/debounce.hook'
import { specificClientColumnKeyMap } from '../../models/consts/column-kep-map'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { strings } from '../../models/consts/strings'
import { EAppRoutes } from '../../models/enums/app-routes.enum'
import { EComponentType } from '../../models/enums/componentType.enum'
import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import { IClient } from '../../models/interfaces/client.interface'
import { fetchBusinessUnitsThunk } from '../../store/slices/specific-client.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './SpecificClient.module.scss'
import { specificClientColumns } from './specific-client.utils'
import { EGuidelineType } from '../../models/enums/guideline-type.enum'
import { fetchClientsThunk } from '../../store/slices/clients.slice'

const { TabPane } = Tabs

const SpecificClient: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { clientId } = useParams<{ clientId: string }>()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const { businessUnits, loading, pagesCount } = useAppSelector((state) => state.businessUnits)
  const { clients } = useAppSelector((state) => state.clients)

  const [currentPage, setCurrentPage] = useState<number>(PAGINATION_CONFIG.DEFAULT_PAGE)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const initialTabKey = location.pathname.includes('/guideline') ? '2' : '1'
  const [activeTab, setActiveTab] = useState(initialTabKey)
  const [client, setClient] = useState<IClient>()

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchBusinessUnitsThunk({
          clientId,
          page: currentPage,
          search: debouncedSearchValue.trim(),
        }),
      )
      dispatch(
        fetchClientsThunk({
          filter: [{ field: 'id', value: clientId }],
        }),
      )
    }
  }, [clientId, currentPage, debouncedSearchValue, dispatch])

  useEffect(() => {
    if (clients && clientId) {
      const foundClient = clients.find((c) => c.id === clientId)
      if (foundClient) {
        setClient(foundClient)
      }
    }
  }, [clients, clientId])

  const handlePageChange = (newPage: number) => {
    if (clientId) {
      setCurrentPage(newPage)
      dispatch(
        fetchBusinessUnitsThunk({
          clientId,
          page: newPage,
        }),
      )
    }
  }

  const handleRowClick = (businessUnit: IBusinessUnit) => {
    navigate(`${EAppRoutes.BUSINESS_UNIT}/${businessUnit.id}`, { state: { businessUnit } })
  }

  const handleModalOk = () => {
    if (clientId) {
      setOpenModal(false)
      dispatch(fetchBusinessUnitsThunk({ clientId }))
    }
  }
  const isEmptySearch: boolean = !!debouncedSearchValue && businessUnits.length === 0
  const noData: boolean = !debouncedSearchValue && businessUnits.length === 0

  return (
    <div className={styles.specificClientContainer}>
      <h1 className={styles.title}>{client?.name}</h1>
      <Tabs
        className={styles.tabsContainer}
        activeKey={activeTab}
        onChange={(key) => {
          setActiveTab(key)
          if (clientId) {
            if (key === '2') {
              navigate(`/clients/${clientId}/guideline`)
            } else {
              navigate(`/clients/${clientId}`)
            }
          }
        }}
      >
        <TabPane className={styles.tabsContainer} tab={strings.SPECIFIC_CLIENT_PAGE.TITLES.BUSINESS_UNITS_TAB} key="1">
          <div className={styles.wrapper}>
            <div className={styles.topContainer}>
              <SearchInput width="25%" onSearch={(value) => setSearchValue(value)} />
              <GenericButton
                title={strings.SPECIFIC_CLIENT_PAGE.TITLES.NEW_BUSINESS_UNIT}
                onClick={() => setOpenModal(true)}
                Icon={PlusIcon}
              />
            </div>
            <div className={styles.bottomContainer}>
              <ListViewContainer
                isButton={false}
                buttonTitle={strings.SPECIFIC_CLIENT_PAGE.TITLES.NEW_BUSINESS_UNIT}
                componentType={EComponentType.CLIENT}
              >
                <ListView
                  onRowClick={(businessUnit) => handleRowClick(businessUnit)}
                  columns={specificClientColumns}
                  data={businessUnits}
                  columnKeyMap={specificClientColumnKeyMap}
                  noSearchResults={isEmptySearch}
                  noData={noData}
                  isLoading={loading}
                  emptyMsg={strings.EMPTY_CASES.EMPTY_BUSINESS_UNITS}
                  setModalOpen={() => setOpenModal(true)}
                  buttonTitle={strings.SPECIFIC_CLIENT_PAGE.TITLES.NEW_BUSINESS_UNIT}
                />
              </ListViewContainer>
            </div>
            {pagesCount > 1 && (
              <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={handlePageChange} />
            )}
          </div>
        </TabPane>
        <TabPane tab={strings.SPECIFIC_CLIENT_PAGE.TITLES.CLIENT_GUIDELINE_TAB} key="2">
          <Guideline referenceType={EGuidelineType.CLIENT} referenceId={clientId} />
        </TabPane>
      </Tabs>
      <BusinessUnitModal
        clientId={clientId}
        isOpen={openModal}
        onOk={handleModalOk}
        onCancel={() => setOpenModal(false)}
      />
    </div>
  )
}
export { SpecificClient }
