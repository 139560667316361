import { Button, message } from 'antd'
import { FC, useEffect, useState } from 'react'

import { ModalGif } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import { IBusinessUnit } from '../../models/interfaces/business-unit.interface'
import { IClient } from '../../models/interfaces/client.interface'
import { IUser } from '../../models/interfaces/users.interface'
import { createProjectThunk } from '../../store/slices/user-projects.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { CustomModal } from '../CustomModal/CustomModal'
import { ModalError } from '../CustomModal/ModalError/ModalError'
import { ModalInput } from '../CustomModal/ModalInput/ModalInput'
import { handleFetchBusinessUnits, handleFetchClients, handleFetchUsers } from '../ProjectModal/project-modal.utils'
import styles from './ProjectModal.module.scss'

interface IProjectModalProps {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
  defaultClientName?: string
  defaultBusinessUnitName?: string
  defaultBusinessUnitId?: string
  areFieldsDisabled?: boolean
}

const ProjectModal: FC<IProjectModalProps> = ({
  isOpen,
  onOk,
  onCancel,
  defaultClientName,
  defaultBusinessUnitName,
  areFieldsDisabled = false,
  defaultBusinessUnitId,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const [projectName, setProjectName] = useState<string>('')
  const [selectedClient, setSelectedClient] = useState<string | undefined>(defaultClientName)
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string | undefined>(defaultBusinessUnitId)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [clients, setClients] = useState<IClient[]>([])
  const [businessUnits, setBusinessUnits] = useState<IBusinessUnit[]>([])
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    handleFetchUsers(setUsers)
    if (user) {
      setSelectedUsers([user.id])
    }
  }, [user])

  useEffect(() => {
    if (!defaultClientName) {
      handleFetchClients(setClients)
    }
  }, [defaultClientName])

  useEffect(() => {
    const client = clients.find((client) => client.name === selectedClient)
    if (client) {
      handleFetchBusinessUnits(client.id, setBusinessUnits)
    }
  }, [clients, selectedClient])

  useEffect(() => {
    if (isOpen) {
      setProjectName('')
      setSelectedClient(defaultClientName)
      setSelectedBusinessUnit(defaultBusinessUnitId)
      setSelectedUsers([user.id])
    }
  }, [isOpen, defaultClientName, defaultBusinessUnitId, user.id])

  const resetFields = () => {
    setProjectName('')
    setSelectedClient(undefined)
    setSelectedBusinessUnit(undefined)
    setSelectedUsers([user.id])
  }

  const handleOk = async () => {
    const isFormValid = areFieldsDisabled
      ? !!projectName
      : !!(projectName && selectedClient && selectedBusinessUnit && selectedUsers && user)

    if (!isFormValid) return
    if (!selectedBusinessUnit) return

    setIsLoading(true)
    try {
      await dispatch(
        createProjectThunk({
          name: projectName,
          businessUnitId: selectedBusinessUnit,
          userIds: selectedUsers,
        }),
      ).unwrap()
      message.success(strings.SUCCESS_MSG.PROJECT_MODAL)
    } catch (error) {
      setIsError(true)
      message.error({ content: strings.COMMAND_CENTER.ERRORS.PROJECT_ERROR, duration: 3 })
    } finally {
      setTimeout(() => {
        onOk()
        setIsLoading(false)
      }, 1800)
    }
  }

  const handleCancel = () => {
    resetFields()
    onCancel()
  }

  const inputData = [
    {
      key: 1,
      label: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.PROJECT_NAME,
      placeholder: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.PROJECT_NAME,
      value: projectName,
      onChange: setProjectName,
    },
    {
      key: 2,
      label: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.CLIENT_NAME,
      placeholder:
        areFieldsDisabled && defaultClientName
          ? defaultClientName
          : strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.CLIENT_NAME,
      value: areFieldsDisabled ? undefined : selectedClient,
      onChange: (name: string) => {
        const client = clients.find((client) => client.name === name)
        if (client) {
          setSelectedClient(client.name)
          setSelectedBusinessUnit(undefined)
        }
      },
      options: clients,
      isDisabled: areFieldsDisabled,
    },
    {
      key: 3,
      label: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.BUSINESS_UNIT,
      placeholder:
        areFieldsDisabled && defaultBusinessUnitName
          ? defaultBusinessUnitName
          : strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.BUSINESS_UNIT,
      value: selectedBusinessUnit
        ? businessUnits.find((unit) => unit.id === selectedBusinessUnit)?.name || defaultBusinessUnitName
        : undefined,
      onChange: (name: string) => {
        const businessUnit = businessUnits.find((unit) => unit.name === name)
        if (businessUnit) {
          setSelectedBusinessUnit(businessUnit.id)
        }
      },
      options: businessUnits,
      isDisabled: !selectedClient || areFieldsDisabled,
    },

    {
      key: 4,
      label: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.PROJECTS_MEMBERS,
      placeholder: strings.COMMAND_CENTER.PROJECT_MODAL.LABELS.PROJECTS_MEMBERS,
      value: selectedUsers,
      onChange: (values: string[]) => {
        const updatedValues = [...new Set([...values.filter((v) => v !== 'you'), user.id])]
        setSelectedUsers(updatedValues)
      },
      options: users,
      isMultiple: true,
      connectedUser: user,
      IsMembersInput: true,
    },
  ]

  return (
    <CustomModal isOpen={isOpen} onCancel={handleCancel} className="project">
      {isError ? (
        <ModalError
          secondMsg={strings.COMMAND_CENTER.ERRORS.ERROR_MSG2}
          firstMsg={strings.COMMAND_CENTER.ERRORS.ERROR_MSG}
          onClose={() => setIsError(false)}
        />
      ) : (
        <>
          <div>
            <h1 className={styles.projectModalTitle}>{strings.COMMAND_CENTER.PROJECT_MODAL.TITLE}</h1>
          </div>
          <div className={styles.modalFields}>
            {inputData.map((data) => (
              <ModalInput
                key={data.key}
                label={data.label}
                placeholder={data.placeholder}
                value={data.value}
                onChange={data.onChange}
                options={data.options}
                isMultiple={data.isMultiple}
                isDisabled={data.isDisabled}
                connectedUser={user}
                isMembersInput={data.IsMembersInput}
              />
            ))}
          </div>
          <div className={styles.projectModalFooter}>
            {isLoading ? (
              <img className={styles.gifAnimation} src={ModalGif} alt={strings.GENERAL_LABELS.SAVING} />
            ) : (
              <Button
                type="primary"
                className={styles.projectModalSaveButton}
                onClick={handleOk}
                disabled={
                  areFieldsDisabled
                    ? !projectName
                    : !projectName || !selectedClient || !selectedBusinessUnit || selectedUsers.length === 0
                }
              >
                {strings.GENERAL_LABELS.FORM_COMPLETE_BUTTON}
              </Button>
            )}
          </div>
        </>
      )}
    </CustomModal>
  )
}

export { ProjectModal }
