import { FC, useEffect, useState } from 'react'

import { CloseIcon, CopyTextIcon, MagicPenIcon2, MagicPenIcon3 } from '../../../assets/icons'
import { GenericButton } from '../../GenericButton/GenericButton'
import styles from './WizardAIAssistant.module.scss'
import { message } from 'antd'
import { strings } from '../../../models/consts/strings'

interface IConversation {
  question: string
  answer: string
}

interface IWizardAIAssistantProps {
  onClose: () => void
  onOk: (question: string) => void
  aiAnswer: string
  isLoading: boolean
}

const WizardAIAssistant: FC<IWizardAIAssistantProps> = ({ onClose, onOk, aiAnswer, isLoading }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [conversations, setConversations] = useState<IConversation[]>([])
  const [dotCount, setDotCount] = useState<number>(1)

  useEffect(() => {
    let interval: NodeJS.Timer
    if (isLoading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev === 3 ? 1 : prev + 1))
      }, 500)
    } else {
      setDotCount(1)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading && aiAnswer && conversations.length > 0) {
      let i = 0
      const text = aiAnswer

      setConversations((prev) => {
        const updated = [...prev]
        updated[0] = { ...updated[0], answer: '' }
        return updated
      })

      const interval = setInterval(() => {
        i++
        setConversations((prev) => {
          const updated = [...prev]
          updated[0] = {
            ...updated[0],
            answer: text.slice(0, i),
          }
          return updated
        })
        if (i >= text.length) {
          clearInterval(interval)
        }
      }, 25)

      return () => clearInterval(interval)
    }
  }, [isLoading, aiAnswer, conversations.length])

  const dots = '.'.repeat(dotCount)

  const onGenerate = () => {
    if (!inputValue) return

    setConversations((prev) => [{ question: inputValue, answer: '' }, ...prev])

    onOk(inputValue)
    setInputValue('')
  }
  return (
    <>
      <div className={styles.WizardAIAssistant}>
        <div className={styles.topContainer}>
          <div className={styles.title}>
            <MagicPenIcon3 />
            <h2 className={styles.titleText}>{strings.AI_ASSISTANT.TITLE}</h2>
          </div>
          <div className={styles.buttonBackground}>
            <CloseIcon className={styles.closeButton} onClick={onClose} />
          </div>
        </div>
        <hr className={styles.hrBorder} />
        <div className={styles.midContainer}>
          {conversations.map((c, idx) => (
            <div key={idx} className={styles.conversationBlock}>
              <div className={styles.aiQuestion}>{c.question}</div>
              {idx === 0 && isLoading ? (
                <div className={styles.loading}>{dots}</div>
              ) : (
                <div className={styles.aiAnswer}>{c.answer}</div>
              )}
              {c.answer && (
                <div
                  onClick={() => {
                    navigator.clipboard
                      .writeText(c.answer)
                      .then(() => {
                        message.info('Answer copied to clipboard!')
                      })
                      .catch((err) => {
                        message.error('Could not copy text: ', err)
                      })
                  }}
                  className={styles.copyText}
                >
                  <CopyTextIcon />
                  <p>{strings.AI_ASSISTANT.COPY_ANSWER}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.bottomContainer}>
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={styles.input}
            type="text"
            placeholder="Ask, edit or explain..."
          />
          <GenericButton Icon={MagicPenIcon2} onClick={onGenerate} />
        </div>
      </div>
    </>
  )
}

export default WizardAIAssistant
