import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './App.css'
import './assets/styles/_index.scss'
import { AppRouter } from './pages/AppRouter'
import { fetchConnectedUser } from './store/slices/user.slice'
import { useAppDispatch } from './store/store'
import { CustomNavigationClient } from './utils/navigation-client'

interface AppProps {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  useEffect(() => {
    dispatch(fetchConnectedUser())
  }, [dispatch])

  return (
    <MsalProvider instance={pca}>
      <AppRouter />
    </MsalProvider>
  )
}

export default App
