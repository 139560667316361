import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'

import {
  AddMoreFilesIcon,
  AddMoreInfoIcon,
  ClientReferenceIcon,
  CompletedGreenIcon,
  CustomerCaseStudiesIcon,
  DiscoverySummaryIcon,
  GeneralInformationIcon,
  ProofIcon,
  TargetAudienceIcon,
} from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EDiscoveryDataField } from '../../../models/enums/discovery-data-fields.enum'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import { IWizardResponseType } from '../../../models/interfaces/wizard-response-type'
import { createOrUpdateDiscovery, getDiscoveryThunk, updateDiscovery } from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import GeneralInformation from './GeneralInformation/GeneralInformation'
import TargetAudience from './TargetAudience/TargetAudience'
import UploadFilesDropDown from './UploadFilesDropDown/UploadFilesDropDown'
import styles from './WizardDiscovery.module.scss'

interface IWizardDiscoveryProps {
  messagingId?: string
  nextStep: () => void
  onProgressUpdate: (percent: number) => void
}

const WizardDiscovery: FC<IWizardDiscoveryProps> = ({ messagingId, nextStep, onProgressUpdate }) => {
  const dispatch = useAppDispatch()
  const discovery = useAppSelector((state) => state.wizard.data?.discovery)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])
  const [isAddMoreInfo, setIsAddMoreInfo] = useState<boolean>(false)
  const [isAddMoreLinksAndFiles, setIsAddMoreLinksAndFiles] = useState<boolean>(false)
  const totalInputs = 6

  useEffect(() => {
    if (messagingId) {
      dispatch(getDiscoveryThunk({ messagingId }))
    }
  }, [dispatch, messagingId, discovery?.id])

  const saveDiscoveryData = async () => {
    if (messagingId && discovery) {
      const { metadata, discoveryText, createdAt, updatedAt, ...filteredDiscovery } = discovery

      await dispatch(
        createOrUpdateDiscovery({
          discoveryData: filteredDiscovery,
        }),
      )
    }
  }

  const handleNext = (currentKey: string, field: EDiscoveryDataField, value: IWizardResponseType[]) => {
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const updatedField: IWizardResponseType[] = value

    dispatch(
      updateDiscovery({
        discoveryData: {
          ...discovery?.discoveryData,
          [field]: updatedField,
        },
      }),
    )

    setCompletedKeys((prev) => {
      const updated = [...new Set([...prev, currentKey])]
      onProgressUpdate((updated.length / totalInputs) * 100)
      return updated
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }
  const items: AccordionItem[] = [
    {
      key: '1',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.generalInformation}
          onNext={(data) => handleNext('1', EDiscoveryDataField.GENERAL_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
        />
      ),
    },
    {
      key: '2',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <ClientReferenceIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.clientReferences}
          onNext={(data) => handleNext('2', EDiscoveryDataField.CLIENT_REFERENCES, data)}
          title={strings.WIZARD.DISCOVERY.CLIENT_REFERENCE.TITLE}
          referenceId={messagingId}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CLIENT_REFERENCE}
        />
      ),
    },
    {
      key: '3',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <TargetAudienceIcon style={{ marginRight: 8 }} />,
      children: (
        <TargetAudience
          value={discovery?.discoveryData.targetAudience}
          onNext={(data) => handleNext('3', EDiscoveryDataField.TARGET_AUDIENCE, data)}
        />
      ),
    },
    {
      key: '4',
      label: (
        <>
          {strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.LABEL}
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </>
      ),
      icon: <DiscoverySummaryIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.discoverySummary}
          onNext={(data) => handleNext('4', EDiscoveryDataField.DISCOVERY_SUMMARY, data)}
          title={strings.WIZARD.DISCOVERY.DISCOVERY_SUMMARY.TITLE}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.DISCOVERY_SUMMARY}
        />
      ),
    },
    {
      key: '5',
      label: 'Customer case studies',
      icon: <CustomerCaseStudiesIcon style={{ marginRight: 8 }} />,

      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.customerCaseStudies}
          onNext={(data) => handleNext('5', EDiscoveryDataField.CUSTOMER_CASE_STUDIES, data)}
          title={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.CUSTOMER_CASE_STUDIES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.CUSTOMER_CASE_STUDIES}
        />
      ),
    },
    {
      key: '6',
      label: 'Proof',
      icon: <ProofIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.proof}
          onNext={(data) => handleNext('6', EDiscoveryDataField.PROOF, data)}
          title={strings.WIZARD.DISCOVERY.PROOF.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.PROOF.PLACE_HOLDER}
          isTextExplanation
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.PROOF}
        />
      ),
    },
  ]

  if (isAddMoreInfo || discovery?.discoveryData.moreInformation) {
    items.push({
      key: '7',
      label: strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.LABEL,
      icon: <AddMoreInfoIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={discovery?.discoveryData.moreInformation}
          onNext={(data) => handleNext('7', EDiscoveryDataField.MORE_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.ADD_MORE_INFORMATION.PLACE_HOLDER}
        />
      ),
    })
  }

  if (isAddMoreLinksAndFiles || discovery?.discoveryData.moreInformation) {
    items.push({
      key: '8',
      label: strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.LABEL,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={discovery?.discoveryData.moreInformation}
          onNext={(data) => handleNext('8', EDiscoveryDataField.MORE_INFORMATION, data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={discovery?.id}
          referenceType={EFileReferenceType.DISCOVERY}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
        />
      ),
    })
  }

  const requiredKeys = ['1', '2', '3', '4']
  const availableGenerate: boolean = requiredKeys.every((key) => completedKeys.includes(key))
  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.DISCOVERY.TITLES.TITLE}
      subTitle={strings.WIZARD.DISCOVERY.TITLES.SUBTITLE}
      availableGenerate={availableGenerate}
      nextStep={saveDiscoveryData}
      step={1}
      isAddMoreInfo
      addMoreInfo={() => setIsAddMoreInfo(true)}
      addMoreLinksAndFiles={() => setIsAddMoreLinksAndFiles(true)}
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardDiscovery
