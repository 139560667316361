import { Input, Tabs } from 'antd'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { updateGuideline } from '../../../store/slices/guideline.slice'
import { strings } from '../../../models/consts/strings'
import styles from './WritingStyles.module.scss'

const { TextArea } = Input

interface IWritingStylesProps {
  values: {
    grammar: string
    terminology: string
    grammaticalStyles: string
    acronyms: string
  }
}

const fieldMapping: Record<string, keyof IWritingStylesProps['values']> = {
  tab1: 'grammar',
  tab2: 'terminology',
  tab3: 'grammaticalStyles',
  tab4: 'acronyms',
}

const tabsData = [
  {
    key: 'tab1',
    label: strings.GUIDELINE.WRITING_STYLES.PUNCTUATION.TITLE,
    placeholder: strings.GUIDELINE.WRITING_STYLES.PUNCTUATION.PLACE_HOLDER,
  },
  {
    key: 'tab2',
    label: strings.GUIDELINE.WRITING_STYLES.SYNTAX.TITLE,
    placeholder: strings.GUIDELINE.WRITING_STYLES.SYNTAX.PLACE_HOLDER,
  },
  {
    key: 'tab3',
    label: strings.GUIDELINE.WRITING_STYLES.GRAMMATICAL_STYLES.TITLE,
    placeholder: strings.GUIDELINE.WRITING_STYLES.GRAMMATICAL_STYLES.PLACE_HOLDER,
  },
  {
    key: 'tab4',
    label: strings.GUIDELINE.WRITING_STYLES.FORMATTING_STYLES.TITLE,
    placeholder: strings.GUIDELINE.WRITING_STYLES.FORMATTING_STYLES.PLACE_HOLDER,
  },
]

const WritingStyles: FC<IWritingStylesProps> = ({ values }) => {
  const dispatch = useDispatch()

  const handleInputChange = (key: string, value: string) => {
    const fieldName = fieldMapping[key]
    if (fieldName) {
      dispatch(updateGuideline({ [fieldName]: value }))
    }
  }

  return (
    <div className={styles.linguisticPatternsContainer}>
      <h3 className={styles.title}>{strings.GUIDELINE.TITLES.WRITING_STYLES}</h3>
      <Tabs
        defaultActiveKey="tab1"
        items={tabsData.map((tab) => ({
          key: tab.key,
          label: tab.label,
          children: (
            <TextArea
              value={values[fieldMapping[tab.key]] || ''}
              onChange={(e) => handleInputChange(tab.key, e.target.value)}
              placeholder={tab.placeholder}
              autoSize={{ minRows: 3, maxRows: 10 }}
            />
          ),
        }))}
      />
    </div>
  )
}

export { WritingStyles }
