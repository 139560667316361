import { Upload, UploadFile, UploadProps, message } from 'antd'
import { FC, useEffect, useState } from 'react'

import { AttachFilesIcon } from '../../../../assets/icons'
import { strings } from '../../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../../models/enums/file-reference-type.enum'
import { IWizardResponseType } from '../../../../models/interfaces/wizard-response-type'
import { uploadFileService } from '../../../../services/files-upload-service'
import { GenericButton } from '../../../GenericButton/GenericButton'
import WizardInput from '../../WizardInput/WizardInput'
import styles from './UploadFilesDropDown.module.scss'

interface IUploadFilesDropDownProps {
  value?: IWizardResponseType[]
  onNext: (data: IWizardResponseType[]) => void
  title: string
  isLink?: boolean
  linkPlaceHolder?: string
  isTextExplanation?: boolean
  referenceId?: string
  referenceType: EFileReferenceType
  subReferenceType?: EFileSubReferenceType
  accept?: string
}

const UploadFilesDropDown: FC<IUploadFilesDropDownProps> = ({
  value,
  isTextExplanation,
  linkPlaceHolder,
  onNext,
  isLink,
  title,
  referenceId,
  referenceType,
  subReferenceType,
  accept,
}) => {
  const [linkInputs, setLinkInputs] = useState<string[]>([])
  const [textExplanation, setTextExplanation] = useState(false)
  const [wizardInput, setWizardInput] = useState<string>('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (!value) return
    const initialFileList = value
      .filter((item) => item.type === 'file')
      .map((item, index) => ({
        uid: `${index}`,
        name: item.value.split('/').pop() || `File ${index + 1}`,
        url: item.value,
      }))

    const initialLinkList = value.filter((item) => item.type === 'url').map((link) => link.value)
    const textExplanationInput = value.find((item) => item.type === 'text')?.value
    setTextExplanation(!!textExplanationInput)
    setWizardInput(textExplanationInput ?? '')
    setLinkInputs(initialLinkList)
    setFileList(initialFileList)
  }, [value])

  const props: UploadProps = {
    accept,
    fileList,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        if (!referenceId) {
          throw new Error('referenceId is required but was not provided')
        }

        const fileName = (file as File).name
        const fileType = (file as File).type

        const formData = new FormData()
        formData.append('file', file)
        formData.append('fileName', fileName)
        formData.append('fileType', fileType)
        formData.append('referenceId', referenceId)
        formData.append('referenceType', referenceType)
        if (subReferenceType) {
          formData.append('subReferenceType', subReferenceType)
        }
        const response = await uploadFileService(formData)

        onSuccess && onSuccess(response)
      } catch (error) {
        if (error instanceof Error) {
          onError && onError(error)
        } else {
          console.error('Unexpected error:', error)
        }
      }
    },
    onChange(info) {
      const { fileList: updatedFileList } = info
      const cleanedFileList = updatedFileList.map((file) => ({
        type: 'file',
        uid: file.uid,
        name: file.name,
        url: file.url || file.response?.data?.fileUrl,
        status: file.status,
      }))

      setFileList(cleanedFileList)
      if (info.file.status === 'done') {
        message.success(`${info.file.name} uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} upload failed`)
      }
    },
    onRemove(file) {
      setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
    },
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
      showDownloadIcon: true,
    },
  }

  const addInputField = () => {
    if (linkInputs.some((link) => !link.trim())) {
      message.error(strings.WIZARD.DISCOVERY.ERRORS.LINK_ERROR)
      return
    }

    setLinkInputs([...linkInputs, ''])
  }

  const removeInputField = (index: number) => {
    const updatedLinks = [...linkInputs]
    updatedLinks.splice(index, 1)
    setLinkInputs(updatedLinks)
  }

  const handleInputChange = (index: number, value: string) => {
    const updatedLinks = [...linkInputs]
    updatedLinks[index] = value
    setLinkInputs(updatedLinks)
  }

  const handleTextExplanationInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setWizardInput(value)
  }

  const handleNextClick = () => {
    const linksObjects: IWizardResponseType[] = linkInputs.length
      ? linkInputs.map((link) => ({
          type: 'url',
          value: link,
        }))
      : []

    const fileObjects: IWizardResponseType[] = fileList.map((file) => ({
      type: 'file',
      value: file.url || '',
    }))

    const explanationInputObject: IWizardResponseType[] = wizardInput
      ? [
          {
            type: 'text',
            value: wizardInput,
          },
        ]
      : []
    const combinedItems = [...linksObjects, ...fileObjects, ...explanationInputObject]

    onNext(combinedItems)
  }

  const isUploaded = fileList.length > 0 || linkInputs.length

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.title}>{title}</p>
        <div className={styles.uploadButtonsContainer}>
          <div className={styles.uploadButtons}>
            {isLink && (
              <button onClick={addInputField} className={styles.buttonContainer}>
                + {strings.GENERAL_LABELS.ADD_LINK}
              </button>
            )}
          </div>
          {isLink && (
            <div className={styles.linkInputs}>
              {linkInputs.map((link, index) => (
                <div className={styles.linkContainer} key={index}>
                  <WizardInput
                    value={link}
                    placeholder={linkPlaceHolder}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                  <button
                    onClick={() => removeInputField(index)}
                    className={`${styles.buttonContainer} ${styles.removeLink}`}
                  >
                    - {strings.GENERAL_LABELS.REMOVE_LINK}
                  </button>
                </div>
              ))}
            </div>
          )}
          <Upload className={styles.attachFilesButtonContainer} {...props}>
            <AttachFilesIcon />
            <span className={styles.attachFilesButton}>{strings.GENERAL_LABELS.ATTACH_FILE}</span>
          </Upload>
        </div>

        {isTextExplanation && (
          <div className={styles.textExplanationContainer}>
            <p className={styles.textExplanationText}>{strings.WIZARD.DISCOVERY.PROOF.TEXT_EXPLANATION}</p>
            {!textExplanation ? (
              <button onClick={() => setTextExplanation(true)} className={styles.textExplanationButton}>
                + {strings.GENERAL_LABELS.ADD_TEXT_EXPLANATION}
              </button>
            ) : (
              <div className={styles.textExplanationInput}>
                <WizardInput
                  value={wizardInput}
                  placeholder={strings.WIZARD.DISCOVERY.PROOF.TEXT_EXPLANATION_PLACE_HOLDER}
                  onChange={handleTextExplanationInputChange}
                />
                <button onClick={() => setTextExplanation(false)} className={styles.removeTextExplanation}>
                  - {strings.WIZARD.DISCOVERY.PROOF.REMOVE_TEXT_EXPLANATION}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.nextButton}>
        <GenericButton isDisabled={!isUploaded} title={strings.GENERAL_LABELS.NEXT} onClick={handleNextClick} />
      </div>
    </div>
  )
}

export default UploadFilesDropDown
