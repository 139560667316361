import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PlusIcon } from '../../assets/icons'
import ClientModal from '../../components/ClientModal/ClientModal'
import { GenericButton } from '../../components/GenericButton/GenericButton'
import { ListView } from '../../components/ListView/ListView'
import { ListViewContainer } from '../../components/ListViewContainer/ListViewContainer'
import { Pagination } from '../../components/Pagination/Pagination'
import { SearchInput } from '../../components/SearchInput/SearchInput'
import { useDebounce } from '../../hooks/debounce.hook'
import { clientColumnKeyMap } from '../../models/consts/column-kep-map'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { strings } from '../../models/consts/strings'
import { EAppRoutes } from '../../models/enums/app-routes.enum'
import { IClient } from '../../models/interfaces/client.interface'
import { fetchClientsThunk } from '../../store/slices/clients.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './Clients.module.scss'
import { clientColumns } from './clients.util'
import { EComponentType } from '../../models/enums/componentType.enum'

const Clients: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userId = useAppSelector((state) => state.user.id)
  const { clients, isLoading, pagesCount } = useAppSelector((state) => state.clients)
  const [currentPage, setCurrentPage] = useState<number>(PAGINATION_CONFIG.DEFAULT_PAGE)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchClientsThunk({
          search: debouncedSearchValue,
          page: currentPage,
        }),
      )
    }
  }, [userId, currentPage, debouncedSearchValue, dispatch])

  const handlePageChange = (newPage: number) => {
    if (userId) {
      setCurrentPage(newPage)
      dispatch(fetchClientsThunk({ page: newPage }))
    }
  }

  const handleRowClick = (client: IClient) => {
    navigate(`${EAppRoutes.CLIENTS}/${client.id}`, { state: { client } })
  }

  const handleModalOk = () => {
    setOpenModal(false)
    dispatch(fetchClientsThunk({}))
  }

  const isEmptySearch: boolean = !!debouncedSearchValue && clients.length === 0
  const noData: boolean = !debouncedSearchValue && clients.length === 0
  return (
    <div className={styles.clientsContainer}>
      <h1 className={styles.title}>{strings.CLIENTS_PAGE.TITLES.MAIN}</h1>
      <div className={styles.topContainer}>
        <SearchInput width="25%" onSearch={(value) => setSearchValue(value)} />
        <GenericButton
          title={strings.CLIENTS_PAGE.TITLES.NEW_CLIENT}
          onClick={() => setOpenModal(true)}
          Icon={PlusIcon}
        />
      </div>
      <div className={styles.bottomContainer}>
        <ListViewContainer
          isButton={false}
          buttonTitle={strings.CLIENTS_PAGE.TITLES.NEW_CLIENT}
          onModalOk={() => dispatch(fetchClientsThunk({}))}
        >
          <ListView
            onRowClick={(client) => handleRowClick(client)}
            columns={clientColumns}
            data={clients}
            columnKeyMap={clientColumnKeyMap}
            noSearchResults={isEmptySearch}
            noData={noData}
            componentType={EComponentType.CLIENT}
            isLoading={isLoading}
            emptyMsg={strings.EMPTY_CASES.EMPTY_CLIENTS}
            buttonTitle={strings.CLIENTS_PAGE.TITLES.NEW_CLIENT}
            setModalOpen={() => setOpenModal(true)}
          />
        </ListViewContainer>
        <ClientModal isOpen={openModal} onOk={handleModalOk} onCancel={() => setOpenModal(false)} />
        {pagesCount > 1 && (
          <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={handlePageChange} />
        )}
      </div>
    </div>
  )
}
export { Clients }
