import { EStatusEnum } from '../models/enums/status.enum'

const getStatusClass = (status?: EStatusEnum): string => {
  switch (status) {
    case EStatusEnum.PENDING:
      return 'pending'
    case EStatusEnum.COMPLETED:
      return 'completed'
    case EStatusEnum.IN_PROGRESS:
      return 'inProgress'
    case EStatusEnum.DRAFTS:
      return 'drafts'
    case EStatusEnum.DISCOVERY:
      return 'discovery'
    case EStatusEnum.COMPETITIVE_ANALYSIS:
      return 'competitiveAnalysis'
    case EStatusEnum.MESSAGING_ENGINE:
      return 'messagingEngine'
    case EStatusEnum.INTERNAL:
      return 'internal'
    case EStatusEnum.EXTERNAL:
      return 'external'
    case EStatusEnum.CLIENT:
      return 'client'
    default:
      return ''
  }
}

const getStatusDisplayName = (status: EStatusEnum): string => {
  switch (status) {
    case EStatusEnum.PENDING:
      return 'Pending'
    case EStatusEnum.COMPLETED:
      return 'Completed'
    case EStatusEnum.IN_PROGRESS:
      return 'In Progress'
    case EStatusEnum.DRAFTS:
      return 'Draft'
    case EStatusEnum.DISCOVERY:
      return 'Discovery'
    case EStatusEnum.COMPETITIVE_ANALYSIS:
      return 'Competitive Analysis'
    case EStatusEnum.MESSAGING_ENGINE:
      return 'Messaging Engine'
    case EStatusEnum.INTERNAL:
      return 'Internal'
    case EStatusEnum.EXTERNAL:
      return 'External'
    case EStatusEnum.CLIENT:
      return 'Client'
    default:
      return 'Unknown'
  }
}

export { getStatusClass, getStatusDisplayName }
