import { EFileReferenceType } from '../models/enums/file-reference-type.enum'

const baseServerUrl = process.env.REACT_APP_SERVER_API_URL

export const apiEndpoints = {
  PROJECTS: {
    GET_PROJECTS: `${baseServerUrl}/projects`,
    CREATE_PROJECT: `${baseServerUrl}/projects/create`,
    GET_PROJECT: (projectId: string) => `${baseServerUrl}/projects/${projectId}`,
    DELETE_PROJECT: `${baseServerUrl}/projects/`,
    UPDATE_PROJECT: (projectId: string) => `${baseServerUrl}/projects/${projectId}`,
  },
  NEWS: {
    GET_NEWS: `${baseServerUrl}/news`,
  },
  USERS: {
    GET_ALL_USERS: `${baseServerUrl}/users`,
    GET_USER_DATA: `${baseServerUrl}/users/me`,
    LOGIN: `${baseServerUrl}/users/login`,
  },
  BUSINESS_UNITS: {
    GET_BUSINESS_UNIT_ById: (businessUnitId: string) => `${baseServerUrl}/business-units/${businessUnitId}`,
    GET_BUSINESS_UNITS: `${baseServerUrl}/business-units`,
    CREATE_BUSINESS_UNIT: `${baseServerUrl}/business-units/create`,
  },
  CLIENTS: {
    GET_CLIENT_ById: (clientId: string) => `${baseServerUrl}/clients/${clientId}`,
    GET_CLIENTS: `${baseServerUrl}/clients`,
    CREATE_CLIENT: `${baseServerUrl}/clients/create`,
  },
  INDUSTRIES: {
    GET_ALL_INDUSTRIES: `${baseServerUrl}/industries`,
  },
  GUIDELINES: {
    GET_GUIDELINE: `${baseServerUrl}/guidelines`,
    CREATE_GUIDELINE: `${baseServerUrl}/guidelines`,
  },
  LOGOS: {
    ADD_LOGO: `${baseServerUrl}/logos`,
    GET_LOGOS: (pathVariable: string) => `${baseServerUrl}/logos/guideline/${pathVariable}`,
    DELETE_LOGO: (logoId: string, storageFileName: string) =>
      `${baseServerUrl}/logos/${logoId}?storageFileName=${storageFileName}`,
  },
  FILES: {
    GET_FILES: (referenceType: EFileReferenceType, referenceId: string) =>
      `${baseServerUrl}/files?referenceType=${referenceType}&referenceId=${referenceId}`,
    CREATE_FILE: `${baseServerUrl}/files`,
    DELETE_FILE: `${baseServerUrl}/files`,
    UPDATE_FILE: `${baseServerUrl}/files`,
  },
  DISCOVERY: {
    CREATE_OR_UPDATE_DISCOVERY: `${baseServerUrl}/discoveries`,
    UPDATE_DISCOVERY_TEXT: `${baseServerUrl}/discoveries`,
    GET_DISCOVERIES: (messagingId: string) => `${baseServerUrl}/discoveries?messagingId=${messagingId}`,
  },
  COMPETITIVE_ANALYSIS: {
    CREATE_OR_UPDATE_COMPETITIVE_ANALYSIS: `${baseServerUrl}/competitive-analysis`,
    UPDATE_COMPETITIVE_ANALYSIS_TEXT: `${baseServerUrl}/competitive-analysis`,
    GET_COMPETITIVE_ANALYSIS: (messagingId: string) =>
      `${baseServerUrl}/competitive-analysis?messagingId=${messagingId}`,
  },
  MESSAGING_ENGINE: {
    CREATE_OR_UPDATE_MESSAGING_ENGINE: `${baseServerUrl}/messaging-engine`,
    GET_MESSAGING_ENGINE: (messagingId: string) => `${baseServerUrl}/messaging-engine?messagingId=${messagingId}`,
  },
  AI_ENDPOINTS: {
    TEXT_EDITING: `${baseServerUrl}/ai/text-editing`,
    ADD_PARAGRAPH: `${baseServerUrl}/ai/add-paragraph`,
  },
  UPLOAD: {
    UPLOAD_TO_STORAGE: `${baseServerUrl}/storage/upload`,
  },
}
