import { ClientsIcon, CommandCenterIcon, LogoutIcon } from '../../assets/icons/index'
import { EAppRoutes } from '../../models/enums/app-routes.enum'

export const sidebarRoutes = [
  {
    id: 1,
    path: EAppRoutes.COMMAND_CENTER,
    icon: CommandCenterIcon,
    altText: 'Command Center',
    class: 'commandCenter',
    isStatic: false,
    isButton: false,
  },
  {
    id: 2,
    path: EAppRoutes.CLIENTS,
    icon: ClientsIcon,
    altText: 'Clients',
    class: 'clients',
    isStatic: false,
    isButton: false,
  },
  {
    id: 3,
    path: '',
    isUserOption: true,
    class: 'avatar',
    isStatic: false,
    isButton: false,
  },
  {
    id: 4,
    path: '',
    icon: LogoutIcon,
    altText: 'Logout',
    class: 'logout',
    isButton: true,
    isStatic: false,
  },
]
