import { FC, useState } from 'react'
import { Modal } from 'antd'

import { BrandIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import { IFile } from '../../../../../models/interfaces/file.interface'
import { WizardTab } from '../../WizardTab/WizardTab'
import { WizardGenericModal } from '../WizardGenericModal'
import styles from './BrandModal.module.scss'

interface IBrandModalProps {
  logos?: string[]
  font?: IFile
  colors?: string[]
  onClose: () => void
}
const brandIcon = { title: strings.WIZARD.HEADER.TABS.BRAND, Icon: BrandIcon }

const BrandModal: FC<IBrandModalProps> = ({ logos, font, colors, onClose }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [currentPreviewUrl, setCurrentPreviewUrl] = useState('')

  const handlePreview = (url: string) => {
    setCurrentPreviewUrl(url)
    setPreviewVisible(true)
  }

  return (
    <WizardGenericModal onClose={onClose} tab={<WizardTab isBold Icon={brandIcon.Icon} title={brandIcon.title} />}>
      <div className={styles.brandModalWrapper}>
        {/* Logos Section */}
        <div className={styles.logosContainer}>
          <h1 className={styles.title}>{strings.GUIDELINE.TITLES.LOGOS}</h1>
          <div className={styles.borderContainer}>
            <div className={styles.logosContent}>
              {logos?.map((logo, index) => (
                <div key={index} className={styles.logo} onClick={() => handlePreview(logo)}>
                  <img src={logo} alt={`Logo ${index + 1}`} className={styles.logoThumb} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {previewVisible && (
          <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
            <img alt="logo preview" style={{ width: '100%' }} src={currentPreviewUrl} />
          </Modal>
        )}

        {/* Font Section */}
        <div className={styles.fontsContainer}>
          <h1 className={styles.title}>{strings.GUIDELINE.TITLES.FONT}</h1>
          <div className={styles.borderContainer}>
            <div className={styles.fontContainer}>
              <p style={{ fontFamily: font?.fileName }} className={styles.fontPreview}>
                Aa
              </p>
              <div style={{ fontFamily: font?.fileName }} className={styles.fontSample}>
                <p className={styles.fontName} style={{ fontFamily: font?.fileName }}>
                  {font?.fileName}
                </p>
                ABCDEFGHIJKLMNOPQRSTUVWXYZ
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890
              </div>
            </div>
          </div>
        </div>

        {/* Colors Section */}
        <h1 className={styles.title}>{strings.GUIDELINE.TITLES.COLOR_PALETTE}</h1>
        <div className={styles.colorsPaletteContainer}>
          <div className={styles.borderContainer}>
            <div className={styles.colorsContent}>
              {colors?.map((color) => (
                <div key={color} className={styles.colorBox} style={{ backgroundColor: color }} title={color} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </WizardGenericModal>
  )
}

export { BrandModal }
