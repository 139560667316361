import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import businessUnitProjectsReducer from './slices/business-unit.slice'
import clientsReducer from './slices/clients.slice'
import guidelineReducer from './slices/guideline.slice'
import newsReducer from './slices/news.slice'
import businessUnitsReducer from './slices/specific-client.slice'
import projectsReducer from './slices/user-projects.slice'
import userSlice from './slices/user.slice'
import wizardSlice from './slices/wizard.slice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    projects: projectsReducer,
    news: newsReducer,
    clients: clientsReducer,
    businessUnits: businessUnitsReducer,
    guidelineSlice: guidelineReducer,
    wizard: wizardSlice,
    businessUnit: businessUnitProjectsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
