import { apiEndpoints } from '../constants/api-endpoints'
import { EFileReferenceType } from '../models/enums/file-reference-type.enum'
import { IFile } from '../models/interfaces/file.interface'
import { apiRequest } from '../utils/api-utils/api'

const uploadFileService = async (formData: FormData) => {
  return await apiRequest('POST', apiEndpoints.UPLOAD.UPLOAD_TO_STORAGE, formData)
}

const SaveFileToDBService = async (file: IFile) => {
  return await apiRequest('POST', apiEndpoints.FILES.CREATE_FILE, file)
}

const getFilesService = async (referenceType: EFileReferenceType, referenceId: string): Promise<IFile[]> => {
  const response = await apiRequest('GET', apiEndpoints.FILES.GET_FILES(referenceType, referenceId))
  const data = response.data
  return data
}

export { SaveFileToDBService, getFilesService, uploadFileService }
