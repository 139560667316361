import { MenuProps, Tabs, message } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { EditIcon2, PlusIcon, TrashRedIcon } from '../../assets/icons'
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal'
import { GenericButton } from '../../components/GenericButton/GenericButton'
import { Guideline } from '../../components/Guideline/Guideline'
import { ListView } from '../../components/ListView/ListView'
import { ListViewContainer } from '../../components/ListViewContainer/ListViewContainer'
import { Pagination } from '../../components/Pagination/Pagination'
import { ProjectModal } from '../../components/ProjectModal/ProjectModal'
import { SearchInput } from '../../components/SearchInput/SearchInput'
import { useDebounce } from '../../hooks/debounce.hook'
import { businessUnitColumnKeyMap } from '../../models/consts/column-kep-map'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { strings } from '../../models/consts/strings'
import { EComponentType } from '../../models/enums/componentType.enum'
import { EGuidelineType } from '../../models/enums/guideline-type.enum'
import { IProject } from '../../models/interfaces/project.interface'
import { fetchClientsService } from '../../services/command-center-service'
import {
  deleteProjectThunk,
  fetchBusinessUnitByIdThunk,
  fetchBusinessUnitProjectsThunk,
} from '../../store/slices/business-unit.slice'
import { RootState, useAppDispatch, useAppSelector } from '../../store/store'
import styles from './BusinessUnit.module.scss'
import { businessUnitColumns } from './business-unit-utils'

const { TabPane } = Tabs

const BusinessUnit: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { businessUnit } = useSelector((state: RootState) => state.businessUnit)
  const { businessUnitId } = useParams<{ businessUnitId: string }>()
  const { businessUnitProjects, loading, pagesCount } = useAppSelector((state) => state.businessUnit)
  const initialTabKey = location.pathname.includes('/guideline') ? '2' : '1'
  const passedBusinessUnit = location.state?.businessUnit || businessUnit

  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(PAGINATION_CONFIG.DEFAULT_PAGE)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [deleteProjectModal, setDeleteProjectModal] = useState(false)
  const [selectedProjectId, setSelectedProjectId] = useState<string>('')
  const [activeTab, setActiveTab] = useState(initialTabKey)
  const [modalClient, setModalClient] = useState<string>('')
  const [modalBusinessUnit, setModalBusinessUnit] = useState<string>('')
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const debouncedSearchValue = useDebounce(searchValue, 500)

  useEffect(() => {
    if (businessUnitId) {
      dispatch(
        fetchBusinessUnitProjectsThunk({
          businessUnitId,
          page: currentPage,
          search: debouncedSearchValue.trim(),
        }),
      )
    }
  }, [businessUnitId, currentPage, debouncedSearchValue, dispatch])

  useEffect(() => {
    if (!businessUnitId) return

    const fetchBUAndClients = async () => {
      try {
        const businessUnit = await dispatch(fetchBusinessUnitByIdThunk({ businessUnitId })).unwrap()
        setModalBusinessUnit(businessUnit.name)

        const clients = await fetchClientsService(
          PAGINATION_CONFIG.DEFAULT_PAGE,
          PAGINATION_CONFIG.DEFAULT_LIMIT,
          undefined,
          [{ field: 'id', value: businessUnit.clientId }],
        )
        const clientName = clients.data.clients[0].name
        setModalClient(clientName)
      } catch (err) {
        console.error(err)
      }
    }
    fetchBUAndClients()
  }, [businessUnitId, dispatch])

  const handlePageChange = (newPage: number) => {
    if (businessUnitId) {
      setCurrentPage(newPage)
      dispatch(
        fetchBusinessUnitProjectsThunk({
          businessUnitId,
          page: newPage,
        }),
      )
    }
  }

  const handleModalOk = () => {
    if (businessUnitId) {
      setOpenModal(false)
      dispatch(fetchBusinessUnitProjectsThunk({ businessUnitId }))
    }
  }

  const handleDeleteProjectModalOk = async () => {
    setConfirmLoading(true)
    if (businessUnitId && selectedProjectId) {
      try {
        await dispatch(deleteProjectThunk({ projectId: selectedProjectId })).unwrap()
        setDeleteProjectModal(false)
        setSelectedProjectId('')
        message.success('Project deleted successfully')
      } catch (error) {
        console.error('Error deleting project:', error)
        message.error('Error: Something went wrong while deleting the project')
      } finally {
        setConfirmLoading(false)
      }
    }
  }

  const dropDownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.dropdownItem}>
          <EditIcon2 className={styles.dropdownIcon} />
          {strings.GENERAL_LABELS.EDIT}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={styles.dropdownItem}>
          <TrashRedIcon className={styles.dropdownIcon} />
          {strings.GENERAL_LABELS.DELETE}
        </div>
      ),
    },
  ]

  const handleMenuClick = (row: IProject, info: MenuInfo) => {
    switch (info.key) {
      case '1':
        /* TODO: implement edit button for project */
        break
      case '2':
        setSelectedProjectId(row.id)
        setDeleteProjectModal(true)
        break
      default:
        break
    }
  }

  const isEmptySearch: boolean = !!debouncedSearchValue && businessUnitProjects.length === 0
  const noData: boolean = !debouncedSearchValue && businessUnitProjects.length === 0

  return (
    <div className={styles.businessUnitContainer}>
      <h1 className={styles.title}>{passedBusinessUnit?.name}</h1>
      <Tabs
        className={styles.tabsContainer}
        activeKey={activeTab}
        onChange={(key) => {
          setActiveTab(key)
          if (businessUnitId) {
            if (key === '2') {
              navigate(`/business-unit/${businessUnitId}/guideline`)
            } else {
              navigate(`/business-unit/${businessUnitId}`)
            }
          }
        }}
      >
        <TabPane className={styles.tabsContainer} tab={strings.BUSINESS_UNIT_PAGE.TABS.TAB1} key="1">
          <div className={styles.wrapper}>
            <div className={styles.topContainer}>
              <SearchInput width="25%" onSearch={(value) => setSearchValue(value)} />
              <GenericButton
                title={strings.COMMAND_CENTER.TITLES.NEW_PROJECT}
                onClick={() => setOpenModal(true)}
                Icon={PlusIcon}
              />
            </div>
            <div className={styles.bottomContainer}>
              <ListViewContainer
                isButton={false}
                buttonTitle={strings.COMMAND_CENTER.TITLES.NEW_PROJECT}
                componentType={EComponentType.CLIENT}
              >
                <ListView
                  onRowClick={() => {}}
                  columns={businessUnitColumns}
                  data={businessUnitProjects}
                  columnKeyMap={businessUnitColumnKeyMap}
                  noSearchResults={isEmptySearch}
                  noData={noData}
                  isLoading={loading}
                  emptyMsg={strings.EMPTY_CASES.EMPTY_BUSINESS_UNITS_PROJECTS}
                  setModalOpen={() => setOpenModal(true)}
                  buttonTitle={strings.COMMAND_CENTER.TITLES.NEW_PROJECT}
                  menuButton
                  dropDownItems={dropDownItems}
                  onMenuClick={handleMenuClick}
                />
              </ListViewContainer>
            </div>
            {pagesCount > 1 && (
              <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={handlePageChange} />
            )}
          </div>
        </TabPane>
        <TabPane tab={strings.BUSINESS_UNIT_PAGE.TABS.TAB2} key="2">
          <Guideline referenceType={EGuidelineType.BUSINESSUNIT} referenceId={businessUnitId} />
        </TabPane>
      </Tabs>
      <ProjectModal
        defaultClientName={modalClient}
        defaultBusinessUnitName={modalBusinessUnit}
        defaultBusinessUnitId={businessUnitId}
        areFieldsDisabled={true}
        isOpen={openModal}
        onOk={handleModalOk}
        onCancel={() => setOpenModal(false)}
      />
      <ConfirmModal
        isOpen={deleteProjectModal}
        onOk={handleDeleteProjectModalOk}
        onCancel={() => setDeleteProjectModal(false)}
        isDangerButton
        primaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE1}
        secondaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE2}
        confirmButtonTItle={strings.GENERAL_LABELS.DELETE_NOW}
        isLoading={confirmLoading}
      />
    </div>
  )
}
export default BusinessUnit
