export function styleText(text: string): string {
  function getOrdinalCompetitor(n: number): string {
    const ordinals = [
      '<h3><strong>First competitor:</strong></h3>',
      '<h3><strong>Second competitor:</strong></h3>',
      '<h3><strong>Third competitor:</strong></h3>',
      '<h3><strong>Fourth competitor:</strong></h3>',
      '<h3><strong>Fifth competitor:</strong></h3>',
    ]
    return ordinals[n - 1] || `${n}th competitor`
  }

  // Start with ordinal counter
  let ordinal = 1

  // Prepend the first ordinal heading to the text
  let result = `${getOrdinalCompetitor(ordinal++)}<p>${text}`

  // Replace each <hr> with a closing paragraph, <hr> tag, new ordinal heading, and opening paragraph
  result = result.replace(/<hr\s*\/?>/gi, () => {
    return `</p><br></p><hr><p>${getOrdinalCompetitor(ordinal++)}<p>`
  })

  return result
}
