import { FunctionComponent } from 'react'

import { INews } from '../../../models/interfaces/news.interface'
import { formatDate } from '../../../utils/format-date.util'
import styles from './NewsListItem.module.scss'

export interface INewsProps {
  news: INews[]
}

const NewsListItem: FunctionComponent<INewsProps> = ({ news }) => {
  const handleRedirect = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className={styles.newsContainer}>
      {news.map((item) => (
        <button key={item.title} type="button" className={styles.redirect} onClick={() => handleRedirect(item.url)}>
          <div className={styles.newsItemBackground}>
            <div className={styles.newsItem}>
              <div className={styles.newsTextContainer}>
                <h3 className={styles.itemName}>{item.name}</h3>
                <p className={styles.itemContent}>{item.title}</p>
              </div>
              <div className={styles.sourceDateContainer}>
                <p className={styles.itemSource}>{item.source.name}</p>
                <p className={styles.itemDate}>{formatDate(item.publishedAt)}</p>
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  )
}

export { NewsListItem }
