import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../../config/auth-config'

const msalInstance = new PublicClientApplication(msalConfig)

const ensureMsalInitialized = async () => {
  const response = await msalInstance.handleRedirectPromise()
  if (response && response.account) {
    msalInstance.setActiveAccount(response.account)
  }
  const user = msalInstance.getActiveAccount()
  if (!user) {
    throw new Error('No active account found. User may need to sign in.')
  }
}

export { ensureMsalInitialized, msalInstance }
