import { apiEndpoints } from '../constants/api-endpoints'
import { EGuidelineType } from '../models/enums/guideline-type.enum'
import { ICompetitiveAnalysis } from '../models/interfaces/competitor-analysis.interface'
import { IDiscovery } from '../models/interfaces/discovery.interface'
import { IGuideline } from '../models/interfaces/guidelines.interface'
import { MessagingEnginePayload } from '../models/interfaces/requests/messaging-engine-payload.interface'
import { IUpdateProjectPayload } from '../models/interfaces/requests/update-project.interface'
import { IMessagingEngineResponse } from '../models/interfaces/responses/messaging-engine-response.interface'
import { apiRequest } from '../utils/api-utils/api'

const fetchProjectService = async (projectId: string) => {
  const response = await apiRequest('GET', apiEndpoints.PROJECTS.GET_PROJECT(projectId))
  const project = response.data
  return project
}

const getBusinessUnitGuidelineService = async (referenceId: string): Promise<IGuideline> => {
  const queryParams = `?referenceType=${EGuidelineType.BUSINESSUNIT}&referenceId=${referenceId}`
  const response = await apiRequest('GET', `${apiEndpoints.GUIDELINES.GET_GUIDELINE}${queryParams}`)
  return response.data
}

const createOrUpdateGuidelineService = async (referenceType: EGuidelineType, guidelineData: Partial<IGuideline>) => {
  return await apiRequest('POST', apiEndpoints.GUIDELINES.CREATE_GUIDELINE, {
    ...guidelineData,
    referenceType,
  })
}

const createOrUpdateDiscoveryService = async (discoveryData: Partial<IDiscovery>) => {
  return await apiRequest('POST', apiEndpoints.DISCOVERY.CREATE_OR_UPDATE_DISCOVERY, discoveryData)
}

const getDiscoveryService = async (messagingId: string): Promise<IDiscovery> => {
  const response = await apiRequest('GET', apiEndpoints.DISCOVERY.GET_DISCOVERIES(messagingId))
  const [discovery] = response.data
  return discovery
}

const getCompetitiveAnalysisService = async (messagingId: string) => {
  const response = await apiRequest('GET', apiEndpoints.COMPETITIVE_ANALYSIS.GET_COMPETITIVE_ANALYSIS(messagingId))
  const [competitiveAnalysis] = response.data
  return competitiveAnalysis
}

const createOrUpdateCompetitiveAnalysisService = async (competitiveAnalysisData: Partial<ICompetitiveAnalysis>) => {
  return await apiRequest(
    'POST',
    apiEndpoints.COMPETITIVE_ANALYSIS.CREATE_OR_UPDATE_COMPETITIVE_ANALYSIS,
    competitiveAnalysisData,
  )
}

const getMessagingEngineService = async (messagingId: string) => {
  const response = await apiRequest('GET', apiEndpoints.MESSAGING_ENGINE.GET_MESSAGING_ENGINE(messagingId))
  const [messagingEngine] = response.messagingEngineData
  return messagingEngine
}

const createOrUpdateMessagingEngineService = async (
  messagingEngineData: MessagingEnginePayload,
): Promise<IMessagingEngineResponse> => {
  return await apiRequest('POST', apiEndpoints.MESSAGING_ENGINE.CREATE_OR_UPDATE_MESSAGING_ENGINE, messagingEngineData)
}

const updateProjectService = async (projectId: string, payload: IUpdateProjectPayload) => {
  const response = await apiRequest('PATCH', apiEndpoints.PROJECTS.UPDATE_PROJECT(projectId), payload)
  const project = response.data
  return project
}

const updateDiscoveryTextService = async (discoveryId: string, discoveryText: string) => {
  const response = await apiRequest('PATCH', apiEndpoints.DISCOVERY.UPDATE_DISCOVERY_TEXT, {
    discoveryId,
    discoveryText,
  })
  return response
}

const updateCompetitiveAnalysisTextTextService = async (competitiveAnalysisId: string, index: number, text: string) => {
  const response = await apiRequest('PATCH', apiEndpoints.COMPETITIVE_ANALYSIS.UPDATE_COMPETITIVE_ANALYSIS_TEXT, {
    competitiveAnalysisId,
    index,
    text,
  })
  return response
}

export {
  createOrUpdateCompetitiveAnalysisService,
  createOrUpdateDiscoveryService,
  createOrUpdateGuidelineService,
  createOrUpdateMessagingEngineService,
  fetchProjectService,
  getBusinessUnitGuidelineService,
  getCompetitiveAnalysisService,
  getDiscoveryService,
  getMessagingEngineService,
  updateCompetitiveAnalysisTextTextService,
  updateDiscoveryTextService,
  updateProjectService,
}
