import { Dropdown, MenuProps } from 'antd'
import { FC, ReactNode } from 'react'

import { AddMoreFilesIcon, AddMoreInfoIcon, GenerateIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { GenericButton } from '../../GenericButton/GenericButton'
import styles from './WizardStepGenericComponent.module.scss'
import classNames from 'classnames'

interface WizardStepGenericComponentProps {
  title: string
  subTitle: string
  children: ReactNode
  availableGenerate: boolean
  nextStep: (index: number) => void
  step: number
  isAddMoreInfo?: boolean
  addMoreLinksAndFiles?: () => void
  addMoreInfo?: () => void
  onlyGenerateButton?: boolean
}
const WizardStepGenericComponent: FC<WizardStepGenericComponentProps> = ({
  availableGenerate,
  children,
  title,
  subTitle,
  nextStep,
  step,
  isAddMoreInfo,
  addMoreLinksAndFiles,
  addMoreInfo,
  onlyGenerateButton,
}) => {
  const dropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div onClick={addMoreInfo} className={styles.dropdownItem}>
          <AddMoreInfoIcon className={styles.dropdownIcon} />
          {strings.WIZARD.ADDITIONAL_FILES.ADD_MORE_INFO}
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={addMoreLinksAndFiles} className={styles.dropdownItem}>
          <AddMoreFilesIcon className={styles.dropdownIcon} />
          {strings.WIZARD.ADDITIONAL_FILES.ATTACH_LINKS_AND_FILES}
        </div>
      ),
    },
  ]
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subTitle}>{subTitle}</h2>
      </div>
      <hr className={styles.hrBorder} />
      <div className={styles.midContainer}>{children}</div>
      <hr className={styles.hrBorder} />
      <div className={classNames(styles.bottomContainer, { [styles.alignSelfEnd]: onlyGenerateButton })}>
        {isAddMoreInfo && (
          <Dropdown
            className={styles.menuDropDown}
            menu={{ items: dropdownItems }}
            trigger={['click']}
            placement="topRight"
          >
            <button className={styles.addButton}> + {strings.GENERAL_LABELS.ADD_MORE}</button>
          </Dropdown>
        )}
        <GenericButton
          isDisabled={!availableGenerate}
          title={strings.GENERAL_LABELS.GENERATE}
          Icon={GenerateIcon}
          onClick={() => nextStep(step)}
        />
      </div>
    </div>
  )
}

export default WizardStepGenericComponent
