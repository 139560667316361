import { FC, useState } from 'react'

import { SmallCloseIcon, TrashIcon } from '../../../../assets/icons'
import { strings } from '../../../../models/consts/strings'
import { ICompetitive } from '../../../../models/interfaces/competitive.interface'
import { CustomModal } from '../../../CustomModal/CustomModal'
import { GenericButton } from '../../../GenericButton/GenericButton'
import WizardInput from '../../WizardInput/WizardInput'
import styles from './WizardCompetitor.module.scss'

interface WizardCompetitorProps {
  onNext: (data: ICompetitive, key: string) => void
  onRemove: () => void
  value?: ICompetitive
  id: string
}

const WizardCompetitor: FC<WizardCompetitorProps> = ({ id, onNext, onRemove, value }) => {
  const [formData, setFormData] = useState<ICompetitive>({
    companyName: value?.companyName || '',
    category: value?.category || '',
    productName: value?.productName || '',
    url: value?.url || '',
    keywords: value?.keywords || [],
  })
  const [newKeyWord, setNewKeyWord] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSaveKeyWord = () => {
    if (newKeyWord && newKeyWord.trim()) {
      setFormData((prevData) => ({
        ...prevData,
        keywords: [...prevData.keywords, newKeyWord.trim()],
      }))
      setNewKeyWord(null)
    }
  }

  const handleDeleteKeyWord = (index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((_, i) => i !== index),
    }))
  }

  const handleNext = () => {
    if (allInputsFilled) {
      onNext(formData, id)
    }
  }

  const allInputsFilled =
    formData.companyName.trim() && formData.category.trim() && formData.productName.trim() && formData.url.trim()

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{strings.WIZARD.COMPETITIVE_ANALYSIS.COMPETITIVE_COMPONENT_TITLE}</h1>
      <div className={styles.inputsContainer}>
        <div className={styles.topInputs}>
          <WizardInput
            name={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.COMPANY_NAME.KEY}
            value={formData.companyName}
            placeholder={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.COMPANY_NAME.PLACEHOLDER}
            onChange={handleChange}
          />
          <WizardInput
            name={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.CATEGORY.KEY}
            value={formData.category}
            placeholder={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.CATEGORY.PLACEHOLDER}
            onChange={handleChange}
          />
        </div>
        <div className={styles.bottomInputs}>
          <WizardInput
            name={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.PRODUCT__NAME.KEY}
            value={formData.productName}
            placeholder={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.PRODUCT__NAME.PLACEHOLDER}
            onChange={handleChange}
          />
          <WizardInput
            name={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.URL.KEY}
            value={formData.url}
            placeholder={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.URL.PLACEHOLDER}
            onChange={handleChange}
          />
        </div>
        <div className={styles.keywordsContainer}>
          <h2 className={styles.keywordsTitle}>{strings.GENERAL_LABELS.KEY_WORD}</h2>
          <div className={styles.keywordsList}>
            {formData.keywords.map((item, index) => (
              <div key={index} className={styles.keywordItem}>
                <div className={styles.alignItems}>
                  <span className={styles.keyword}>{item}</span>
                  <SmallCloseIcon className={styles.closeIcon} onClick={() => handleDeleteKeyWord(index)} />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.keywordInputs}>
            {newKeyWord !== null ? (
              <div className={styles.inputContainer}>
                <WizardInput
                  name={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.KEY_WORD.KEY}
                  value={newKeyWord}
                  placeholder={strings.WIZARD.COMPETITIVE_ANALYSIS.INPUTS.KEY_WORD.PLACEHOLDER}
                  onChange={(e) => setNewKeyWord(e.target.value)}
                />
                <div className={styles.addButton} onClick={handleSaveKeyWord}>
                  <span>+</span>
                  <span>{strings.GENERAL_LABELS.ADD}</span>
                </div>
              </div>
            ) : (
              <button onClick={() => setNewKeyWord('')} className={styles.addKeyWordButton}>
                {`+ ${strings.WIZARD.COMPETITIVE_ANALYSIS.ADD_KEY_WORD}`}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <button onClick={() => setModalOpen(true)} className={styles.removeButton} type="button">
          <TrashIcon />
          {strings.GENERAL_LABELS.REMOVE}
        </button>
        <GenericButton isDisabled={!allInputsFilled} title={strings.GENERAL_LABELS.NEXT} onClick={handleNext} />
      </div>
      <CustomModal isOpen={modalOpen} onCancel={() => setModalOpen(false)}>
        <div className={styles.modalContainer}>
          <div className={styles.titlesContainer}>
            <h1 className={styles.modalTitle}>{strings.WIZARD.COMPETITIVE_ANALYSIS.DELETE_PANEL1}</h1>
            <h2 className={styles.modalSubTitle}>{strings.WIZARD.COMPETITIVE_ANALYSIS.DELETE_PANEL2}</h2>
          </div>
          <div className={styles.modalButtonsContainer}>
            <GenericButton isDangerButton title={strings.GENERAL_LABELS.DELETED_NOW} onClick={onRemove} />
            <GenericButton secondary title={strings.GENERAL_LABELS.CANCEL} onClick={() => setModalOpen(false)} />
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default WizardCompetitor
