import { Button, message } from 'antd'
import { FC, useEffect, useState } from 'react'

import { ModalGif } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import { IUser } from '../../models/interfaces/users.interface'
import { createBusinessUnitThunk } from '../../store/slices/specific-client.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { CustomModal } from '../CustomModal/CustomModal'
import { ModalError } from '../CustomModal/ModalError/ModalError'
import { ModalInput } from '../CustomModal/ModalInput/ModalInput'
import { handleFetchUsers } from '../ProjectModal/project-modal.utils'
import styles from './BusinessUnitModal.module.scss'

interface IBusinessUnitModalProps {
  clientId: string | undefined
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}

const BusinessUnitModal: FC<IBusinessUnitModalProps> = ({ isOpen, onOk, onCancel, clientId }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const [unitName, setUnitName] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      setSelectedUsers([user.id])
    }
  }, [user])

  useEffect(() => {
    handleFetchUsers(setUsers)
  }, [])

  const resetFields = () => {
    setUnitName('')
    setSelectedUsers([user.id])
  }

  const handleOk = async () => {
    if (!unitName || !selectedUsers || !user) {
      return
    }

    setIsLoading(true)

    try {
      if (!clientId) {
        return
      }
      await dispatch(
        createBusinessUnitThunk({
          name: unitName,
          clientId: clientId,
        }),
      ).unwrap()
      setTimeout(() => resetFields(), 1800)
      message.success(strings.SUCCESS_MSG.BUSINESS_UNIT)
    } catch (error) {
      setIsError(true)
      message.error({
        content: strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.ERRORS.BUSINESS_UNIT_ERROR,
        duration: 3,
      })
    } finally {
      setTimeout(() => {
        onOk()
        setIsLoading(false)
      }, 1800)
    }
  }

  const inputData = [
    {
      key: 1,
      label: strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.LABELS.UNIT_NAME,
      placeholder: strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.LABELS.UNIT_NAME,
      value: unitName,
      onChange: setUnitName,
    },
    {
      key: 2,
      label: strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.LABELS.TEAM_MEMBERS,
      placeholder: strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.LABELS.TEAM_MEMBERS,
      value: selectedUsers,
      onChange: (values: string[]) => {
        const updatedValues = [...new Set([...values.filter((v) => v !== 'you'), user.id])]
        setSelectedUsers(updatedValues)
      },
      options: users,
      isMultiple: true,
      connectedUser: user,
      IsMembersInput: true,
    },
  ]

  return (
    <CustomModal isOpen={isOpen} onCancel={onCancel} className="businessUnit">
      {isError ? (
        <ModalError
          firstMsg={strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.ERRORS.ERROR_MSG}
          secondMsg={strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.ERRORS.ERROR_MSG2}
          onClose={() => setIsError(false)}
        />
      ) : (
        <>
          <div>
            <h1 className={styles.businessUnitModalTitle}>{strings.SPECIFIC_CLIENT_PAGE.BUSINESSUNIT_MODAL.TITLE}</h1>
          </div>
          <div className={styles.modalFields}>
            {inputData.map((data) => (
              <ModalInput
                key={data.key}
                label={data.label}
                placeholder={data.placeholder}
                value={data.value}
                onChange={data.onChange}
                options={data.options}
                isMultiple={data.isMultiple}
                connectedUser={user}
                isMembersInput={data.IsMembersInput}
              />
            ))}
          </div>
          <div className={styles.businessUnitModalFooter}>
            {isLoading ? (
              <img className={styles.gifAnimation} src={ModalGif} alt={strings.GENERAL_LABELS.SAVING} />
            ) : (
              <Button
                type="primary"
                className={styles.businessUnitModalSaveButton}
                onClick={handleOk}
                disabled={!unitName || !selectedUsers}
              >
                {strings.GENERAL_LABELS.FORM_COMPLETE_BUTTON}
              </Button>
            )}
          </div>
        </>
      )}
    </CustomModal>
  )
}

export default BusinessUnitModal
