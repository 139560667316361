import { FC } from 'react'

import { BrandIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import { WizardTab } from '../../WizardTab/WizardTab'
import { WizardGenericModal } from '../WizardGenericModal'
import styles from './BrandModal.module.scss'

interface IBrandModalProps {
  logos?: string[]
  font?: {
    fileName: string
    fileUrl: string
    fileType: string
  }
  colors?: string[]
  onClose: () => void
}
const brandIcon = { title: strings.WIZARD.HEADER.TABS.BRAND, Icon: BrandIcon }

const BrandModal: FC<IBrandModalProps> = ({ logos, font, colors, onClose }) => {
  return (
    <WizardGenericModal onClose={onClose} tab={<WizardTab isBold Icon={brandIcon.Icon} title={brandIcon.title} />}>
      <div className={styles.brandModalWrapper}>
        {/* Logos Section */}
        <div className={styles.logosContainer}>
          <h1 className={styles.title}>{strings.GUIDELINE.TITLES.LOGOS}</h1>
          <div className={styles.borderContainer}>
            <div className={styles.logosContent}>
              {logos?.map((logo) => <img key={logo} src={logo} alt={'Logo'} className={styles.logo} />)}
            </div>
          </div>
        </div>

        {/* Fonts Section */}
        <div className={styles.fontsContainer}>
          <h1 className={styles.title}>{strings.GUIDELINE.TITLES.FONT}</h1>
          <div className={styles.borderContainer}>
            <div className={styles.fontsContent}>
              <p style={{ fontFamily: font?.fileName }} className={styles.font}>
                {font?.fileName}
              </p>
            </div>
          </div>
        </div>

        {/* Colors Section */}
        <h1 className={styles.title}>{strings.GUIDELINE.TITLES.COLOR_PALETTE}</h1>
        <div className={styles.colorsPaletteContainer}>
          <div className={styles.borderContainer}>
            <div className={styles.colorsContent}>
              {colors?.map((color) => (
                <div key={color} className={styles.colorBox} style={{ backgroundColor: color }} title={color} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </WizardGenericModal>
  )
}

export { BrandModal }
