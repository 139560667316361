import { Collapse, message } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'

import { CompetitorIcon, CompletedGreenIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { ICompetitive } from '../../../models/interfaces/competitive.interface'
import {
  createOrUpdateCompetitiveAnalysis,
  getCompetitiveAnalysisThunk,
  updateCompetitiveAnalysis,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import styles from './WizardCompetitiveAnalysis.module.scss'
import WizardCompetitor from './WizardCompetitor/WizardCompetitor'

interface CompetitorAnalysisItem {
  key: string
  label: ReactNode
  children: ReactNode
  icon?: ReactNode
  badge?: ReactNode
  data?: ICompetitive
}

interface IWizardCompetitiveAnalysisProps {
  messagingId?: string
  nextStep: () => void
  onProgressUpdate: (percent: number) => void
}

const WizardCompetitiveAnalysis: FC<IWizardCompetitiveAnalysisProps> = ({
  messagingId,
  onProgressUpdate,
  nextStep,
}) => {
  const dispatch = useAppDispatch()
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])
  const [competitorItems, setCompetitorItems] = useState<CompetitorAnalysisItem[]>([])

  useEffect(() => {
    if (messagingId) {
      dispatch(getCompetitiveAnalysisThunk({ messagingId }))
    }
  }, [dispatch, messagingId])

  useEffect(() => {
    const competitiveArray = competitiveAnalysis?.competitors
    if (competitiveArray) {
      const fetchedCompetitorItems: CompetitorAnalysisItem[] = competitiveArray.map((item) => ({
        key: item.companyName.trim(),
        label: <span className={styles.competitorItem}>{item.companyName}</span>,
        icon: <CompetitorIcon style={{ marginRight: 8 }} />,
        data: item,
        children: (
          <WizardCompetitor
            id={item.companyName.trim()}
            value={item}
            onRemove={() => handleRemove(item.companyName.trim())}
            onNext={(formData) => handleNext(formData, item.companyName.trim())}
          />
        ),
      }))
      setCompetitorItems(fetchedCompetitorItems)
    }
  }, [dispatch, messagingId, competitiveAnalysis])

  const saveCompetitiveAnalysisData = async () => {
    if (messagingId && competitiveAnalysis) {
      const { metadata, createdAt, updatedAt, competitorsText, ...filteredData } = competitiveAnalysis

      await dispatch(
        createOrUpdateCompetitiveAnalysis({
          competitiveAnalysisData: filteredData,
        }),
      )
    }
  }

  const handleNext = (newCompetitorData: ICompetitive, currentId: string) => {
    const newKey = newCompetitorData.companyName.trim() || currentId

    setCompetitorItems((prevItems) =>
      prevItems.map((item) =>
        item.key === currentId
          ? {
              ...item,
              key: newKey,
              label: <span className={styles.competitorItem}>{newCompetitorData.companyName}</span>,
              data: newCompetitorData,
              children: (
                <WizardCompetitor
                  id={newKey}
                  value={newCompetitorData}
                  onRemove={() => handleRemove(newKey)}
                  onNext={(formData) => handleNext(formData, newKey)}
                />
              ),
            }
          : item,
      ),
    )

    const currentCompetitors = competitiveAnalysis?.competitors || []
    const competitorExists = currentCompetitors.some((competitor) => competitor.companyName === currentId)

    let updatedCompetitors: ICompetitive[]
    if (competitorExists) {
      updatedCompetitors = currentCompetitors.map((competitor) =>
        competitor.companyName === currentId ? newCompetitorData : competitor,
      )
    } else {
      updatedCompetitors = [...currentCompetitors, newCompetitorData]
    }

    dispatch(updateCompetitiveAnalysis({ competitors: updatedCompetitors }))
    setActiveKey('')
    setCompletedKeys((prev) => [...new Set([...prev, newKey])])
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const handleRemove = (keyToRemove: string) => {
    setCompetitorItems((prevItems) => prevItems.filter((item) => item.key !== keyToRemove))
    setCompletedKeys((prevKeys) => prevKeys.filter((key) => key !== keyToRemove))
    setActiveKey('')

    if (competitiveAnalysis) {
      const remainingCompetitors = competitiveAnalysis.competitors.filter(
        (competitor) => competitor.companyName.trim() !== keyToRemove,
      )
      dispatch(updateCompetitiveAnalysis({ competitors: remainingCompetitors }))
    }
  }

  const addCompetitor = () => {
    const defaultKey = strings.WIZARD.COMPETITIVE_ANALYSIS.DEFAULT_NAME

    if (competitorItems.find((item) => item.key === defaultKey)) {
      message.error(strings.WIZARD.COMPETITIVE_ANALYSIS.ERROR)
      return
    }

    const newCompetitor: CompetitorAnalysisItem = {
      key: defaultKey,
      label: <span className={styles.competitorItem}>{`${strings.WIZARD.COMPETITIVE_ANALYSIS.ITEM_TITLE}`}</span>,
      icon: <CompetitorIcon style={{ marginRight: 8 }} />,
      children: (
        <WizardCompetitor
          id={defaultKey}
          onRemove={() => handleRemove(defaultKey)}
          onNext={(formData) => handleNext(formData, defaultKey)}
        />
      ),
    }
    setCompetitorItems((prevItems) => [...prevItems, newCompetitor])
    setActiveKey('')
  }

  const availableGenerate = completedKeys.length > 0
  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.COMPETITIVE_ANALYSIS.TITLE}
      subTitle={strings.WIZARD.COMPETITIVE_ANALYSIS.SUB_TITLE}
      availableGenerate={availableGenerate}
      nextStep={saveCompetitiveAnalysisData}
      step={2}
      onlyGenerateButton
    >
      {competitorItems.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
      <button className={styles.addCompetitor} onClick={addCompetitor}>
        {`+ ${competitorItems.length === 0 ? strings.WIZARD.COMPETITIVE_ANALYSIS.ADD_ITEM : strings.WIZARD.COMPETITIVE_ANALYSIS.ADD_ANOTHER_ITEM}`}
      </button>
    </WizardStepGenericComponent>
  )
}

export default WizardCompetitiveAnalysis
