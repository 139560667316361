import classNames from 'classnames'

import { FC } from 'react'
import { ActionButtonIcon } from '../../assets/icons'
import styles from './ActionButton.module.scss'

interface IActionButtonProps {
  onClick?: () => void
  ariaLabel: string
  className?: string
  isExpanded?: boolean
}

const ActionButton: FC<IActionButtonProps> = ({ onClick, ariaLabel, className, isExpanded }) => {
  return (
    <button className={classNames(styles.actionButton, className)} onClick={onClick} aria-label={ariaLabel}>
      {isExpanded ? (
        <ActionButtonIcon className={classNames(styles.icon, styles.flipped)} />
      ) : (
        <ActionButtonIcon className={styles.icon} />
      )}
    </button>
  )
}

export { ActionButton }
