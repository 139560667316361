import axiosInstance from './axios-interceptor'

export const apiRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  url: string,
  data: any = null,
) => {
  const response = await axiosInstance({
    method,
    url,
    data,
  })
  return response.data
}
