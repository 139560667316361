import { ECommonColumns } from '../../models/enums/columns.enum'

const businessUnitColumns = [
  ECommonColumns.MESSAGING_PROJECT_NAME,
  ECommonColumns.UPDATED_BY,
  ECommonColumns.STATUS,
  ECommonColumns.LAST_UPDATED,
]

export { businessUnitColumns }
