export enum ECommonColumns {
  NAME = 'Name',
  CLIENT_NAME = 'Client name',
  PROJECTS = 'Projects',
  BUSINESS_UNITS = 'Business units',
  BUSINESS_UNIT_NAME = 'Business unit name',
  STATUS = 'Status',
  LAST_UPDATED = 'Last updated',
  INDUSTRY = 'Industry',
  MESSAGING_PROJECT_NAME = 'Messaging project name',
  UPDATED_BY = 'Updated By',
}
