import { BrandIcon, DetailsIcon, ToneOfVoiceIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType } from '../../../models/enums/file-reference-type.enum'
import { EWizardTabs } from '../../../models/enums/wizard-tabs.enum'
import { IFile } from '../../../models/interfaces/file.interface'
import { IGuideline } from '../../../models/interfaces/guidelines.interface'
import { getFilesService } from '../../../services/files-upload-service'
import { getBusinessUnitGuidelineService } from '../../../services/wizard-service'

const handleSetGuideline = async (
  setBusinessUnitGuidelines: (businessUnitGuideline: IGuideline) => void,
  guidelineId: string,
) => {
  const response = await getBusinessUnitGuidelineService(guidelineId)
  setBusinessUnitGuidelines(response)
}

const handleSetGuidelineFiles = async (setGuidelineFiles: (files: IFile[]) => void, guidelineId: string) => {
  const response = await getFilesService(EFileReferenceType.GUIDELINE, guidelineId)
  setGuidelineFiles(response)
}

const tabs = [
  {
    id: 1,
    title: strings.WIZARD.HEADER.TABS.DETAILS,
    Icon: DetailsIcon,
    modal: EWizardTabs.DETAILS,
  },
  {
    id: 2,
    title: strings.WIZARD.HEADER.TABS.BRAND,
    Icon: BrandIcon,
    modal: EWizardTabs.BRAND,
  },
  {
    id: 3,
    title: strings.WIZARD.HEADER.TABS.TONE_OF_VOICE,
    Icon: ToneOfVoiceIcon,
    modal: EWizardTabs.TONE_OF_VOICE,
  },
]
export { handleSetGuideline, tabs, handleSetGuidelineFiles }
