import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { INews } from '../../models/interfaces/news.interface'
import { fetchCompaniesNews } from '../../services/command-center-service'

interface INewsState {
  news: INews[]
  isNewsLoading: boolean
  newsError: string | null
}

const initialState: INewsState = {
  news: [],
  isNewsLoading: true,
  newsError: null,
}

export const fetchNews = createAsyncThunk<INews[]>('news/fetchNews', async () => {
  return await fetchCompaniesNews()
})

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    clearNews: (state) => {
      state.news = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.isNewsLoading = true
        state.newsError = null
      })
      .addCase(fetchNews.fulfilled, (state, action: PayloadAction<INews[]>) => {
        state.isNewsLoading = false
        state.news = action.payload
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.isNewsLoading = false
        state.newsError = action.error.message || 'Failed to fetch news'
      })
  },
})

export const { clearNews } = newsSlice.actions

export default newsSlice.reducer
