import classNames from 'classnames'
import { MenuInfo } from 'rc-menu/lib/interface'
import { ReactNode } from 'react'

import { MenuProps } from 'antd'
import { strings } from '../../models/consts/strings'
import { EComponentType } from '../../models/enums/componentType.enum'
import { EmptyDataMsg } from '../EmptyDataMsg/EmptyDataMsg'
import { Loader } from '../Loader/Loader'
import styles from './ListView.module.scss'
import { ListViewRow } from './ListViewRow/ListViewRow'

export interface IListProps<T> {
  columns: string[]
  data: T[]
  columnKeyMap: Record<string, (row: T) => ReactNode>
  columnWidths?: Record<string, string>
  onRowClick: (row: T) => void
  componentType?: EComponentType
  noData?: boolean
  noSearchResults?: boolean
  isLoading?: boolean
  emptyMsg?: string
  setModalOpen?: () => void
  buttonTitle: string
  menuButton?: boolean
  dropDownItems?: MenuProps['items']
  onMenuClick?: (row: T, info: MenuInfo) => void
}

const ListView = <T extends { id: string }>({
  columns,
  data,
  columnKeyMap,
  columnWidths = {},
  onRowClick,
  componentType = EComponentType.DEFAULT,
  noData = false,
  noSearchResults = false,
  isLoading = false,
  emptyMsg = strings.EMPTY_CASES.EMPTY_SEARCH,
  setModalOpen = () => {},
  buttonTitle,
  menuButton,
  dropDownItems,
  onMenuClick,
}: IListProps<T>) => {
  const tableClass = classNames(styles.table, styles[componentType], {
    [styles.loading]: isLoading || noData || !data.length,
  })

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr className={styles.loaderRow}>
          <td colSpan={columns.length} className={styles.loaderCell}>
            <Loader />
          </td>
        </tr>
      )
    } else if (noSearchResults) {
      return (
        <tr className={styles.loaderRow}>
          <td colSpan={columns.length} className={styles.noResults}>
            {strings.EMPTY_CASES.EMPTY_SEARCH}
          </td>
        </tr>
      )
    } else if (noData || data.length === 0) {
      return (
        <tr className={styles.loaderRow}>
          <td colSpan={columns.length} className={styles.noDataCell}>
            <EmptyDataMsg msg={emptyMsg} onNewButtonClick={setModalOpen} buttonTitle={buttonTitle} />
          </td>
        </tr>
      )
    }

    return data.map((row) => (
      <ListViewRow
        key={row.id}
        row={row}
        columns={columns}
        columnKeyMap={columnKeyMap}
        onClick={() => onRowClick(row)}
        menuButton={menuButton}
        dropdownItems={dropDownItems}
        onMenuClick={onMenuClick}
      />
    ))
  }

  return (
    <table className={tableClass}>
      <thead>
        <tr className={styles.tableHeadline}>
          {columns.map((column) => (
            <th
              key={column}
              style={{
                width: columnWidths[column] || 'auto',
                maxWidth: columnWidths[column] || 'none',
              }}
            >
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{renderTableBody()}</tbody>
    </table>
  )
}

export { ListView }
