import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'

import { AddMoreFilesIcon, CompletedGreenIcon, GeneralInformationIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { EGuidelineType } from '../../../models/enums/guideline-type.enum'
import { AccordionItem } from '../../../models/interfaces/accordion-item.interface'
import {
  EWizardResponseType,
  IWizardResponseType,
} from '../../../models/interfaces/responses/wizard-response-type.interface'
import { fetchGuidelineThunk } from '../../../store/slices/guideline.slice'
import {
  createOrUpdateMessagingEngine,
  getDiscoveryThunk,
  getMessagingEngineThunk,
  updateMessagingEngine,
} from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import GeneralInformation from '../WizardDiscovery/GeneralInformation/GeneralInformation'
import UploadFilesDropDown from '../WizardDiscovery/UploadFilesDropDown/UploadFilesDropDown'
import WizardStepGenericComponent from '../WizardStepGenericComponent/WizardStepGenericComponent'
import styles from './WizardMessagingEngine.module.scss'

interface IWizardMessagingEngineProps {
  messagingId?: string
  nextStep: () => void
  onProgressUpdate: (percent: number) => void
  businessUnitId?: string
  AILoading: (isLoading: boolean) => void
}
const WizardMessagingEngine: FC<IWizardMessagingEngineProps> = ({
  messagingId,
  nextStep,
  businessUnitId,
  onProgressUpdate,
  AILoading,
}) => {
  const dispatch = useAppDispatch()
  const messagingEngine = useAppSelector((state) => state.wizard.data?.messagingEngine)
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)
  const discovery = useAppSelector((state) => state.wizard.data.discovery)
  const guideline = useAppSelector((state) => state.guidelineSlice.guideline)

  const [activeKey, setActiveKey] = useState<string>('')
  const [completedKeys, setCompletedKeys] = useState<string[]>([])

  useEffect(() => {
    if (!messagingId) return

    if (!messagingEngine) {
      dispatch(getMessagingEngineThunk({ messagingId }))
    }
    if (!guideline && businessUnitId) {
      dispatch(fetchGuidelineThunk({ referenceType: EGuidelineType.BUSINESSUNIT, referenceId: businessUnitId }))
    }
    if (!discovery) {
      dispatch(getDiscoveryThunk({ messagingId }))
    }
  }, [messagingId, messagingEngine, guideline, businessUnitId, discovery, dispatch])

  function toWizardResponseArray(value?: string): IWizardResponseType[] {
    if (!value) {
      return []
    }
    return [
      {
        type: EWizardResponseType.TEXT,
        value,
      },
    ]
  }

  const saveMessagingData = async () => {
    if (messagingId && messagingEngine && guideline && discovery) {
      const filteredGuideline = {
        generalInformation: toWizardResponseArray(guideline.generalInformation),
        toneOfVoice: toWizardResponseArray(guideline.toneOfVoice),
        grammar: toWizardResponseArray(guideline.grammar),
        terminology: toWizardResponseArray(guideline.terminology),
        acronyms: toWizardResponseArray(guideline.acronyms),
      }

      const filteredDiscovery = {
        generalInformation: discovery.discoveryData.generalInformation ?? [],
        clientReferences: discovery.discoveryData.clientReferences ?? [],
        targetAudience: discovery.discoveryData.targetAudience ?? [],
        proof: discovery.discoveryData.proof ?? [],
        customerCaseStudies: discovery.discoveryData.customerCaseStudies ?? [],
        discoverySummary: discovery.discoveryData.discoverySummary ?? [],
        moreInformation: discovery.discoveryData.moreInformation ?? [],
      }

      const messagingEngineData = {
        ...filteredGuideline,
        files: [],
        discovery: filteredDiscovery,
        competitiveAnalysis: competitiveAnalysis?.competitorsText ? competitiveAnalysis?.competitorsText.join(',') : '',
        moreInformation: messagingEngine.messagingEngineData.moreInformation,
      }

      const payload = {
        messagingId,
        messagingEngineData,
      }
      // AILoading(true)

      await dispatch(createOrUpdateMessagingEngine(payload))
      // pollForUpdatedDiscovery()
    }
  }

  // const pollForUpdatedDiscovery = () => {
  //   const intervalId = setInterval(async () => {
  //     try {
  //       if (messagingId) {
  //         const resultAction = await dispatch(getDiscoveryThunk({ messagingId }))

  //         if (getDiscoveryThunk.rejected.match(resultAction)) {
  //           throw new Error(resultAction.error.message || 'Error fetching discovery')
  //         }

  //         const updatedDiscovery = resultAction.payload
  //         if (!updatedDiscovery) return

  //         if ('discoveryText' in updatedDiscovery && updatedDiscovery.discoveryText) {
  //           AILoading(false)
  //           clearInterval(intervalId)
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Polling error:', error)
  //       AILoading(false)
  //       clearInterval(intervalId)
  //     }
  //   }, 5000)
  // }

  const handleNext = (currentKey: string, value: IWizardResponseType[]) => {
    if (!messagingEngine) return
    const nextKey = (parseInt(currentKey) + 1).toString()
    setActiveKey(nextKey)

    const newMoreInformation = [...messagingEngine.messagingEngineData.moreInformation, ...value]
    dispatch(
      updateMessagingEngine({
        messagingEngineData: {
          ...messagingEngine.messagingEngineData,
          moreInformation: newMoreInformation,
        },
      }),
    )

    setCompletedKeys((prev) => {
      const updated = [...new Set([...prev, currentKey])]
      return updated
    })
  }

  const handleChange = (key: string | string[]) => {
    setActiveKey(key as string)
  }

  const items: AccordionItem[] = [
    {
      key: '1',
      label: <>{strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_INFORMATION}</>,
      icon: <GeneralInformationIcon style={{ marginRight: 8 }} />,
      children: (
        <GeneralInformation
          value={messagingEngine?.messagingEngineData.moreInformation}
          onNext={(data) => handleNext('1', data)}
          title={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.TITLE}
          placeholder={strings.WIZARD.DISCOVERY.GENERAL_INFORMATION.PLACE_HOLDER}
        />
      ),
    },
    {
      key: '2',
      label: strings.WIZARD.MESSAGING_ENGINE.ADD_MORE_LINKS_AND_FILES,
      icon: <AddMoreFilesIcon style={{ marginRight: 8 }} />,
      children: (
        <UploadFilesDropDown
          value={messagingEngine?.messagingEngineData.moreInformation}
          onNext={(data) => handleNext('2', data)}
          title={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.TITLE}
          isLink
          linkPlaceHolder={strings.WIZARD.DISCOVERY.ATTACH_LINKS_AND_FILES.PLACE_HOLDER}
          referenceId={messagingId}
          referenceType={EFileReferenceType.MESSAGING_ENGINE}
          subReferenceType={EFileSubReferenceType.ADDITIONAL_FILES}
        />
      ),
    },
  ]

  return (
    <WizardStepGenericComponent
      title={strings.WIZARD.MESSAGING_ENGINE.TITLE}
      subTitle={strings.WIZARD.MESSAGING_ENGINE.SUB_TITLE}
      availableGenerate={true}
      nextStep={saveMessagingData}
      step={3}
      onlyGenerateButton
    >
      {items.map((item) => (
        <Collapse
          key={item.key}
          className={styles.panel}
          activeKey={activeKey}
          expandIconPosition="end"
          accordion
          onChange={(key) => handleChange(key)}
          style={{ alignItems: 'center' }}
        >
          <Collapse.Panel
            key={item.key}
            header={
              <div className={styles.dropDownHeader}>
                {item.icon}
                {item.label}
                {completedKeys.includes(item.key) && (
                  <div className={styles.completeBadge}>
                    <CompletedGreenIcon />
                    <p className={styles.badgeTitle}>{strings.GENERAL_LABELS.COMPLETED}</p>
                  </div>
                )}
              </div>
            }
          >
            {item.children}
          </Collapse.Panel>
        </Collapse>
      ))}
    </WizardStepGenericComponent>
  )
}

export default WizardMessagingEngine
