import { apiEndpoints } from '../constants/api-endpoints'
import { EFileReferenceType } from '../models/enums/file-reference-type.enum'
import { EGuidelineType } from '../models/enums/guideline-type.enum'
import { IGuideline } from '../models/interfaces/guidelines.interface'
import { ILogo } from '../models/interfaces/logo.interface'
import { apiRequest } from '../utils/api-utils/api'

const getGuidelineService = async (referenceType: EGuidelineType, referenceId: string): Promise<IGuideline> => {
  const queryParams = `?referenceType=${referenceType}&referenceId=${referenceId}`
  const response = await apiRequest('GET', `${apiEndpoints.GUIDELINES.GET_GUIDELINE}${queryParams}`)
  return response.data
}

const createGuidelineService = async (guidelineData: Partial<IGuideline>) => {
  const response = await apiRequest('POST', `${apiEndpoints.GUIDELINES.CREATE_GUIDELINE}`, guidelineData)
  return response.data
}

const getLogosService = async (guidelineId: string): Promise<ILogo[]> => {
  const response = await apiRequest('GET', apiEndpoints.LOGOS.GET_LOGOS(guidelineId))
  return response.data.map((logo: ILogo) => ({
    ...logo,
    uid: logo.id,
    name: logo.logoName,
    status: 'done',
    url: logo.logoUrl,
  }))
}

const uploadLogoService = async (formData: FormData) => {
  return await apiRequest('POST', apiEndpoints.LOGOS.ADD_LOGO, formData)
}

const getGuidelineFilesService = async (guidelineId: string) => {
  const response = await apiRequest('GET', apiEndpoints.FILES.GET_FILES(EFileReferenceType.GUIDELINE, guidelineId))
  const data = response.data
  return data
}

const deleteFileService = async (id: string): Promise<void> => {
  const response = await apiRequest('DELETE', apiEndpoints.FILES.DELETE_FILE, { id })
  return response
}

const deleteLogoService = async (logoId: string, storageFileName: string) => {
  const response = await apiRequest('DELETE', apiEndpoints.LOGOS.DELETE_LOGO(logoId, storageFileName))
  return response
}

export {
  uploadLogoService,
  createGuidelineService,
  getGuidelineService,
  getLogosService,
  deleteFileService,
  getGuidelineFilesService,
  deleteLogoService,
}
