import { Button, message } from 'antd'
import { FC, useEffect, useState } from 'react'

import { ModalGif } from '../../assets/icons'
import { strings } from '../../models/consts/strings'
import { IIndustry } from '../../models/interfaces/industry.interface'
import { createClient } from '../../store/slices/clients.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { CustomModal } from '../CustomModal/CustomModal'
import { ModalError } from '../CustomModal/ModalError/ModalError'
import { ModalInput } from '../CustomModal/ModalInput/ModalInput'
import styles from './ClientModal.module.scss'
import { handleFetchIndustries } from './clientModal.utils'

interface IClientModalProps {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}

const ClientModal: FC<IClientModalProps> = ({ isOpen, onOk, onCancel }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const [clientName, setClientName] = useState<string>('')
  const [selectedIndustry, setSelectedIndustry] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [industries, setIndustries] = useState<IIndustry[]>([])

  useEffect(() => {
    handleFetchIndustries(setIndustries)
  }, [setIndustries])

  const resetFields = () => {
    setClientName('')
    setSelectedIndustry(undefined)
  }

  const handleOk = async () => {
    if (!clientName || !selectedIndustry) {
      return
    }

    setIsLoading(true)

    try {
      await dispatch(
        createClient({
          name: clientName,
          IndustryName: selectedIndustry.toUpperCase(),
        }),
      ).unwrap()
      setTimeout(() => resetFields(), 1800)
      message.success(strings.SUCCESS_MSG.CLIENT_MODAL)
    } catch (error) {
      setIsError(true)
      message.error({ content: strings.CLIENTS_PAGE.ERRORS.CLIENT_ERROR, duration: 3 })
    } finally {
      setTimeout(() => {
        onOk()
        setIsLoading(false)
      }, 1800)
    }
  }

  const inputData = [
    {
      key: 1,
      label: strings.CLIENTS_PAGE.CLIENT_MODAL.LABELS.CLIENT_NAME,
      placeholder: strings.CLIENTS_PAGE.CLIENT_MODAL.LABELS.CLIENT_NAME,
      value: clientName,
      onChange: setClientName,
    },
    {
      key: 2,
      label: strings.CLIENTS_PAGE.CLIENT_MODAL.LABELS.INDUSTRY,
      placeholder: strings.CLIENTS_PAGE.CLIENT_MODAL.LABELS.INDUSTRY,
      value: selectedIndustry,
      onChange: setSelectedIndustry,
      options: industries,
    },
  ]
  return (
    <CustomModal isOpen={isOpen} onCancel={onCancel} className="client">
      {isError ? (
        <ModalError
          onClose={() => setIsError(false)}
          firstMsg={strings.CLIENTS_PAGE.ERRORS.ERROR_MSG}
          secondMsg={strings.CLIENTS_PAGE.ERRORS.ERROR_MSG2}
        />
      ) : (
        <>
          <div>
            <h1 className={styles.clientModalTitle}>{strings.CLIENTS_PAGE.CLIENT_MODAL.TITLE}</h1>
          </div>
          <div className={styles.modalFields}>
            {inputData.map((data) => (
              <ModalInput
                key={data.key}
                label={data.label}
                placeholder={data.placeholder}
                value={data.value}
                onChange={data.onChange}
                options={data.options}
                connectedUser={user}
              />
            ))}
          </div>
          <div className={styles.clientModalFooter}>
            {isLoading ? (
              <img className={styles.gifAnimation} src={ModalGif} alt={strings.GENERAL_LABELS.SAVING} />
            ) : (
              <Button
                type="primary"
                className={styles.clientModalSaveButton}
                onClick={handleOk}
                disabled={!clientName || !selectedIndustry}
              >
                {strings.GENERAL_LABELS.FORM_COMPLETE_BUTTON}
              </Button>
            )}
          </div>
        </>
      )}
    </CustomModal>
  )
}

export default ClientModal
