import { FC } from 'react'

import { ArrowBack, ErrorIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import styles from './ModalError.module.scss'

interface IModalErrorProps {
  onClose: () => void
  firstMsg: string
  secondMsg: string
}
const ModalError: FC<IModalErrorProps> = ({ onClose, firstMsg, secondMsg }) => {
  return (
    <div className={styles.errorModalContainer}>
      <ErrorIcon className={styles.failureAnimation} />
      <h1 className={styles.errorFirstTitle}>{firstMsg}</h1>
      <h2 className={styles.errorSecondTitle}>{secondMsg}</h2>
      <button className={styles.backButton} onClick={onClose}>
        <ArrowBack />
        {strings.GENERAL_LABELS.ERROR_BACK_BUTTON}
      </button>
    </div>
  )
}

export { ModalError }
