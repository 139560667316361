import { FC } from 'react'

import { LoaderGif } from '../../assets/icons'
import styles from './Loader.module.scss'

interface ILoaderProps {
  size?: number
  altText?: string
}

const Loader: FC<ILoaderProps> = ({ size = 50, altText = 'Loading...' }) => {
  return (
    <div className={styles.loaderContainer}>
      <img src={LoaderGif} alt={altText} className={styles.loader} style={{ width: size, height: size }} />
    </div>
  )
}

export { Loader }
