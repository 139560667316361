import classNames from 'classnames'
import { FC } from 'react'

import styles from './GenericButton.module.scss'

export interface IGenericButtonProps {
  title?: string
  onClick?: () => void
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  secondary?: boolean
  isDisabled?: boolean
  isDangerButton?: boolean
  style?: React.CSSProperties
}

const GenericButton: FC<IGenericButtonProps> = ({
  style,
  isDangerButton,
  title,
  onClick,
  Icon,
  secondary,
  isDisabled,
}) => {
  const buttonClass = classNames(styles.button, {
    [styles.secondary]: secondary,
    [styles.danger]: isDangerButton,
  })
  return (
    <button style={style} disabled={isDisabled} className={buttonClass} onClick={onClick}>
      {Icon && <Icon />}
      {title && <h3 className={classNames(styles.buttonTitle, { [styles.disabled]: isDisabled })}>{title}</h3>}
    </button>
  )
}

export { GenericButton }
