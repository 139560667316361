import { PlusOutlined } from '@ant-design/icons'
import type { GetProp, UploadFile, UploadProps } from 'antd'
import { Image, Upload, message } from 'antd'
import { UploadListType } from 'antd/es/upload/interface'
import { FC, useCallback, useEffect, useState } from 'react'

import { LogoIcon, UploadIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EFileReferenceType, EFileSubReferenceType } from '../../../models/enums/file-reference-type.enum'
import { IFile } from '../../../models/interfaces/file.interface'
import { uploadFileService } from '../../../services/files-upload-service'
import { GenericButton } from '../../GenericButton/GenericButton'
import styles from './FilesUploadGuideline.module.scss'
import { convertIFileToUploadFile } from '../../../utils/file-utils/convert-file.util'

const { Dragger } = Upload
interface FilesUploadGuidelineProps {
  referenceId?: string
  files?: IFile[]
  listType: UploadListType
  subReferenceType: EFileSubReferenceType
  firstText?: string
  secondText?: string
  buttonText?: string
  multiple?: boolean
  isButton?: boolean
  accept?: string
  onFileUpload?: (files: IFile[]) => void
  onFileRemove?: (fileId: string) => void
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const FilesUploadGuideline: FC<FilesUploadGuidelineProps> = ({
  isButton,
  files = [],
  buttonText,
  firstText,
  secondText,
  accept,
  referenceId,
  subReferenceType,
  listType,
  onFileUpload,
  onFileRemove,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [hasFiles, setHasFiles] = useState<boolean>(false)

  useEffect(() => {
    const convertedFiles = files.filter((file) => file.subReferenceType === subReferenceType)
    setFileList(convertIFileToUploadFile(convertedFiles))
  }, [files, subReferenceType])

  useEffect(() => {
    setHasFiles(fileList.length > 0)
  }, [fileList])

  const handlePreview = useCallback(
    async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType)
      }
      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
    },
    [setPreviewImage, setPreviewOpen],
  )

  const props: UploadProps = {
    accept,
    listType: listType,
    fileList,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        if (!referenceId) throw new Error('referenceId is required but was not provided')
        const formData = new FormData()
        formData.append('file', file)
        const response = await uploadFileService(formData)
        onSuccess && onSuccess(response)
      } catch (error) {
        onError?.(error instanceof Error ? error : new Error('Unexpected upload error'))
      }
    },
    onChange(info) {
      const { file, fileList } = info
      setFileList(fileList)

      if (file.status === 'done') {
        const { fileUrl } = file.response
        const newFile: IFile = {
          referenceId: referenceId || '',
          referenceType: EFileReferenceType.GUIDELINE,
          subReferenceType: subReferenceType,
          fileName: file.name,
          fileType: file.type || '',
          fileUrl,
        }
        onFileUpload?.([...files, newFile])
        message.success(`${file.name} uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${file.name} upload failed`)
      }
    },
    onRemove: async (info) => {
      try {
        setFileList((prev) => prev.filter((item) => item.uid !== info.uid))
        onFileRemove?.(info.uid)
        message.success(`file deleted successfully, Don't forget to save changes!`)
      } catch (error) {
        message.error(`file deletion failed`)
        return false
      }
    },
    showUploadList: { showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: true },
  }
  return (
    <>
      {subReferenceType === EFileSubReferenceType.LOGO ? (
        hasFiles ? (
          <div className={styles.logosContainer}>
            <Upload {...props} onPreview={handlePreview}>
              <button className={styles.plusIcon} style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div className={styles.uploadButton}>{strings.GENERAL_LABELS.UPLOAD}</div>
              </button>
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </div>
        ) : (
          <Dragger style={{ maxHeight: '200px' }} {...props} onPreview={handlePreview}>
            <div className={styles.draggerContainer}>
              <LogoIcon />
              <div className={styles.textContainer}>
                <p className={styles.text1}>{firstText}</p>
                <p className={styles.text2}>{secondText}</p>
              </div>
              {isButton && buttonText && <GenericButton secondary title={buttonText} onClick={() => {}} />}
            </div>
          </Dragger>
        )
      ) : (
        <Dragger style={{ maxHeight: '200px' }} {...props}>
          <div className={styles.draggerContainer}>
            <UploadIcon />
            <div className={styles.textContainer}>
              <p className={styles.text1}>{firstText}</p>
              <p className={styles.text2}>{secondText}</p>
            </div>
            {isButton && buttonText && <GenericButton secondary title={buttonText} onClick={() => {}} />}
          </div>
        </Dragger>
      )}
    </>
  )
}

export default FilesUploadGuideline
