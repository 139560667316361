import { IIndustry } from '../../models/interfaces/industry.interface'
import { fetchIndustriesService } from '../../services/clients-service'
import { formatString } from '../../utils/format-string.util'

export const handleFetchIndustries = async (setIndustries: (Industries: IIndustry[]) => void) => {
  const industries = await fetchIndustriesService()

  const formattedIndustries = industries.data.map((industry: IIndustry) => ({
    ...industry,
    name: formatString(industry.name),
  }))
  setIndustries(formattedIndustries)
}
