import { ReactNode } from 'react'
import { UserOption } from '../../components/CustomModal/ModalInput/UserOption/UserOption'
import { formatString } from '../../utils/format-string.util'
import { ECommonColumns } from '../enums/columns.enum'
import { IBusinessUnit } from '../interfaces/business-unit.interface'
import { IClient } from '../interfaces/client.interface'
import { IProject } from '../interfaces/project.interface'

const projectColumnKeyMap: Partial<Record<ECommonColumns, (row: IProject) => React.ReactNode>> = {
  [ECommonColumns.CLIENT_NAME]: (row) => row.businessUnit?.client?.name || 'N/A',
  [ECommonColumns.MESSAGING_PROJECT_NAME]: (row) => row.name,
  [ECommonColumns.STATUS]: (row) => row.status,
  [ECommonColumns.LAST_UPDATED]: (row) => row.updatedAt,
}

const clientColumnKeyMap: Partial<Record<ECommonColumns, (row: IClient) => React.ReactNode>> = {
  [ECommonColumns.CLIENT_NAME]: (row) => row.name,
  [ECommonColumns.INDUSTRY]: (row) => formatString(row.industry),
  [ECommonColumns.BUSINESS_UNITS]: (row) => row.businessUnitCount,
  [ECommonColumns.LAST_UPDATED]: (row) => row.updatedAt,
}

const specificClientColumnKeyMap: Partial<Record<ECommonColumns, (row: IBusinessUnit) => React.ReactNode>> = {
  [ECommonColumns.BUSINESS_UNIT_NAME]: (row) => row.name,
  [ECommonColumns.PROJECTS]: (row) => row.projectCount,
  [ECommonColumns.LAST_UPDATED]: (row) => row.updatedAt,
}

const businessUnitColumnKeyMap: Partial<Record<ECommonColumns, (row: IProject) => ReactNode>> = {
  [ECommonColumns.MESSAGING_PROJECT_NAME]: (row) => row.name,
  [ECommonColumns.UPDATED_BY]: (row) => <UserOption size={25} user={row.updatedByUser} />,
  [ECommonColumns.STATUS]: (row) => row.status,
  [ECommonColumns.LAST_UPDATED]: (row) => row.updatedAt,
}

export { clientColumnKeyMap, projectColumnKeyMap, specificClientColumnKeyMap, businessUnitColumnKeyMap }
