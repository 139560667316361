import { FC } from 'react'

import { WizardTab } from '../../WizardTab/WizardTab'
import { WizardGenericModal } from '../WizardGenericModal'
import { DetailsIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import styles from './DetailsModal.module.scss'

interface IDetailsModalProps {
  generalInformation?: string
  website?: string
  parentWebsite?: string
  onClose: () => void
}
const detailsIcon = { title: strings.WIZARD.HEADER.TABS.DETAILS, Icon: DetailsIcon }

const DetailsModal: FC<IDetailsModalProps> = ({ onClose, generalInformation, website, parentWebsite }) => {
  return (
    <WizardGenericModal tab={<WizardTab isBold Icon={detailsIcon.Icon} title={detailsIcon.title} />} onClose={onClose}>
      <div className={styles.detailsModalContainer}>
        <div className={styles.boxContainer}>
          <h2 className={styles.title}>{strings.GUIDELINE.GENERAL_DETAILS.GENERAL_INFORMATION.LABEL}</h2>
          <span className={styles.text}>{generalInformation}</span>
        </div>
        <div className={styles.boxContainer}>
          <h2 className={styles.title}>{strings.GUIDELINE.GENERAL_DETAILS.WEBSITE.LABEL}</h2>
          <a target="_blank" rel="noopener noreferrer" href={website} className={(styles.text, styles.link)}>
            {website}
          </a>
        </div>
        {website !== parentWebsite && (
          <div className={styles.boxContainer}>
            <h2 className={styles.title}>{strings.GUIDELINE.GENERAL_DETAILS.PARENT_WEBSITE.LABEL}</h2>
            <a target="_blank" rel="noopener noreferrer" href={parentWebsite} className={(styles.text, styles.link)}>
              {parentWebsite}
            </a>
          </div>
        )}
      </div>
    </WizardGenericModal>
  )
}

export { DetailsModal }
