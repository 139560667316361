import { FC } from 'react'

import { PurplePlusIcon } from '../../assets/icons'
import { GenericButton } from '../GenericButton/GenericButton'
import styles from './EmptyDataMsg.module.scss'

interface IEmptyDataMsgProps {
  onNewButtonClick: () => void
  msg: string
  buttonTitle: string
}

const EmptyDataMsg: FC<IEmptyDataMsgProps> = ({ onNewButtonClick, msg, buttonTitle }) => {
  return (
    <div className={styles.emptyData}>
      <p className={styles.msg}>{msg}</p>
      <GenericButton secondary Icon={PurplePlusIcon} title={buttonTitle} onClick={onNewButtonClick} />
    </div>
  )
}

export { EmptyDataMsg }
