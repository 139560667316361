import { Input, message } from 'antd'
import { FC, useState } from 'react'

import { EditIcon, ToneOfVoiceIcon, VoiceIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import { updateProjectThunk } from '../../../../../store/slices/wizard.slice'
import { useAppDispatch } from '../../../../../store/store'
import { GenericButton } from '../../../../GenericButton/GenericButton'
import { WizardTab } from '../../WizardTab/WizardTab'
import { WizardGenericModal } from '../WizardGenericModal'
import styles from './ToneOfVoiceModal.module.scss'
import { ToneOfVoiceModalSave } from './ToneOfVoiceModalSave/ToneOfVoiceModalSave'

const { TextArea } = Input

interface IToneOfVoiceModalProps {
  guidelineId?: string
  toneOfVoice?: string
  projectId?: string
  onClose: () => void
}
const toneOfVoiceIcon = { title: strings.WIZARD.HEADER.TABS.TONE_OF_VOICE, Icon: ToneOfVoiceIcon }

const ToneOfVoiceModal: FC<IToneOfVoiceModalProps> = ({ projectId, toneOfVoice: initialToneOfVoice, onClose }) => {
  const dispatch = useAppDispatch()

  const [editState, setEditState] = useState(false)
  const [toneOfVoice, setToneOfVoice] = useState(initialToneOfVoice || '')
  const [saveModalOpen, setSaveModalOpen] = useState(false)

  const handleEditClick = () => {
    setEditState(true)
  }

  const handleCancelEdit = () => {
    setEditState(false)
    setToneOfVoice(initialToneOfVoice || '')
  }

  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setToneOfVoice(value)
  }

  const handleToneOfVoiceChange = async () => {
    if (projectId) {
      try {
        setSaveModalOpen(false)
        dispatch(
          updateProjectThunk({
            projectId,
            payload: { toneOfVoice: toneOfVoice },
          }),
        )
        message.success(strings.SUCCESS_MSG.TONE_OF_VOICE)
        setEditState(false)
      } catch (error) {
        message.error(strings.WIZARD.HEADER.ERROR_MSGS.TONE_OF_VOICE)
      }
    }
    setSaveModalOpen(false)
  }

  return (
    <WizardGenericModal
      tab={<WizardTab isBold Icon={toneOfVoiceIcon.Icon} title={toneOfVoiceIcon.title} />}
      onClose={onClose}
      toolTip={{ title: strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.TOOL_TIP, direction: 'bottomLeft' }}
    >
      <div className={styles.toneOfVoiceContainer}>
        <div className={styles.boxContainer}>
          {!editState ? (
            <div className={styles.buttonBackground} onClick={handleEditClick}>
              <EditIcon className={styles.editIcon} />
            </div>
          ) : (
            <button onClick={handleCancelEdit} className={styles.cancelEdit}>
              {strings.GENERAL_LABELS.CANCEL}
            </button>
          )}
          <TextArea className={styles.text} disabled={!editState} value={toneOfVoice} onChange={handleInputChange} />
        </div>
        <hr className={styles.hrBorder} />
        <div className={styles.bottomContainer}>
          <GenericButton
            title={strings.WIZARD.HEADER.TONE_OF_VOICE_MODAL.MODAL_SAVE}
            Icon={VoiceIcon}
            onClick={() => setSaveModalOpen(true)}
            isDisabled={!editState}
          />
        </div>
      </div>
      {saveModalOpen && (
        <ToneOfVoiceModalSave
          isOpen={saveModalOpen}
          onOk={handleToneOfVoiceChange}
          onCancel={() => setSaveModalOpen(false)}
        />
      )}
    </WizardGenericModal>
  )
}

export { ToneOfVoiceModal }
