import classNames from 'classnames'
import { FC, ReactNode, useState } from 'react'

import { PlusIcon } from '../../assets/icons'
import { EComponentType } from '../../models/enums/componentType.enum'
import { GenericButton } from '../GenericButton/GenericButton'
import styles from './ListViewContainer.module.scss'

interface IListViewContainerProps {
  title?: string
  buttonTitle: string
  children: ReactNode
  isButton: boolean
  componentType?: EComponentType
  ModalComponent?: FC<{ isOpen: boolean; onOk: () => void; onCancel: () => void }>
  onModalOk?: () => void
}

const ListViewContainer: FC<IListViewContainerProps> = ({
  title,
  children,
  isButton,
  componentType = EComponentType.DEFAULT,
  ModalComponent,
  buttonTitle,
  onModalOk,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleModalOk = () => {
    if (onModalOk) {
      onModalOk()
    }
    setOpenModal(false)
  }

  const containerClass = classNames(styles.dataListContainer, {
    [styles.commandCenter]: styles[componentType],
  })

  return (
    <div className={containerClass}>
      <div className={styles.listTopContainer}>
        <h2 className={styles.dataListTitle}>{title}</h2>
        {isButton && <GenericButton Icon={PlusIcon} title={buttonTitle} onClick={() => setOpenModal(true)} />}
      </div>
      {children}
      {ModalComponent && (
        <ModalComponent isOpen={openModal} onOk={handleModalOk} onCancel={() => setOpenModal(false)} />
      )}
    </div>
  )
}

export { ListViewContainer }
