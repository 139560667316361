import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import { apiRequest } from '../utils/api-utils/api'

const fetchClientsService = async (
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
  search?: string,
) => {
  const payload = {
    page,
    limit,
    sort,
    filter,
    search,
  }
  const response = await apiRequest('POST', apiEndpoints.CLIENTS.GET_CLIENTS, payload)
  const clients = response.data.clients
  return {
    clients,
    meta: response.data.meta,
  }
}

const createClientService = async (name: string, industry: string) => {
  const response = await apiRequest('POST', apiEndpoints.CLIENTS.CREATE_CLIENT, {
    name,
    industry: industry,
  })
  return response
}

const fetchIndustriesService = async () => {
  return await apiRequest('GET', apiEndpoints.INDUSTRIES.GET_ALL_INDUSTRIES)
}

const createBusinessUnitService = async (name: string, clientId: string) => {
  const response = await apiRequest('POST', apiEndpoints.BUSINESS_UNITS.CREATE_BUSINESS_UNIT, {
    name,
    clientId,
  })
  return response
}
export { createBusinessUnitService, createClientService, fetchClientsService, fetchIndustriesService }
