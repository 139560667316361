import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { GenericButton } from '../../components/GenericButton/GenericButton'
import WizardCompetitiveAnalysis from '../../components/WizardComponents/WizardCompetitiveAnalysis/WizardCompetitiveAnalysis'
import WizardDiscovery from '../../components/WizardComponents/WizardDiscovery/WizardDiscovery'
import { WizardHeader } from '../../components/WizardComponents/WizardHeader/WizardHeader'
import WizardMessagingEngine from '../../components/WizardComponents/WizardMessagingEngine/WizardMessagingEngine'
import { WizardStepper } from '../../components/WizardComponents/WizardStepper/WizardStepper'
import WizardTextEditor from '../../components/WizardComponents/WizardTextEditor/WizardTextEditor'
import {
  createOrUpdateCompetitiveAnalysis,
  fetchProjectThunk,
  getCompetitiveAnalysisThunk,
  updateCompetitiveAnalysis,
  updateCompetitiveAnalysisTextThunk,
  updateDiscovery,
  updateDiscoveryTextThunk,
} from '../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './Wizard.module.scss'

const Wizard: FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector((state) => state.wizard.data?.project)
  const discovery = useAppSelector((state) => state.wizard.data?.discovery)
  const competitiveAnalysis = useAppSelector((state) => state.wizard.data?.competitiveAnalysis)
  const { projectId } = useParams<{ projectId: string }>()

  const [progress, setProgress] = useState(0)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [discoveryEditorText, setDiscoveryEditorText] = useState<string>(discovery?.discoveryText || '')
  const [competitiveAnalysisEditorText, setCompetitiveAnalysisEditorText] = useState<string[]>(
    competitiveAnalysis?.competitorsText || [''],
  )
  const [AILoading, setAILoading] = useState(false)

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectThunk({ projectId }))
    }
  }, [dispatch, projectId])

  useEffect(() => {
    if (project?.messaging?.id) {
      dispatch(getCompetitiveAnalysisThunk({ messagingId: project.messaging.id }))
    }
  }, [dispatch, project?.messaging?.id])

  const goToNextStep = async () => {
    if (currentStep === 0 && discovery?.id) {
      dispatch(updateDiscovery({ discoveryText: discoveryEditorText }))
      dispatch(updateDiscoveryTextThunk({ discoveryId: discovery?.id, discoveryText: discoveryEditorText }))
    } else if (currentStep === 1 && competitiveAnalysis?.competitors && competitiveAnalysis.id) {
      dispatch(updateCompetitiveAnalysis({ competitorsText: competitiveAnalysisEditorText }))
      for (let index = 0; index < competitiveAnalysis?.competitors.length; index++) {
        dispatch(
          updateCompetitiveAnalysisTextThunk({
            competitiveAnalysisId: competitiveAnalysis?.id,
            index: index,
            text: competitiveAnalysisEditorText[index],
          }),
        )
      }
      const { metadata, createdAt, updatedAt, competitorsText, ...filteredData } = competitiveAnalysis
      await dispatch(
        createOrUpdateCompetitiveAnalysis({
          competitiveAnalysisData: filteredData,
        }),
      )
    }
    setCurrentStep((prevStep) => prevStep + 1)
  }

  const goToPreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  const handleUpdateFirstCompetitorText = (updatedVal: string | string[]) => {
    const fullText = Array.isArray(updatedVal) ? updatedVal.join('<hr>') : updatedVal
    const splitTexts = fullText.split(/<hr\s*\/?>/gi)
    const headingsRegex =
      /<h3><strong>(First competitor:|Second competitor:|Third competitor:|Fourth competitor:|Fifth competitor:)<\/strong><\/h3>/gi
    const cleanedTexts = splitTexts.map((segment) => segment.replace(headingsRegex, '').trim())
    setCompetitiveAnalysisEditorText(cleanedTexts)
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <WizardDiscovery
              messagingId={project?.messaging?.id}
              nextStep={goToNextStep}
              onProgressUpdate={setProgress}
              AILoading={setAILoading}
            />
            <WizardTextEditor
              onChangeText={setDiscoveryEditorText}
              text={discovery?.discoveryText}
              step={currentStep}
              loading={AILoading}
            />
          </>
        )
      case 1:
        return (
          <>
            <WizardCompetitiveAnalysis
              messagingId={project?.messaging?.id}
              onProgressUpdate={setProgress}
              nextStep={goToNextStep}
              AILoading={setAILoading}
            />
            <WizardTextEditor
              onChangeText={handleUpdateFirstCompetitorText}
              text={competitiveAnalysis?.competitorsText}
              step={currentStep}
              loading={AILoading}
            />
          </>
        )
      case 2:
        return (
          <>
            <WizardMessagingEngine
              messagingId={project?.messaging?.id}
              nextStep={goToNextStep}
              onProgressUpdate={setProgress}
              businessUnitId={project?.businessUnit?.id}
              AILoading={setAILoading}
            />
            <WizardTextEditor loading={AILoading} step={currentStep} />
          </>
        )
      default:
        return null
    }
  }

  return (
    <main className={styles.wizardContainer}>
      <WizardHeader
        clientName={project?.businessUnit?.client?.name}
        businessUnitName={project?.businessUnit?.name}
        project={project}
        businessUnitId={project?.businessUnit?.id}
      />
      <hr className={styles.hrBorder} />
      <div className={styles.midContainer}>
        <div className={styles.stepperContainer}>
          <WizardStepper currentStep={currentStep} onStepChange={setCurrentStep} percent={progress} />
        </div>
        <div className={styles.buttonsContainer}>
          <GenericButton isDisabled={currentStep === 0} title="Back" secondary onClick={goToPreviousStep} />
          <GenericButton isDisabled={currentStep === 2} title="Next" onClick={goToNextStep} />
        </div>
      </div>
      <div className={styles.bottomContainer}>{renderStepContent()}</div>
    </main>
  )
}
export default Wizard
