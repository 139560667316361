import { apiEndpoints } from '../constants/api-endpoints'
import { IFile } from '../models/interfaces/file.interface'
import { apiRequest } from '../utils/api-utils/api'

export const uploadFileService = async (formData: FormData) => {
  return await apiRequest('POST', apiEndpoints.UPLOAD.UPLOAD_TO_STORAGE, formData)
}

export const SaveFileToDBService = async (file: IFile) => {
  return await apiRequest('POST', apiEndpoints.FILES.CREATE_FILE, file)
}
