import { FC, useEffect, useState } from 'react'

import { CloseIcon } from '../../../../../assets/icons'
import { strings } from '../../../../../models/consts/strings'
import { IUser } from '../../../../../models/interfaces/users.interface'
import { updateProjectThunk } from '../../../../../store/slices/wizard.slice'
import { useAppDispatch } from '../../../../../store/store'
import { UserOption } from '../../../../CustomModal/ModalInput/UserOption/UserOption'
import { ModalDropdown } from '../../../../ModalDropdown/ModalDropdown'
import { handleFetchUsers } from '../../../../ProjectModal/project-modal.utils'
import { WizardSearch } from '../../WizardSearch/WizardSearch'
import styles from './MembersModal.module.scss'

interface IMembersModalProps {
  onClose: () => void
  connectedUser: IUser
  onUpdateMembersCount: (count: number) => void
  projectId?: string
  projectMembers?: IUser[]
}
const MembersModal: FC<IMembersModalProps> = ({
  onUpdateMembersCount,
  onClose,
  connectedUser,
  projectId,
  projectMembers,
}) => {
  const dispatch = useAppDispatch()

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(projectMembers || [])
  const [users, setUsers] = useState<IUser[]>([])
  const membersAssigned = selectedUsers.length + 1

  useEffect(() => {
    handleFetchUsers(setUsers)
  }, [])

  const updateSelectedUsers = (updatedUsers: IUser[]) => {
    setSelectedUsers(updatedUsers)
    onUpdateMembersCount(updatedUsers.length + 1)
    const userIds = updatedUsers.map((user) => user.id)
    if (projectId) {
      dispatch(
        updateProjectThunk({
          projectId,
          payload: { userIds },
        }),
      ).catch((error) => {
        console.error('Error updating project users:', error)
      })
    }
  }

  const handleSelectUser = (user: IUser) => {
    if (!selectedUsers.some((selectedUser) => selectedUser.id === user.id)) {
      const updatedUsers = [...selectedUsers, user]
      updateSelectedUsers(updatedUsers)
    }
  }

  const handleRemoveUser = (userId: string) => {
    const updatedUsers = selectedUsers.filter((user) => user.id !== userId)
    updateSelectedUsers(updatedUsers)
  }

  const filteredUsers = users.filter((user) => !selectedUsers.some((selectedUser) => selectedUser.id === user.id))

  return (
    <ModalDropdown>
      <div className={styles.modalContainer}>
        <div className={styles.topContainer}>
          <div className={styles.titles}>
            <h2 className={styles.title}>{strings.WIZARD.HEADER.MEMBERS_TAB.TITLE}</h2>
            <h3 className={styles.secondTitle}>
              {membersAssigned} {membersAssigned > 1 ? 'members assigned' : 'member assigned'}
            </h3>
          </div>
          <div className={styles.buttonBackground}>
            <CloseIcon className={styles.closeButton} onClick={onClose} />
          </div>
        </div>
        <div className={styles.midContainer}>
          <WizardSearch users={filteredUsers} onSelectUser={handleSelectUser} />
        </div>
        <div className={styles.bottomContainer}>
          <UserOption isMeTag user={connectedUser} />
          {selectedUsers.map((user) => (
            <div className={styles.userTag} key={user.id}>
              <UserOption user={user} />
              <CloseIcon className={styles.closeIcon} onClick={() => handleRemoveUser(user.id)} />
            </div>
          ))}
        </div>
      </div>
    </ModalDropdown>
  )
}

export { MembersModal }
