import { apiEndpoints } from '../constants/api-endpoints'
import { ItextEditingAI } from '../models/interfaces/text-editing-ai.interface'
import { apiRequest } from '../utils/api-utils/api'

interface IAIResponse {
  result: string
}

const textEditingAIService = async (textInfo: ItextEditingAI): Promise<IAIResponse> => {
  return await apiRequest('POST', apiEndpoints.AI_ENDPOINTS.TEXT_EDITING, textInfo)
}

const addParagraphAIService = async (textInfo: ItextEditingAI): Promise<IAIResponse> => {
  return await apiRequest('POST', apiEndpoints.AI_ENDPOINTS.ADD_PARAGRAPH, textInfo)
}

export { textEditingAIService, addParagraphAIService }
