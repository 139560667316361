import { Avatar } from 'antd'
import { FC } from 'react'

import { IUser } from '../../../../models/interfaces/users.interface'
import styles from './UserOption.module.scss'

interface IUserOptionProps {
  user: Partial<IUser>
  size?: number
  iconClassName?: string
  textClassName?: string
  onClick?: () => void
  isMeTag?: boolean
}

const UserOption: FC<IUserOptionProps> = ({ isMeTag, onClick, user, iconClassName, textClassName, size }) => {
  const firstInitial = user.firstName?.[0] || ''
  const lastInitial = user.lastName?.[0] || ''

  return (
    <div onClick={onClick} className={styles.userOptionContainer}>
      <Avatar size={size} className={iconClassName || styles.avatarUser}>
        {firstInitial}
        {lastInitial}
      </Avatar>
      <span className={textClassName || styles.avatarUserName}>
        {user.firstName} {user.lastName} {isMeTag && <span className={styles.meTag}>(Me)</span>}
      </span>
    </div>
  )
}

export { UserOption }
