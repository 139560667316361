import classNames from 'classnames'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { sizes } from '../../../models/consts/sizes'
import { IUser } from '../../../models/interfaces/users.interface'
import { UserOption } from '../../CustomModal/ModalInput/UserOption/UserOption'
import styles from './SidebarLink.module.scss'

interface ISidebarLinkProps {
  path: string
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  altText?: string
  className?: string
  isExpanded: boolean
  selectedTab: string
  onTabSelect: (tabPath: string) => void
  isButton?: boolean
  onButtonClick?: () => void
  isStatic: boolean
  user?: IUser
}

const SidebarLink: FC<ISidebarLinkProps> = ({
  path,
  Icon,
  altText,
  className,
  isExpanded,
  selectedTab,
  onTabSelect,
  isButton = false,
  onButtonClick,
  isStatic = false,
  user,
}) => {
  const handleClick = () => {
    if (isButton && onButtonClick) {
      onButtonClick()
    } else if (!isButton && !isStatic) {
      onTabSelect(path)
    }
  }
  const isSelected = selectedTab === path

  const sharedClassNames = classNames(styles.navLink, className, {
    [styles.expanded]: isExpanded,
    [styles.selected]: isSelected,
    [styles.userOption]: !!user,
  })

  const renderContent = (iconClassName: string) => (
    <div className={styles.iconTextContainer}>
      {Icon && <Icon className={iconClassName} aria-label={altText} />}
      <span
        className={classNames(styles.linkText, {
          [styles.hidden]: !isExpanded,
        })}
      >
        {altText}
      </span>
    </div>
  )

  if (user) {
    return (
      <div className={sharedClassNames}>
        <UserOption size={sizes.userAvatar} user={user} iconClassName={styles.icon} textClassName={styles.linkText} />
      </div>
    )
  }

  if (isStatic) {
    return (
      <div className={sharedClassNames} aria-label={altText}>
        {renderContent(styles.staticLogo)}
      </div>
    )
  }

  if (isButton) {
    return (
      <button className={sharedClassNames} onClick={handleClick} aria-label={altText}>
        {renderContent(styles.icon)}
      </button>
    )
  }

  return (
    <NavLink to={path} className={sharedClassNames} onClick={handleClick}>
      {renderContent(styles.icon)}
    </NavLink>
  )
}

export default SidebarLink
