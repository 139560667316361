import { Dropdown, MenuProps } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import React, { ReactNode } from 'react'

import { MenuIcon } from '../../../assets/icons'
import { ECommonColumns } from '../../../models/enums/columns.enum'
import { formatRelativeDate } from '../../../utils/format-date.util'
import { StatusBox } from '../../StatusBox/StatusBox'
import styles from '../ListView.module.scss'

interface ITableRowProps<T, U> {
  row: T
  columns: U[]
  columnKeyMap: Record<string, (row: T) => React.ReactNode>
  onClick?: () => void
  menuButton?: ReactNode
  dropdownItems?: MenuProps['items']
  onMenuClick?: (row: T, info: MenuInfo) => void
}

const renderCellContent = (column: ECommonColumns, value: any): React.ReactNode => {
  switch (column) {
    case ECommonColumns.STATUS:
      return <StatusBox status={value} className={styles.statusBox} />
    case ECommonColumns.LAST_UPDATED:
      return formatRelativeDate(value)
    default:
      return value ?? 'N/A'
  }
}

const ListViewRow = <T, U extends string>({
  row,
  columns,
  columnKeyMap,
  onClick,
  menuButton,
  dropdownItems,
  onMenuClick,
}: ITableRowProps<T, U>): JSX.Element => {
  return (
    <tr onClick={onClick}>
      {columns.map((column) => (
        <td key={column}>{renderCellContent(column as ECommonColumns, columnKeyMap[column](row))}</td>
      ))}
      {menuButton && (
        <Dropdown
          className={styles.menuDropDown}
          menu={{
            items: dropdownItems,
            onClick: (info) => {
              if (onMenuClick) {
                onMenuClick(row, info)
              }
            },
          }}
          trigger={['click']}
          placement="bottomLeft"
        >
          <MenuIcon className={styles.menuIcon} />
        </Dropdown>
      )}
    </tr>
  )
}

export { ListViewRow }
