const getParagraphBefore = (text: string, pos: number): string => {
  let textBefore = text.slice(0, pos).trimEnd()
  if (!textBefore) return ''

  const paragraphs = textBefore
    .split(/\n{2,}/)
    .map((p) => p.trim())
    .filter(Boolean)
  return paragraphs.length > 0 ? paragraphs[paragraphs.length - 1] : ''
}

const getParagraphAfter = (text: string, pos: number): string => {
  let textAfter = text.slice(pos).trimStart()
  if (!textAfter) return ''

  const paragraphs = textAfter
    .split(/\n{2,}/)
    .map((p) => p.trim())
    .filter(Boolean)
  return paragraphs.length > 0 ? paragraphs[0] : ''
}

export { getParagraphBefore, getParagraphAfter }
