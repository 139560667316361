import { FC } from 'react'

import { SearchIcon } from '../../assets/icons'
import styles from './SearchInput.module.scss'

interface SearchInputProps {
  onSearch: (value: string) => void
  placeholder?: string
  width?: string
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, width, onSearch }) => {
  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(value)
  }
  return (
    <div style={{ width: width || '100%' }} className={styles.searchContainer}>
      <SearchIcon />
      <input
        onChange={handleInputChange}
        className={styles.searchInput}
        type="text"
        placeholder={placeholder ? placeholder : 'Search'}
      />
    </div>
  )
}

export { SearchInput }
