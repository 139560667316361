import { Modal } from 'antd'
import { FC, ReactNode } from 'react'

import './CustomModal.scss'

interface ICustomModalProps {
  isOpen: boolean
  onCancel: () => void
  footer?: ReactNode
  className?: string
  children: ReactNode
}

const CustomModal: FC<ICustomModalProps> = ({ isOpen, onCancel, footer = null, className = '', children }) => {
  return (
    <Modal open={isOpen} onCancel={onCancel} footer={footer} className={`custom-modal ${className}`}>
      {children}
    </Modal>
  )
}

export { CustomModal }
