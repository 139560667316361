import { Dropdown, MenuProps, message } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BackIcon, DownArrowIcon, ExportIcon, MenuIcon, TrashRedIcon } from '../../../assets/icons'
import { strings } from '../../../models/consts/strings'
import { EStatusEnum } from '../../../models/enums/status.enum'
import { EWizardTabs } from '../../../models/enums/wizard-tabs.enum'
import { IGuideline } from '../../../models/interfaces/guidelines.interface'
import { IProject } from '../../../models/interfaces/project.interface'
import { IUser } from '../../../models/interfaces/users.interface'
import { deleteProjectThunk } from '../../../store/slices/business-unit.slice'
import { updateProjectThunk } from '../../../store/slices/wizard.slice'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import ConfirmModal from '../../ConfirmModal/ConfirmModal'
import { StatusBox } from '../../StatusBox/StatusBox'
import { EditableNameField } from './EditableNameField/EditableNameField'
import { BrandModal } from './WizardGenericModal/BrandModal/BrandModal'
import { DetailsModal } from './WizardGenericModal/DetailsModal/DetailsModal'
import { MembersModal } from './WizardGenericModal/MembersModal/MembersModal'
import { ToneOfVoiceModal } from './WizardGenericModal/ToneOfVoiceModal/ToneOfVoiceModal'
import styles from './WizardHeader.module.scss'
import { WizardTab } from './WizardTab/WizardTab'
import { handleSetGuideline, tabs } from './wizard-header.utils'

interface IWizardHeaderProps {
  clientName?: string
  businessUnitName?: string
  businessUnitId?: string
  guidelineId?: string
  project?: IProject
}

const WizardHeader: FC<IWizardHeaderProps> = ({ clientName, businessUnitName, businessUnitId, project }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)

  const [businessUnitGuidelines, setBusinessUnitGuidelines] = useState<IGuideline | null>(null)
  const [activeModal, setActiveModal] = useState<EWizardTabs | null>(null)
  const [membersCount, setMembersCount] = useState<number>(project?.users?.length || 1)
  const [isStatusHovered, setIsStatusHovered] = useState(false)
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false)
  const [deleteProjectModal, setDeleteProjectModal] = useState(false)

  useEffect(() => {
    if (!businessUnitId) return
    handleSetGuideline(setBusinessUnitGuidelines, businessUnitId)
  }, [businessUnitId])

  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1)
  }

  const generateStatusItems = (): MenuProps['items'] =>
    Object.values(EStatusEnum)
      .slice(0, 4)
      .map((status) => ({
        key: status,
        label: <StatusBox status={status} />,
      }))

  const handleUpdateName = (newName: string) => {
    if (project) {
      dispatch(
        updateProjectThunk({
          projectId: project?.id,
          payload: { name: newName },
        }),
      )
    }
  }

  const handleStatusChange = (selectedKey: string) => {
    const newStatus = selectedKey as EStatusEnum
    if (project) {
      dispatch(
        updateProjectThunk({
          projectId: project.id,
          payload: { status: newStatus },
        }),
      )
    }
  }

  const handleDeleteProjectModalOk = async () => {
    if (project) {
      try {
        await dispatch(deleteProjectThunk({ projectId: project.id })).unwrap()
        message.success('Project deleted successfully')
        navigate(-1)
        setDeleteProjectModal(false)
      } catch (error) {
        console.error('Error deleting project:', error)
        message.error('Error: Something went wrong while deleting the project')
      }
    }
  }

  const handleDropdownClick = ({ key }: { key: string }) => {
    if (key === 'export') {
      message.info('Export functionality not implemented yet.')
    } else if (key === 'delete') {
      setDeleteProjectModal(true)
    }
  }

  const dropDownItems: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <div className={styles.dropdownItem}>
          <ExportIcon className={styles.dropdownIcon} />
          {strings.WIZARD.HEADER.DROPDOWN.EXPORT}
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div className={styles.dropdownItem}>
          <TrashRedIcon className={styles.dropdownIcon} />
          {strings.WIZARD.HEADER.DROPDOWN.DELETE_PROJECT}
        </div>
      ),
    },
  ]

  return (
    <div className={styles.wizardHeader}>
      <div className={styles.backButton} onClick={handleBackClick}>
        <BackIcon />
        <span>{strings.WIZARD.HEADER.BACK_BUTTON}</span>
      </div>
      {/*Client, BU, Project Names Section */}
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          <div className={styles.names}>
            <span className={styles.clientTab}>{`${clientName} /`}</span>
            <span className={styles.businessUnitTab}>{` ${businessUnitName} /`}</span>
            {/* Implement a function to edit project name, and update on DB*/}
            <EditableNameField fieldName={project?.name} onUpdateName={(newName) => handleUpdateName(newName)} />
          </div>
          <Dropdown
            className={styles.statusDropDown}
            menu={{
              items: generateStatusItems(),
              onClick: ({ key }) => handleStatusChange(key),
            }}
            trigger={['click']}
            placement="bottom"
            open={isStatusDropDownOpen}
            onOpenChange={(open) => setIsStatusDropDownOpen(open)}
          >
            <div
              className={styles.statusContainer}
              onMouseEnter={() => setIsStatusHovered(true)}
              onMouseLeave={() => setIsStatusHovered(false)}
            >
              {/* Implement a function to edit project status, and update on DB*/}
              <StatusBox className={styles.statusBox} status={project?.status} />
              {(isStatusHovered || isStatusDropDownOpen) && (
                <span className={styles.dropdownIcon}>
                  <DownArrowIcon className={styles.downArrowIcon} />
                </span>
              )}
            </div>
          </Dropdown>
        </div>
        {/*Right Tabs Section */}
        <div className={styles.tabs}>
          {tabs.map(({ id, title, Icon, modal }) => (
            <WizardTab
              onClick={() => setActiveModal((prev) => (prev === modal ? null : modal))}
              key={id}
              Icon={Icon}
              title={title}
            />
          ))}

          <div
            className={styles.membersTab}
            onClick={() => setActiveModal((prev) => (prev === EWizardTabs.MEMBERS ? null : EWizardTabs.MEMBERS))}
          >
            <span className={styles.tab}>{`${strings.WIZARD.HEADER.TABS.MEMBERS} /`}</span>
            <span className={styles.tab}>{membersCount}</span>
          </div>
          {/*MenuIcon Section */}
          <Dropdown
            className={styles.menuDropDown}
            menu={{ items: dropDownItems, onClick: handleDropdownClick }}
            trigger={['click']}
            placement="bottomRight"
          >
            <MenuIcon className={styles.menuIcon} />
          </Dropdown>
        </div>
        <div className={styles.modalsContainer}>
          {/*Details Modal Section */}
          {activeModal === EWizardTabs.DETAILS && (
            <DetailsModal
              website={businessUnitGuidelines?.website ?? ''}
              parentWebsite={businessUnitGuidelines?.parentWebsite ?? ''}
              generalInformation={businessUnitGuidelines?.generalInformation}
              onClose={() => setActiveModal(null)}
            />
          )}
          {/*Brand Modal Section */}
          {activeModal === EWizardTabs.BRAND && (
            <BrandModal
              onClose={() => setActiveModal(null)}
              //TODO: need to fix logos and font import to files service call.
              // logos={businessUnitGuidelines?.logos}
              // font={businessUnitGuidelines?.font}
              colors={businessUnitGuidelines?.colors}
            />
          )}
          {/*Tone of Voice Modal Section */}
          {activeModal === EWizardTabs.TONE_OF_VOICE && (
            <ToneOfVoiceModal
              toneOfVoice={project?.toneOfVoice || businessUnitGuidelines?.toneOfVoice}
              onClose={() => setActiveModal(null)}
              projectId={project?.id}
            />
          )}
          {/*Members Modal Section */}
          {activeModal === EWizardTabs.MEMBERS && (
            <MembersModal
              onUpdateMembersCount={(count) => setMembersCount(count)}
              connectedUser={user}
              onClose={() => setActiveModal(null)}
              projectId={project?.id}
              projectMembers={project?.users as IUser[]}
            />
          )}
        </div>
        <ConfirmModal
          isOpen={deleteProjectModal}
          onOk={handleDeleteProjectModalOk}
          onCancel={() => setDeleteProjectModal(false)}
          isDangerButton
          primaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE1}
          secondaryTitle={strings.BUSINESS_UNIT_PAGE.DELETE_MODAL.TITLE2}
          confirmButtonTItle={strings.GENERAL_LABELS.DELETE_NOW}
        />
      </div>
    </div>
  )
}
export { WizardHeader }
