import { apiEndpoints } from '../constants/api-endpoints'
import { PAGINATION_CONFIG } from '../models/consts/pagination-config'
import { apiRequest } from '../utils/api-utils/api'

const fetchBusinessUnitProjectsService = async (
  businessUnitId: string,
  page: number = PAGINATION_CONFIG.DEFAULT_PAGE,
  limit: number = PAGINATION_CONFIG.DEFAULT_LIMIT,
  sort?: { field: string; order: 'ASC' | 'DESC' },
  filter?: { field: string; value: string }[],
  search?: string,
) => {
  const payload = {
    page,
    limit,
    sort,
    filter: [{ field: 'businessUnitId', value: businessUnitId }],
    search,
  }
  const response = await apiRequest('POST', apiEndpoints.PROJECTS.GET_PROJECTS, payload)
  const businessUnitProjects = response.data.projects
  return {
    businessUnitProjects,
    meta: response.data.meta,
  }
}

const deleteProjectService = async (id: string) => {
  const payload = {
    id,
  }
  const response = await apiRequest('DELETE', apiEndpoints.PROJECTS.DELETE_PROJECT, payload)
  return response
}

const fetchBusinessUnitByIdService = async (businessUnitId: string) => {
  const response = await apiRequest('GET', apiEndpoints.BUSINESS_UNITS.GET_BUSINESS_UNIT_ById(businessUnitId))
  return response.data
}
export { fetchBusinessUnitProjectsService, deleteProjectService, fetchBusinessUnitByIdService }
