import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ListView } from '../../components/ListView/ListView'
import { ListViewContainer } from '../../components/ListViewContainer/ListViewContainer'
import { NewsScroller } from '../../components/News/NewsScroller/NewsScroller'
import { Pagination } from '../../components/Pagination/Pagination'
import { ProjectModal } from '../../components/ProjectModal/ProjectModal'
import { projectColumnKeyMap } from '../../models/consts/column-kep-map'
import { PAGINATION_CONFIG } from '../../models/consts/pagination-config'
import { strings } from '../../models/consts/strings'
import { EAppRoutes } from '../../models/enums/app-routes.enum'
import { ECommonColumns } from '../../models/enums/columns.enum'
import { EComponentType } from '../../models/enums/componentType.enum'
import { IProject } from '../../models/interfaces/project.interface'
import { fetchNews } from '../../store/slices/news.slice'
import { fetchProjectsThunk } from '../../store/slices/user-projects.slice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import styles from './CommandCenter.module.scss'

const CommandCenter: FC = () => {
  const isStaging = process.env.REACT_APP_ENV === 'stg'
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projects, isProjectsLoading, pagesCount } = useAppSelector((state) => state.projects)
  const { news, isNewsLoading, newsError } = useAppSelector((state) => state.news)
  const userId = useAppSelector((state) => state.user.id)

  const [currentPage, setCurrentPage] = useState<number>(PAGINATION_CONFIG.DEFAULT_PAGE)
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (userId) {
      dispatch(fetchProjectsThunk({ userId }))
      dispatch(fetchNews())
    }
  }, [dispatch, userId])

  const handlePageChange = (newPage: number) => {
    if (userId) {
      setCurrentPage(newPage)
      dispatch(
        fetchProjectsThunk({
          userId,
          page: newPage,
        }),
      )
    }
  }

  const handleRowClick = (project: IProject) => {
    if (isStaging) {
      return
    }
    navigate(`${EAppRoutes.WIZARD}/${project.id}`)
  }

  const handleModalOk = () => {
    setCurrentPage(PAGINATION_CONFIG.DEFAULT_PAGE)
    dispatch(fetchProjectsThunk({ userId, page: PAGINATION_CONFIG.DEFAULT_PAGE }))
    setOpenModal(false)
  }

  const projectColumns = [
    ECommonColumns.CLIENT_NAME,
    ECommonColumns.MESSAGING_PROJECT_NAME,
    ECommonColumns.STATUS,
    ECommonColumns.LAST_UPDATED,
  ]
  return (
    <main className={styles.commandCenterContainer}>
      <h1 className={styles.title}>Command Center</h1>
      <div className={styles.wrapper}>
        <div className={styles.secondWrapper}>
          <ListViewContainer
            title={strings.COMMAND_CENTER.TITLES.DATA_LIST}
            isButton={true}
            componentType={EComponentType.COMMAND_CENTER}
            ModalComponent={ProjectModal}
            buttonTitle={strings.COMMAND_CENTER.TITLES.NEW_PROJECT}
            onModalOk={handleModalOk}
          >
            <ListView
              onRowClick={handleRowClick}
              columns={projectColumns}
              data={projects}
              columnKeyMap={projectColumnKeyMap}
              componentType={EComponentType.COMMAND_CENTER}
              isLoading={isProjectsLoading}
              emptyMsg={strings.EMPTY_CASES.EMPTY_PROJECTS}
              buttonTitle={strings.COMMAND_CENTER.TITLES.NEW_PROJECT}
              setModalOpen={() => setOpenModal(true)}
            />
          </ListViewContainer>
          {pagesCount > 1 && (
            <Pagination
              componentType={EComponentType.COMMAND_CENTER}
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
        <NewsScroller
          isLoading={isNewsLoading}
          error={newsError}
          news={news}
          title={strings.COMMAND_CENTER.TITLES.NEWS_SCROLLER}
        />
      </div>
      <ProjectModal isOpen={openModal} onOk={handleModalOk} onCancel={() => setOpenModal(false)} />
    </main>
  )
}
export { CommandCenter }
