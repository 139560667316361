import classNames from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'

import { strings } from '../../../../models/consts/strings'
import { IUser } from '../../../../models/interfaces/users.interface'
import { UserOption } from '../../../CustomModal/ModalInput/UserOption/UserOption'
import styles from './WizardSearch.module.scss'

interface WizardSearchProps {
  users: IUser[]
  onSelectUser: (user: IUser) => void
}

const WizardSearch: FC<WizardSearchProps> = ({ onSelectUser, users }) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const searchContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropDown = () => {
    setShowDropdown((prev) => !prev)
  }

  const filteredUsers = users.filter((user) =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchValue.toLowerCase()),
  )

  return (
    <div ref={searchContainerRef} className={styles.searchContainer} onClick={toggleDropDown}>
      <input
        className={styles.searchInput}
        value={searchValue}
        placeholder={strings.WIZARD.HEADER.MEMBERS_TAB.SEARCH_PLACEHOLDER}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div className={classNames(styles.dropdown, { [styles.show]: showDropdown })}>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user: IUser) => (
            <div className={styles.userOption} key={user.id}>
              <UserOption onClick={() => onSelectUser(user)} user={user} />
            </div>
          ))
        ) : (
          <div className={styles.noResults}>No members found</div>
        )}
      </div>
    </div>
  )
}

export { WizardSearch }
