import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { FC, ReactNode } from 'react'

import { CloseIcon, InfoIcon } from '../../../../assets/icons'
import { ModalDropdown } from '../../../ModalDropdown/ModalDropdown'
import styles from './WizardGenericModal.module.scss'

interface IWizardModalProps {
  tab?: ReactNode
  children: ReactNode
  onClose: () => void
  toolTip?: { title: string; direction: TooltipPlacement }
}
const WizardGenericModal: FC<IWizardModalProps> = ({ tab, children, onClose, toolTip }) => {
  return (
    <ModalDropdown>
      <div className={styles.topContainer}>
        <div className={styles.tabName}>
          {tab}
          {toolTip && (
            <Tooltip placement={toolTip.direction} title={toolTip.title}>
              <InfoIcon />
            </Tooltip>
          )}
        </div>
        <div className={styles.buttonBackground}>
          <CloseIcon className={styles.closeButton} onClick={onClose} />
        </div>
      </div>
      <hr className={styles.hrLine} />
      {children}
    </ModalDropdown>
  )
}

export { WizardGenericModal }
