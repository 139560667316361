import { FC } from 'react'
import styles from './WizardInput.module.scss'

interface IWizardInputProps {
  name?: string
  value: string | string[]
  placeholder: string | undefined
  type?: string
  className?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const WizardInput: FC<IWizardInputProps> = ({ name, value, placeholder, type = 'text', className, onChange }) => {
  return (
    <input
      name={name}
      value={value}
      placeholder={placeholder}
      type={type}
      className={`${styles.wizardInput} ${className || ''}`}
      onChange={onChange}
    />
  )
}

export default WizardInput
