import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ICompetitiveAnalysis } from '../../models/interfaces/competitor-analysis.interface'
import { IDiscovery } from '../../models/interfaces/discovery.interface'
import { IGuideline } from '../../models/interfaces/guidelines.interface'
import { IMessagingEngine } from '../../models/interfaces/messaging-engine.interface'
import { IProject } from '../../models/interfaces/project.interface'
import { IUpdateProjectPayload } from '../../models/interfaces/update-project.interface'
import {
  createOrUpdateCompetitiveAnalysisService,
  createOrUpdateDiscoveryService,
  createOrUpdateMessagingEngineService,
  fetchProjectService,
  getCompetitiveAnalysisService,
  getDiscoveryService,
  getMessagingEngineService,
  updateCompetitiveAnalysisTextTextService,
  updateDiscoveryTextService,
  updateProjectService,
} from '../../services/wizard-service'

interface WizardState {
  data: {
    guideline: IGuideline | undefined
    discovery: IDiscovery | undefined
    competitiveAnalysis: ICompetitiveAnalysis | undefined
    messagingEngine: IMessagingEngine | undefined
    project?: IProject | undefined
  }
  loading: boolean
  error: string | null
}

const initialState: WizardState = {
  data: {
    guideline: undefined,
    discovery: undefined,
    competitiveAnalysis: undefined,
    messagingEngine: undefined,
    project: undefined,
  },
  loading: false,
  error: null,
}

export const fetchProjectThunk = createAsyncThunk<IProject, { projectId: string }>(
  'wizard/fetchProject',
  async ({ projectId }) => {
    return await fetchProjectService(projectId)
  },
)

export const updateProjectThunk = createAsyncThunk<IProject, { projectId: string; payload: IUpdateProjectPayload }>(
  'wizard/updateProject',
  async ({ projectId, payload }) => {
    return await updateProjectService(projectId, payload)
  },
)

export const getDiscoveryThunk = createAsyncThunk<
  IDiscovery,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchDiscovery', async ({ messagingId }) => {
  return await getDiscoveryService(messagingId)
})

export const updateDiscoveryTextThunk = createAsyncThunk<
  void,
  { discoveryId: string; discoveryText: string },
  { rejectValue: { status: number; message: string } }
>('wizard/updateDiscoveryText', async ({ discoveryId, discoveryText }) => {
  return await updateDiscoveryTextService(discoveryId, discoveryText)
})

export const updateCompetitiveAnalysisTextThunk = createAsyncThunk<
  void,
  { competitiveAnalysisId: string; index: number; text: string },
  { rejectValue: { status: number; message: string } }
>('wizard/updateCompetitiveAnalysisText', async ({ competitiveAnalysisId, index, text }) => {
  return await updateCompetitiveAnalysisTextTextService(competitiveAnalysisId, index, text)
})

export const getCompetitiveAnalysisThunk = createAsyncThunk<
  ICompetitiveAnalysis,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchCompetitiveAnalysis', async ({ messagingId }) => {
  return await getCompetitiveAnalysisService(messagingId)
})

export const getMessagingEngineThunk = createAsyncThunk<
  IMessagingEngine,
  { messagingId: string },
  { rejectValue: { status: number; message: string } }
>('wizard/fetchMessagingEngine', async ({ messagingId }) => {
  return await getMessagingEngineService(messagingId)
})

export const createOrUpdateDiscovery = createAsyncThunk<
  void,
  { discoveryData: Partial<IDiscovery> },
  { rejectValue: { status: number; message: string } }
>('wizard/saveDiscovery', async ({ discoveryData }) => {
  return await createOrUpdateDiscoveryService(discoveryData)
})

export const createOrUpdateCompetitiveAnalysis = createAsyncThunk<
  void,
  { competitiveAnalysisData: Partial<ICompetitiveAnalysis> },
  { rejectValue: { status: number; message: string } }
>('wizard/saveCompetitiveAnalysis', async ({ competitiveAnalysisData }) => {
  return await createOrUpdateCompetitiveAnalysisService(competitiveAnalysisData)
})

export const createOrUpdateMessagingEngine = createAsyncThunk<
  void,
  { messagingEngineData: Partial<IMessagingEngine> },
  { rejectValue: { status: number; message: string } }
>('wizard/saveMessagingEngine', async ({ messagingEngineData }) => {
  return await createOrUpdateMessagingEngineService(messagingEngineData)
})

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    updateDiscovery(state, action: PayloadAction<Partial<IDiscovery>>) {
      if (state.data?.discovery) {
        state.data.discovery = {
          ...state.data.discovery,
          ...action.payload,
        }
      } else {
        state.data.discovery = action.payload as IDiscovery
      }
    },
    updateCompetitiveAnalysis(state, action: PayloadAction<Partial<ICompetitiveAnalysis>>) {
      if (state.data?.competitiveAnalysis) {
        state.data.competitiveAnalysis = {
          ...state.data.competitiveAnalysis,
          ...action.payload,
        }
      } else {
        state.data.competitiveAnalysis = action.payload as ICompetitiveAnalysis
      }
    },
    updateMessagingEngine(state, action: PayloadAction<Partial<IMessagingEngine>>) {
      if (state.data?.messagingEngine) {
        state.data.messagingEngine = {
          ...state.data.messagingEngine,
          ...action.payload,
        }
      } else {
        state.data = {
          ...state.data,
          messagingEngine: action.payload as IMessagingEngine,
        }
      }
    },
    updateGuideline(state, action: PayloadAction<Partial<IGuideline>>) {
      if (state.data?.guideline) {
        state.data.guideline = { ...state.data.guideline, ...action.payload }
      }
    },
    updateProject(state, action: PayloadAction<Partial<IProject>>) {
      if (state.data) {
        state.data = { ...state.data, ...action.payload }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchProjectThunk.fulfilled, (state, action: PayloadAction<IProject>) => {
        state.data = {
          ...state.data,
          project: action.payload,
        }
        state.loading = false
      })
      .addCase(fetchProjectThunk.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(getDiscoveryThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getDiscoveryThunk.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          discovery: action.payload,
        }
        state.loading = false
      })
      .addCase(getDiscoveryThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getCompetitiveAnalysisThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getCompetitiveAnalysisThunk.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          competitiveAnalysis: action.payload,
        }
        state.loading = false
      })
      .addCase(getCompetitiveAnalysisThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getMessagingEngineThunk.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getMessagingEngineThunk.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          messagingEngine: action.payload,
        }
        state.loading = false
      })
      .addCase(getMessagingEngineThunk.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(updateProjectThunk.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateProjectThunk.fulfilled, (state, action) => {
        const { name, status, toneOfVoice, users } = action.payload
        if (state.data.project) {
          state.data.project.name = name
          state.data.project.status = status
          state.data.project.toneOfVoice = toneOfVoice
          state.data.project.users = users
        }
        state.loading = false
      })
      .addCase(updateProjectThunk.rejected, (state) => {
        state.loading = false
      })
  },
})
export const { updateMessagingEngine, updateCompetitiveAnalysis, updateDiscovery, updateProject } = wizardSlice.actions
export default wizardSlice.reducer
