import { FunctionComponent } from 'react'

import { fetchNews } from '../../../store/slices/news.slice'
import { useAppDispatch } from '../../../store/store'
import { Loader } from '../../Loader/Loader'
import { OfflineMsg } from '../../OfflineMsg/OfflineMsg'
import { RefreshButton } from '../../RefreshButton/RefreshButton'
import { NewsListItem } from '../NewsListItem/NewsListItem'
import styles from './NewsScroller.module.scss'
import { INews } from '../../../models/interfaces/news.interface'

interface INewsScrollerProps {
  title: string
  news: INews[]
  isLoading: boolean
  error: string | null
}

const NewsScroller: FunctionComponent<INewsScrollerProps> = ({ title, news, isLoading, error }) => {
  const dispatch = useAppDispatch()

  const handleRefresh = () => {
    dispatch(fetchNews())
  }

  return (
    <div className={styles.newsScroller}>
      <div className={styles.newsTopContainer}>
        <h2 className={styles.newsScrollerTitle}>{title}</h2>
        <RefreshButton onClick={handleRefresh} />
      </div>
      <div className={styles.newsBox}>
        {isLoading ? <Loader /> : !!news?.length ? <NewsListItem news={news} /> : <OfflineMsg />}
      </div>
    </div>
  )
}

export { NewsScroller }
