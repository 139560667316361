enum EFileReferenceType {
  GUIDELINE = 'GUIDELINE',
  DISCOVERY = 'DISCOVERY',
  MESSAGING_ENGINE = 'MESSAGING_ENGINE',
}

enum EFileSubReferenceType {
  CLIENT_REFERENCE = 'CLIENT_REFERENCE',
  DISCOVERY_SUMMARY = 'DISCOVERY_SUMMARY',
  CUSTOMER_CASE_STUDIES = 'CUSTOMER_CASE_STUDIES',
  PROOF = 'PROOF',
  ADDITIONAL_FILES = 'ADDITIONAL_FILES',
  FONT = 'FONT',
  LOGO = 'LOGO',
  FILE = 'FILE',
}

export { EFileReferenceType, EFileSubReferenceType }
