import React, { FC, useEffect, useState } from 'react'
import styles from './EditableNameField.module.scss'

interface EditableNameFieldProps {
  fieldName?: string
  onUpdateName?: (newName: string) => void
}

const EditableNameField: FC<EditableNameFieldProps> = ({ fieldName = '', onUpdateName }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [tempName, setTempName] = useState<string>(fieldName)

  useEffect(() => {
    if (fieldName) {
      setTempName(fieldName)
    }
  }, [fieldName])

  const handleSpanClick = () => {
    setIsEditing(true)
  }

  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setTempName(value)
  }

  const handleBlur = () => {
    if (tempName !== fieldName && onUpdateName) {
      onUpdateName(tempName)
    }
    setIsEditing(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onUpdateName) {
      onUpdateName(tempName)
      setIsEditing(false)
    }
    if (e.key === 'Escape') {
      setTempName(fieldName)
      setIsEditing(false)
    }
  }

  return isEditing ? (
    <input
      className={styles.fieldInput}
      value={tempName}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
    />
  ) : (
    <span className={styles.fieldTab} onClick={handleSpanClick}>
      {fieldName}
    </span>
  )
}

export { EditableNameField }
