import { FC, useState } from 'react'

import { strings } from '../../../../models/consts/strings'
import { IWizardResponseType } from '../../../../models/interfaces/wizard-response-type'
import { GenericButton } from '../../../GenericButton/GenericButton'
import WizardInput from '../../WizardInput/WizardInput'
import styles from './TargetAudience.module.scss'

interface ITargetAudienceProps {
  onNext: (data: IWizardResponseType[]) => void
  value?: IWizardResponseType[]
}

const TargetAudience: FC<ITargetAudienceProps> = ({ onNext, value }) => {
  const [inputValue, setInputValue] = useState<string>(value?.[0]?.value || '')
  const [secondInputValue, setSecondInputValue] = useState<string>(value?.[1]?.value || '')
  const [isSecondInputVisible, setIsSecondInputVisible] = useState<boolean>(!!value?.[1])

  const handleFirstInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(value)
  }
  const handleSecondInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSecondInputValue(value)
  }

  const handleNext = () => {
    const output: IWizardResponseType[] = [
      { type: 'text', value: inputValue },
      { type: 'text', value: secondInputValue },
    ]
    onNext(output)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.targetAudienceContainer}>
        <p className={styles.title}>{strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.TITLE}</p>
        <div className={styles.inputsContainer}>
          <WizardInput
            value={inputValue}
            placeholder={strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.PLACE_HOLDER}
            onChange={handleFirstInputChange}
            className={styles.discoveryInput}
          />
          {isSecondInputVisible && (
            <WizardInput
              value={secondInputValue}
              placeholder={strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.PLACE_HOLDER2}
              onChange={handleSecondInputChange}
              className={styles.discoveryInput}
            />
          )}
          {isSecondInputVisible ? (
            <button onClick={() => setIsSecondInputVisible(false)} className={styles.secondAudienceButton}>
              - {strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.BUTTON2}
            </button>
          ) : (
            <button onClick={() => setIsSecondInputVisible(true)} className={styles.secondAudienceButton}>
              + {strings.WIZARD.DISCOVERY.TARGET_AUDIENCE.BUTTON}
            </button>
          )}
        </div>
      </div>
      <div className={styles.nextButton}>
        <GenericButton
          isDisabled={(isSecondInputVisible && (!secondInputValue.trim() || !inputValue.trim())) || !inputValue.trim()}
          title={strings.GENERAL_LABELS.NEXT}
          onClick={handleNext}
        />
      </div>
    </div>
  )
}

export default TargetAudience
